import React, { Fragment, useEffect } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { clearErrors, getTransferDetails } from "../../actions/transferAction";
import Loader from "../layout/Loader/Loader";
import MetaData from "../layout/MetaData";

const TransferDetails = () => {
  const dispatch = useDispatch();
  const alert = useAlert();

  let { id } = useParams();

  const { error, loading, transfer } = useSelector((state) => state.transferDetails);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    dispatch(getTransferDetails(id));
  }, [dispatch, id, error, alert]);
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title={`Transfer Details - ${transfer.id}`} />
          <div className="card">
            <h1 id="productListHeading">Transfer Details</h1>
            <div className="container-fluid">
              <h3 className="block-title">
                Transfer Id: <span className="fw-normal">{transfer.id}</span>
              </h3>
              <hr className="page-divider half transparent" />
              {transfer.arrival && (
                <Fragment>
                  <h3 className="block-title" id="pickupanddropoff">
                    <span>Arrival</span>
                  </h3>
                  <div className="row mb-4 px-5">
                    <div className="col-sm-6 mb-2 mb-sm-4">
                      <div className="form-group has-icon has-label">
                        <label className="form-label-theme">From:</label>{" "}
                        {transfer.arrival && transfer.arrival.pickup && transfer.arrival.pickup.location}
                      </div>
                    </div>
                    <div className="col-sm-6 mb-2 mb-sm-4">
                      <div className="form-group has-icon has-label">
                        <label className="form-label-theme">To:</label>{" "}
                        {transfer.arrival && transfer.arrival.dropoff && transfer.arrival.dropoff.location}{" "}
                        {transfer.arrival && transfer.arrival.dropoff && transfer.arrival.dropoff.locationDetails}
                      </div>
                    </div>
                    <div className="col-sm-6 mb-2 mb-sm-4">
                      <div className="form-group has-label has-icon">
                        <label className="form-label-theme">Date:</label>{" "}
                        {transfer.arrival && transfer.arrival.pickup && transfer.arrival.pickup.date}{" "}
                        {transfer.arrival && transfer.arrival.pickup && transfer.arrival.pickup.time}
                      </div>
                    </div>
                    <div className="col-sm-6 mb-2 mb-sm-4">
                      <div className="form-group has-label has-icon">
                        <label className="form-label-theme">Passengers:</label>{" "}
                        {transfer.arrival && transfer.arrival.pickup && transfer.arrival.pickup.passengers}
                      </div>
                    </div>
                    <div className="col-sm-6 mb-2 mb-sm-4">
                      <div className="form-group has-label has-icon">
                        <label className="form-label-theme">Baby Seat:</label>{" "}
                        {transfer.arrival && transfer.arrival.pickup && transfer.arrival.pickup.babySeat}
                      </div>
                    </div>
                    <div className="col-sm-6 mb-2 mb-sm-4">
                      <div className="form-group has-label has-icon">
                        <label className="form-label-theme">Child Seat:</label>{" "}
                        {transfer.arrival && transfer.arrival.pickup && transfer.arrival.pickup.childSeat}
                      </div>
                    </div>
                  </div>
                </Fragment>
              )}
              {transfer.departure && (
                <Fragment>
                  <h3 className="block-title" id="pickupanddropoff">
                    <span>Departure</span>
                  </h3>
                  <div className="row mb-4 px-5">
                    <div className="col-sm-6 mb-2 mb-sm-4">
                      <div className="form-group has-icon has-label">
                        <label className="form-label-theme">From:</label>{" "}
                        {transfer.departure && transfer.departure.pickup && transfer.departure.pickup.location}
                      </div>
                    </div>
                    <div className="col-sm-6 mb-2 mb-sm-4">
                      <div className="form-group has-icon has-label">
                        <label className="form-label-theme">To:</label>{" "}
                        {transfer.departure && transfer.departure.dropoff && transfer.departure.dropoff.location}{" "}
                        {transfer.departure && transfer.departure.dropoff && transfer.departure.dropoff.locationDetails}
                      </div>
                    </div>
                    <div className="col-sm-6 mb-2 mb-sm-4">
                      <div className="form-group has-label has-icon">
                        <label className="form-label-theme">Date:</label>{" "}
                        {transfer.departure && transfer.departure.pickup && transfer.departure.pickup.date}{" "}
                        {transfer.departure && transfer.departure.pickup && transfer.departure.pickup.time}
                      </div>
                    </div>
                    <div className="col-sm-6 mb-2 mb-sm-4">
                      <div className="form-group has-label has-icon">
                        <label className="form-label-theme">Passengers:</label>{" "}
                        {transfer.departure && transfer.departure.pickup && transfer.departure.pickup.passengers}
                      </div>
                    </div>
                    <div className="col-sm-6 mb-2 mb-sm-4">
                      <div className="form-group has-label has-icon">
                        <label className="form-label-theme">Baby Seat:</label>{" "}
                        {transfer.departure && transfer.departure.pickup && transfer.departure.pickup.babySeat}
                      </div>
                    </div>
                    <div className="col-sm-6 mb-2 mb-sm-4">
                      <div className="form-group has-label has-icon">
                        <label className="form-label-theme">Child Seat:</label>{" "}
                        {transfer.departure && transfer.departure.pickup && transfer.departure.pickup.childSeat}
                      </div>
                    </div>
                  </div>
                </Fragment>
              )}
              <h3 className="block-title mt-4">
                <span>Customer Information</span>
              </h3>
              <div className="row pb-3 px-5">
                <div className="col-md-6 mt-4">
                  <div className="form-group">
                    <label className="form-label-theme">Name:</label>{" "}
                    {transfer.customerDetails && transfer.customerDetails.name}
                  </div>
                </div>
                <div className="col-md-6 mt-4">
                  <div className="form-group">
                    <label className="form-label-theme">Surname:</label>{" "}
                    {transfer.customerDetails && transfer.customerDetails.surname}
                  </div>
                </div>
                <div className="col-md-6 mt-4">
                  <div className="form-group">
                    <label className="form-label-theme">Birth Date:</label>{" "}
                    {transfer.customerDetails && transfer.customerDetails.dateOfBirth}
                  </div>
                </div>
                <div className="col-md-6 mt-4">
                  <div className="form-group">
                    <label className="form-label-theme">Email:</label>{" "}
                    {transfer.customerDetails && transfer.customerDetails.email}
                  </div>
                </div>
                <div className="col-md-6 mt-4">
                  <div className="form-group">
                    <label className="form-label-theme">Address:</label>{" "}
                    {transfer.customerDetails && transfer.customerDetails.address}
                  </div>
                </div>
                <div className="col-md-6 mt-4">
                  <div className="form-group">
                    <label className="form-label-theme">Place to Stay in North Cyprus:</label>{" "}
                    {transfer.customerDetails && transfer.customerDetails.placeInNorthCyprus}
                  </div>
                </div>
                <div className="col-md-6 mt-4">
                  <div className="form-group">
                    <label className="form-label-theme">Phone:</label>{" "}
                    {transfer.customerDetails && transfer.customerDetails.phone}
                  </div>
                </div>
                <div className="col-md-6 mt-4">
                  <div className="form-group">
                    <label className="form-label-theme">Cell Phone:</label>{" "}
                    {transfer.customerDetails && transfer.customerDetails.cellPhone}
                  </div>
                </div>
                <div className="col-md-12 mt-4">
                  <div className="form-group">
                    <label className="form-label-theme">Message:</label>{" "}
                    {transfer.customerDetails && transfer.customerDetails.message}
                  </div>
                </div>
              </div>
              <hr />
              <h3 className="block-title">
                Total Price: <span className="fw-normal">£{transfer.totalPrice}</span>
              </h3>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default TransferDetails;
