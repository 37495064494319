import axios from "axios";
import {
  ALL_CAR_FAIL,
  ALL_CAR_REQUEST,
  ALL_CAR_SUCCESS,
  ADMIN_CAR_REQUEST,
  ADMIN_CAR_SUCCESS,
  ADMIN_CAR_FAIL,
  CREATE_CAR_REQUEST,
  CREATE_CAR_SUCCESS,
  CREATE_CAR_FAIL,
  UPDATE_CAR_REQUEST,
  UPDATE_CAR_SUCCESS,
  UPDATE_CAR_FAIL,
  DELETE_CAR_REQUEST,
  DELETE_CAR_SUCCESS,
  DELETE_CAR_FAIL,
  CAR_DETAILS_REQUEST,
  CAR_DETAILS_FAIL,
  CAR_DETAILS_SUCCESS,
  CLEAR_ERRORS,
} from "../constants/carConstants";

// Get All Cars
export const getCars =
  (name = "", currentPage = 1, category, price = [0, 100], gearbox, fuel, passengers, doors, suitcases) =>
  async (dispatch) => {
    try {
      dispatch({ type: ALL_CAR_REQUEST });

      let link = `/api/v1/cars?keyword=${name}&page=${currentPage}`;

      if (category) {
        link += `&category=${category}`;
      }
      if (fuel) {
        link += `&fuel=${fuel}`;
      }
      if (gearbox) {
        link += `&gearbox=${gearbox}`;
      }
      if (passengers) {
        link += `&passengers=${passengers}`;
      }
      if (doors) {
        link += `&doors=${doors}`;
      }
      if (suitcases) {
        link += `&suitcases=${suitcases}`;
      }
      const { data } = await axios.get(link);

      dispatch({
        type: ALL_CAR_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: ALL_CAR_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// Get All Cars For Admin
export const getAdminCars = () => async (dispatch) => {
  try {
    dispatch({ type: ADMIN_CAR_REQUEST });

    const { data } = await axios.get("/api/v1/admin/cars");

    dispatch({
      type: ADMIN_CAR_SUCCESS,
      payload: data.cars,
    });
  } catch (error) {
    dispatch({
      type: ADMIN_CAR_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Create Car
export const createCar = (carData) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_CAR_REQUEST });

    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const { data } = await axios.post(`/api/v1/admin/car/new`, carData, config);

    dispatch({
      type: CREATE_CAR_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_CAR_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update Car
export const updateCar = (id, carData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_CAR_REQUEST });

    const config = {
      headers: { "Content-Type": "multipart/form-data" },
    };

    const { data } = await axios.put(`/api/v1/admin/car/${id}`, carData, config);

    dispatch({
      type: UPDATE_CAR_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_CAR_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete Car
export const deleteCar = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_CAR_REQUEST });

    const { data } = await axios.delete(`/api/v1/admin/car/${id}`);

    dispatch({
      type: DELETE_CAR_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_CAR_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Get Car Details
export const getCarDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: CAR_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/v1/car/${id}`);

    dispatch({
      type: CAR_DETAILS_SUCCESS,
      payload: data.car,
    });
  } catch (error) {
    dispatch({
      type: CAR_DETAILS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Clearing Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
