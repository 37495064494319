import React, { Fragment, useEffect, useState } from "react";
import "./productList.css";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import MetaData from "../layout/MetaData";
import { getAllCategories, clearErrors, deleteCategory } from "../../actions/categoryAction";
import { DELETE_USER_RESET } from "../../constants/userConstants";
import { DELETE_CATEGORY_RESET } from "../../constants/categoryConstants";
import Loader from "../layout/Loader/Loader";
import axios from "axios";

const CustomerEnquiries = () => {
  const [contacts, setContacts] = useState();

  useEffect(() => {
    axios.get("/api/v1/admin/contacts").then((response) => {
      setContacts(response.data.contacts);
    });
  }, []);

  return (
    <Fragment>
      <MetaData title={`Customer Enquiries - Admin`} />

      <div className="card">
        <h1 id="productListHeading">Customer Enquiries</h1>
        <div className="card-body px-0 py-0">
          <div className="table-responsive scrollbar">
            <table className="table table-sm fs--1 mb-0 overflow-hidden">
              <thead className="bg-200 text-900">
                <tr>
                  <th className="align-middle white-space-nowrap">Name</th>
                  <th className="align-middle white-space-nowrap">Email</th>
                  <th className="align-middle white-space-nowrap">Subject</th>
                  <th className="align-middle white-space-nowrap">Message</th>
                  <th className="align-middle white-space-nowrap text-end">Created At</th>
                  <th className="no-sort pe-1 align-middle data-table-row-action"></th>
                </tr>
              </thead>
              <tbody className="list" id="table-purchase-body">
                {contacts && contacts.length > 0 ? (
                  contacts.map((item) => (
                    <tr className="btn-reveal-trigger">
                      <td className="align-middle white-space-nowrap name">{item.name}</td>
                      <td className="align-middle white-space-nowrap name">{item.email}</td>
                      <td className="align-middle white-space-nowrap name">{item.subject}</td>
                      <td className="align-middle white-space-nowrap name">{item.message}</td>
                      <td className="align-middle text-end createdAt">{String(item.createdAt).substr(0, 10)}</td>
                      <td className="align-middle white-space-nowrap text-end p-4"></td>
                    </tr>
                  ))
                ) : (
                  <tr className="btn-reveal-trigger">
                    <td className="text-center align-middle py-3" colSpan={6}>
                      No Results Found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CustomerEnquiries;
