import React, { Fragment, useEffect, useState } from "react";
import "./productList.css";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import MetaData from "../layout/MetaData";
import { clearErrors, deleteCar, getCars } from "../../actions/carAction";
import { DELETE_CAR_RESET } from "../../constants/carConstants";
import Loader from "../layout/Loader/Loader";
import Select from "react-select";
import { Slider } from "@mui/material";
import { getAllCategories } from "../../actions/categoryAction";
import Pagination from "react-js-pagination";

const CarsList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();

  const { error, cars, loading, carsCount, resultPerPage, filteredCarsCount } = useSelector((state) => state.cars);

  const { categories } = useSelector((state) => state.categories);

  const { error: deleteError, isDeleted, message } = useSelector((state) => state.car);

  const [currentPage, setcurrentPage] = useState(1);

  const setCurrentPageNo = (e) => {
    setcurrentPage(e);
  };

  let count = filteredCarsCount;

  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [engine, setEngine] = useState([0, 4.0]);
  const [fuel, setFuel] = useState("");
  const [gearbox, setGearbox] = useState("");
  const [passengers, setPassengers] = useState(0);
  const [doors, setDoors] = useState(0);
  const [suitcases, setSuitcases] = useState(0);
  const [price, setPrice] = useState([0, 100]);
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [image, setImage] = useState("");
  const [AC, setAC] = useState(false);
  const [active, setActive] = useState(false);
  const deleteUserHandler = (id) => {
    dispatch(deleteCar(id));
  };
  const FilterHandler = () => {
    dispatch(getCars(name, currentPage, category, price, gearbox, fuel, passengers, doors, suitcases));
  };
  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (deleteError) {
      alert.error(deleteError);
      dispatch(clearErrors());
    }

    if (isDeleted) {
      alert.success("Car Deleted Successfully");
      navigate("/admin/cars");
      dispatch({ type: DELETE_CAR_RESET });
    }
    dispatch(getCars(name, currentPage));
    dispatch(getAllCategories());
  }, [dispatch, name, alert, error, deleteError, navigate, isDeleted, currentPage]);

  const categorySelectOptions = [];

  categories &&
    categories.forEach((item) => {
      categorySelectOptions.push({
        value: item.name,
        label: item.name,
      });
    });

  const fuelSelectOptions = [
    { value: "Petrol", label: "Petrol" },
    { value: "Diesel", label: "Diesel" },
    { value: "High Octane", label: "High Octane" },
    { value: "Unleaded", label: "Unleaded" },
  ];
  const gearBoxSelectOptions = [
    { value: "Manual", label: "Manual" },
    { value: "Automatic", label: "Automatic" },
    { value: "Triptonic", label: "Triptonic" },
  ];
  const passengersSelectOptions = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
  ];
  const doorsSelectOptions = [
    { value: 2, label: 2 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
  ];
  const suitcasesSelectOptions = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
  ];

  const handleOnChangeAC = () => {
    setAC(!AC);
  };

  const handleOnChangeActive = () => {
    setActive(!active);
  };
  const resetFilters = () => {
    setName("");
    setPrice([0, 100]);
    setCategory("");
    setGearbox("");
    setFuel("");
    setPassengers("");
    setDoors("");
    setSuitcases("");
    setAC(false);
    setActive(false);
    dispatch(getCars());
  };

  const marks = [
    {
      value: 0,
      label: "£0",
    },
    {
      value: 100,
      label: "£100",
    },
  ];
  const handleChangePrice = (event, newPrice, activeThumb) => {
    if (!Array.isArray(newPrice)) {
      return;
    }

    if (activeThumb === 0) {
      setPrice([Math.min(newPrice[0], price[1] - 10), price[1]]);
    } else {
      setPrice([price[0], Math.max(newPrice[1], price[0] + 10)]);
    }
  };
  return (
    <Fragment>
      <MetaData title={`ALL Cars - Admin`} />
      <div className="card">
        <h1 id="productListHeading">All Cars</h1>
        <div className="container-fluid p-3 mb-4 bg-200 filter-box">
          <div className="row pb-3 ">
            <div className="col-lg-8">
              <input
                type="text"
                className="prodFormInput"
                placeholder="Search by Name..."
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="col-lg-4">
              <span>Price</span>
              <Slider
                marks={marks}
                step={5}
                sx={{
                  color: "#e47911",
                  "& .MuiSlider-thumb": {
                    width: "15px",
                    height: "15px",
                  },
                  "& .MuiSlider-track": {
                    height: "5px",
                  },
                  "& .MuiSlider-rail": {
                    height: "5px",
                  },
                  "& .MuiSlider-mark": {
                    visibility: "hidden",
                  },
                }}
                value={price}
                onChange={handleChangePrice}
                valueLabelDisplay="auto"
                getAriaLabel={() => "Temperature range"}
                min={0}
                max={100}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-2">
              <Select
                placeholder="Category"
                className="basic-multi-select"
                classNamePrefix="select"
                options={categorySelectOptions}
                required
                value={categorySelectOptions.filter(function (option) {
                  return option.value === category;
                })}
                onChange={(e) => setCategory(e.value)}
              />
            </div>
            <div className="col-lg-2">
              <Select
                placeholder="Gearbox"
                className="basic-multi-select"
                classNamePrefix="select"
                options={gearBoxSelectOptions}
                required
                value={gearBoxSelectOptions.filter(function (option) {
                  return option.value === gearbox;
                })}
                onChange={(e) => setGearbox(e.value)}
              />
            </div>
            <div className="col-lg-2">
              <Select
                placeholder="Fuel"
                className="basic-multi-select"
                classNamePrefix="select"
                options={fuelSelectOptions}
                required
                value={fuelSelectOptions.filter(function (option) {
                  return option.value === fuel;
                })}
                onChange={(e) => setFuel(e.value)}
              />
            </div>
            <div className="col-lg-2">
              <Select
                placeholder="Passengers"
                className="basic-multi-select"
                classNamePrefix="select"
                options={passengersSelectOptions}
                required
                value={passengersSelectOptions.filter(function (option) {
                  return option.value === passengers;
                })}
                onChange={(e) => setPassengers(e.value)}
              />
            </div>
            <div className="col-lg-2">
              <Select
                placeholder="Doors"
                className="basic-multi-select"
                classNamePrefix="select"
                options={doorsSelectOptions}
                required
                value={doorsSelectOptions.filter(function (option) {
                  return option.value === doors;
                })}
                onChange={(e) => setDoors(e.value)}
              />
            </div>
            <div className="col-lg-1 d-flex">
              <input type="submit" className="btn btn-theme text-uppercase" value="Filter" onClick={FilterHandler} />
            </div>
            <div className="col-lg-1 d-flex">
              <input type="submit" className="btn btn-theme text-uppercase" value="Reset" onClick={resetFilters} />
            </div>
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : (
          <div className="card-body px-0 py-0">
            <div className="table-responsive scrollbar">
              <table className="table table-sm fs--1 mb-0 overflow-hidden">
                <thead className="bg-200 text-900">
                  <tr>
                    <th className="align-middle white-space-nowrap">No.</th>
                    <th className="align-middle white-space-nowrap">Image</th>
                    <th className="align-middle white-space-nowrap">Name</th>
                    <th className="align-middle white-space-nowrap">Price / Day</th>
                    <th className="align-middle white-space-nowrap">Category</th>
                    <th className="align-middle white-space-nowrap">Engine</th>
                    <th className="align-middle white-space-nowrap">Gearbox</th>
                    <th className="align-middle white-space-nowrap">Passengers</th>
                    <th className="align-middle white-space-nowrap">Doors</th>
                    <th className="lign-middle white-space-nowrap">Suitcases</th>
                    <th className="align-middle white-space-nowrap">Fuel</th>
                    <th className="align-middle white-space-nowrap">AC</th>
                    <th className="align-middle white-space-nowrap">Active</th>
                    <th className="align-middle white-space-nowrap text-end">Created At</th>
                    <th className="align-middle data-table-row-action"></th>
                  </tr>
                </thead>
                <tbody className="list" id="table-purchase-body">
                  {cars &&
                    cars.map((item) => (
                      <tr className="btn-reveal-trigger">
                        <td className="align-middle white-space-nowrap fw-bold text-grey text-uppercase">
                          {item.orderNo}
                        </td>
                        <td className="align-middle white-space-nowrap image">
                          <img className="img-fluid" src={`/images/${item.image}`} alt="img" />
                        </td>
                        <td className="align-middle white-space-nowrap fw-bold text-grey text-uppercase">
                          {item.name}
                        </td>
                        <td className="align-middle white-space-nowrap">
                          £{item.prices && item.prices[0].days1} - £{item.prices && item.prices[7].days2}
                        </td>
                        <td className="align-middle white-space-nowrap">{item.category}</td>
                        <td className="align-middle white-space-nowrap">{item.engine.toPrecision(2)} lt</td>
                        <td className="align-middle white-space-nowrap">{item.gearbox}</td>
                        <td className="align-middle white-space-nowrap">{item.passengers}</td>
                        <td className="align-middle white-space-nowrap">{item.doors}</td>
                        <td className="align-middle white-space-nowrap">{item.suitcases}</td>
                        <td className="align-middle white-space-nowrap">{item.fuel}</td>
                        <td className="align-middle">{item.AC === true ? "Yes" : "No"}</td>
                        <td className="align-middle">{item.active === true ? "Yes" : "No"}</td>
                        <td className="align-middle text-end">{String(item.createdAt).substr(0, 10)}</td>
                        <td className="align-middle white-space-nowrap text-end">
                          <div className="dropstart font-sans-serif position-static d-inline-block">
                            <button
                              className="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal float-end"
                              type="button"
                              id="dropdown0"
                              data-bs-toggle="dropdown"
                              data-boundary="window"
                              aria-haspopup="true"
                              aria-expanded="false"
                              data-bs-reference="parent"
                            >
                              <svg
                                className="svg-inline--fa fa-ellipsis-h fa-w-16 fs--1"
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fas"
                                data-icon="ellipsis-h"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                data-fa-i2svg=""
                              >
                                <path
                                  fill="currentColor"
                                  d="M328 256c0 39.8-32.2 72-72 72s-72-32.2-72-72 32.2-72 72-72 72 32.2 72 72zm104-72c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm-352 0c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72z"
                                ></path>
                              </svg>
                            </button>
                            <div className="dropdown-menu dropdown-menu-end border py-2" aria-labelledby="dropdown0">
                              <Link className="dropdown-item" target="_blank" to={`/admin/car/${item.id}`}>
                                View
                              </Link>
                              <Link className="dropdown-item" to={`/admin/car/${item.id}`}>
                                Edit
                              </Link>
                              <div className="dropdown-divider"></div>
                              <Link onClick={() => deleteUserHandler(item.id)} className="dropdown-item text-danger">
                                Delete
                              </Link>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
      {resultPerPage < count && (
        <div className="paginationbox mt-5 d-flex justify-content-center">
          <Pagination
            activePage={currentPage}
            itemsCountPerPage={resultPerPage}
            totalItemsCount={carsCount}
            onChange={setCurrentPageNo}
            nextPageText="Next"
            prevPageText="Prev"
            hideFirstLastPages={true}
            itemclassName="page-item"
            linkclassName="page-link"
            activeclassName="pageItemActive"
            activeLinkclassName="pageLinkActive"
          />
        </div>
      )}
    </Fragment>
  );
};

export default CarsList;
