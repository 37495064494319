import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../layout/Breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import { getCars, clearErrors } from "../../actions/carAction";
import Select from "react-select";
import { Slider } from "@mui/material";
import { getAllCategories } from "../../actions/categoryAction";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import Loader from "../layout/Loader/Loader";
import ContactFooter from "../layout/ContactFooter";
import MetaData from "../layout/MetaData";
import $ from "jquery";

const Cars = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const location = useLocation();

  if (location.state) {
    var { selectedCategory } = location.state;
  }

  const { cars, loading, error, carsCount, resultPerPage, filteredCarsCount } = useSelector((state) => state.cars);

  const { categories } = useSelector((state) => state.categories);

  const [currentPage, setcurrentPage] = useState(1);

  const setCurrentPageNo = (e) => {
    setcurrentPage(e);
    window.scrollTo({
      top: 0,
    });
  };

  let count = filteredCarsCount;

  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [engine, setEngine] = useState([0, 4.0]);
  const [fuel, setFuel] = useState("");
  const [gearbox, setGearbox] = useState("");
  const [passengers, setPassengers] = useState(0);
  const [doors, setDoors] = useState(0);
  const [suitcases, setSuitcases] = useState(0);
  const [price, setPrice] = useState([0, 100]);
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [image, setImage] = useState("");
  const [AC, setAC] = useState(false);
  const [active, setActive] = useState(false);

  const FilterHandler = () => {
    dispatch(getCars(name, currentPage, category, price, gearbox, fuel, passengers, doors, suitcases, AC, active));
  };

  const categorySelectOptions = [];
  categories &&
    categories.forEach((item) => {
      categorySelectOptions.push({
        value: item.name,
        label: item.name,
      });
    });

  const fuelSelectOptions = [
    { value: "Petrol", label: "Petrol" },
    { value: "Euro Diesel", label: "Euro Diesel" },
    { value: "High Octane", label: "High Octane" },
    { value: "Unleaded", label: "Unleaded" },
  ];
  const gearBoxSelectOptions = [
    { value: "Manual", label: "Manual" },
    { value: "Automatic", label: "Automatic" },
    { value: "Triptonic", label: "Triptonic" },
  ];
  const passengersSelectOptions = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
  ];
  const doorsSelectOptions = [
    { value: 2, label: 2 },
    { value: 4, label: 4 },
    { value: 5, label: 5 },
  ];
  const suitcasesSelectOptions = [
    { value: 1, label: 1 },
    { value: 2, label: 2 },
    { value: 3, label: 3 },
    { value: 4, label: 4 },
  ];
  const handleOnChangeAC = () => {
    setAC(!AC);
  };

  const handleOnChangeActive = () => {
    setActive(!active);
  };

  const resetFilters = () => {
    setName("");
    setPrice([0, 100]);
    setEngine([0, 4.0]);
    setGearbox("");
    setCategory("");
    setFuel("");
    setPassengers("");
    setDoors("");
    setSuitcases("");
    setAC(false);
    setActive(false);
    dispatch(getCars());
  };
  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (selectedCategory) {
      dispatch(getCars(name, currentPage, selectedCategory));
    } else {
      dispatch(getCars(name, currentPage));
    }
    dispatch(getAllCategories());
  }, [dispatch, name, selectedCategory, error, alert, currentPage]);

  const [filters, setFilters] = useState(false);
  const handleChangeFilters = () => {
    if (window.innerWidth < 768) {
      if (filters === false) {
        $("#FiltersDiv").slideDown(400);
      } else {
        $("#FiltersDiv").slideUp(400);
      }
      setFilters(!filters);
    }
  };

  return (
    <Fragment>
      <MetaData title="Baspinar - Cars" />
      <Breadcrumb parent="cars" name="Car Fleet" />
      <section className="page-section with-sidebar sub-page">
        <div className="container-lg">
          <div className="row">
            <div className="col-md-7 col-lg-9 content car-listing" id="content">
              <div id="pnlCars">
                {loading ? (
                  <Loader />
                ) : (
                  cars &&
                  cars.map((car) => (
                    <>
                      {car.active && (
                        <div className="thumbnail no-border no-padding thumbnail-car-card clearfix">
                          <div className="media">
                            <Link to={`/car/${car.id}`} className="media-link">
                              <img src={`/images/${car.image}`} id={car.image} />
                            </Link>
                          </div>
                          <div className="caption">
                            <div className="carType">
                              <span>{car.category}</span>
                            </div>
                            <h4 className="caption-title">
                              <a>{car.name}</a>
                            </h4>
                            <div className="caption-text">{car.additionalInfo} </div>
                            <div className="row m-0 border-top mt-4" style={{ fontSize: "small" }}>
                              <div className="col d-flex flex-column align-items-center justify-content-end border-right py-2 px-0 px-md-2">
                                <i className="fa fa-car"></i>
                                {car.fuel}
                              </div>
                              <div className="col d-flex flex-column align-items-center justify-content-end border-right py-2 px-0 px-md-2">
                                <i className="fa fa-tachometer-alt"></i>
                                {car.engine.toPrecision(2)} lt
                              </div>
                              <div className="col d-flex flex-column align-items-center justify-content-end border-right py-2 px-0 px-md-2">
                                <i className="fa fa-cog"></i>
                                {car.gearbox}
                              </div>
                              <div className="col d-flex flex-column align-items-center justify-content-end border-right py-2 px-0 px-md-2">
                                <i className="fa fa-users"></i>
                                {car.passengers} Seats
                              </div>
                              <div className="col d-flex flex-column align-items-center justify-content-end border-right py-2 px-0 px-md-2">
                                <i className="far fa-folder"></i>
                                {car.doors} Doors
                              </div>
                              <div className="col-xl-3 col-lg-12 p-0">
                                <Link className="btn btn-theme w-100" to={`/car/${car.id}`}>
                                  RENT NOW
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))
                )}
              </div>
              {resultPerPage < count && (
                <div className="paginationbox mt-5 d-flex justify-content-center">
                  <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={resultPerPage}
                    totalItemsCount={carsCount}
                    onChange={setCurrentPageNo}
                    nextPageText="Next"
                    prevPageText="Prev"
                    hideFirstLastPages={true}
                    itemclassName="page-item"
                    linkclassName="page-link"
                    activeclassName="pageItemActive"
                    activeLinkclassName="pageLinkActive"
                  />
                </div>
              )}
            </div>
            <aside className="col-md-4 col-lg-3 sidebar order-first order-lg-last order-md-last mb-3 mb-md-0">
              <div className="widget shadow widget-find-car mx-auto" style={{ maxWidth: "450px" }}>
                <h4 className="widget-title" id="FiltersH4" onClick={handleChangeFilters}>
                  Find Best Rental Car
                </h4>
                <div className="widget-content d-md-block" id="FiltersDiv">
                  <div className="form-search light">
                    <div className="form-group selectpicker-wrapper">
                      <div className="container-fluid px-3 mb-4 filter-box-web">
                        <div className="row">
                          <div className="col-lg-12">
                            <input
                              type="text"
                              className="prodFormInput"
                              placeholder="Search by Name..."
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </div>

                          <div className="col-lg-12">
                            <Select
                              placeholder="Category"
                              className="basic-multi-select"
                              classNamePrefix="select"
                              options={categorySelectOptions}
                              required
                              value={categorySelectOptions.filter(function (option) {
                                return option.value === category;
                              })}
                              onChange={(e) => setCategory(e.value)}
                            />
                          </div>
                          <div className="col-lg-12">
                            <Select
                              placeholder="Gearbox"
                              className="basic-multi-select"
                              classNamePrefix="select"
                              options={gearBoxSelectOptions}
                              required
                              value={gearBoxSelectOptions.filter(function (option) {
                                return option.value === gearbox;
                              })}
                              onChange={(e) => setGearbox(e.value)}
                            />
                          </div>
                          <div className="col-lg-12">
                            <Select
                              placeholder="Fuel"
                              className="basic-multi-select"
                              classNamePrefix="select"
                              options={fuelSelectOptions}
                              required
                              value={fuelSelectOptions.filter(function (option) {
                                return option.value === fuel;
                              })}
                              onChange={(e) => setFuel(e.value)}
                            />
                          </div>
                          <div className="col-lg-12">
                            <Select
                              placeholder="Passengers"
                              className="basic-multi-select"
                              classNamePrefix="select"
                              options={passengersSelectOptions}
                              required
                              value={passengersSelectOptions.filter(function (option) {
                                return option.value === passengers;
                              })}
                              onChange={(e) => setPassengers(e.value)}
                            />
                          </div>
                          <div className="col-lg-12">
                            <Select
                              placeholder="Doors"
                              className="basic-multi-select"
                              classNamePrefix="select"
                              options={doorsSelectOptions}
                              required
                              value={doorsSelectOptions.filter(function (option) {
                                return option.value === doors;
                              })}
                              onChange={(e) => setDoors(e.value)}
                            />
                          </div>
                          <div className="col-lg-12">
                            <Select
                              placeholder="Suitcases"
                              className="basic-multi-select"
                              classNamePrefix="select"
                              options={suitcasesSelectOptions}
                              required
                              value={suitcasesSelectOptions.filter(function (option) {
                                return option.value === suitcases;
                              })}
                              onChange={(e) => setSuitcases(e.value)}
                            />
                          </div>
                          <div className="col-lg-12">
                            <span>Price</span>
                            <Slider
                              step={5}
                              sx={{
                                color: "#e47911",
                                "& .MuiSlider-thumb": {
                                  width: "15px",
                                  height: "15px",
                                  "&:focus, &:hover, &.Mui-active": {
                                    boxShadow:
                                      "0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)",
                                  },
                                },
                              }}
                              value={price}
                              onChange={(e, newPrice) => {
                                setPrice(newPrice);
                              }}
                              valueLabelDisplay="auto"
                              getAriaLabel={() => "Temperature range"}
                              min={0}
                              max={100}
                            />
                          </div>
                          <div className="col-lg-12">
                            <span className="me-2">AC</span>
                            <input
                              type="checkbox"
                              className="float-end prodFormInputCheck"
                              checked={AC}
                              onChange={handleOnChangeAC}
                            />
                          </div>
                          <div className="col-lg-12">
                            <span className="me-2">Active</span>
                            <input
                              type="checkbox"
                              className="float-end prodFormInputCheck"
                              checked={active}
                              onChange={handleOnChangeActive}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <input
                      type="submit"
                      name="btnShowType"
                      value="Find Car"
                      id="btnShowType"
                      className="btn btn-theme w-100 my-2"
                      onClick={FilterHandler}
                    />
                    <input
                      type="submit"
                      name="btnShowType"
                      value="Reset Filters"
                      id="btnShowType"
                      className="btn btn-theme w-100"
                      onClick={resetFilters}
                    />
                  </div>
                </div>
              </div>
              <div className="widget shadow widget-helping-center d-none d-md-block">
                <h4 className="widget-title">Helping Center</h4>
                <div className="widget-content">
                  <p>If you need help with your reservation, please contact us on </p>
                  <h5 className="widget-title-sub">+90 542 851 43 33</h5>
                  <p>
                    <a href="mailto:info@baspinar-rentals.com">info@baspinar-rentals.com</a>
                  </p>
                  <div className="button">
                    <a id="btnfooterpage" href="#" className="btn btn-theme w-100">
                      Support Center
                    </a>
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </section>
      <ContactFooter />
    </Fragment>
  );
};

export default Cars;
