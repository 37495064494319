import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../layout/Breadcrumb";
import TimeSelect from "../layout/TimeSelect";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import ContactFooter from "../layout/ContactFooter";
import { useDispatch, useSelector } from "react-redux";
import { saveBooking } from "../../actions/bookingAction";
import MetaData from "../layout/MetaData";

const Booking = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { bookingInfo } = useSelector((state) => state.createBooking);

  const [pickUpLocation, setPickUpLocation] = useState("");
  const [pickUpDate, setPickUpDate] = useState("");
  const [pickUpTime, setPickUpTime] = useState("00:00");
  const [dropOffLocation, setDropOffLocation] = useState("");
  const [dropOffDate, setDropOffDate] = useState("");
  const [dropOffTime, setDropOffTime] = useState("00:00");

  // Form Input Errors
  const [inputError, setInputError] = useState(false);
  const [pickLocE, setPickLocE] = useState();
  const [pickDateE, setPickDateE] = useState();
  const [dropLocE, setDropLocE] = useState();
  const [dropDateE, setDropDateE] = useState();

  const booking = {
    pickUpLocation: pickUpLocation,
    pickUpDate: pickUpDate,
    pickUpTime: pickUpTime,
    dropOffLocation: dropOffLocation,
    dropOffDate: dropOffDate,
    dropOffTime: dropOffTime,
  };

  const bookingSubmitHandler = (e) => {
    e.preventDefault();
    let isError = false;

    if (!pickUpLocation) {
      setPickLocE("Enter Pickup Location");
      setInputError(true);
      isError = true;
    }
    if (!pickUpDate) {
      setPickDateE("Select Pickup Date");
      setInputError(true);
      isError = true;
    }
    if (!dropOffLocation) {
      setDropLocE("Enter Dropoff Location");
      setInputError(true);
      isError = true;
    }
    if (!dropOffDate) {
      setDropDateE("Select Dropoff Date");
      setInputError(true);
      isError = true;
    }
    if (!isError) {
      setInputError(false);
      dispatch(saveBooking(booking));
      alert.show("Choose Car");
      navigate("/cars");
    }
  };

  useEffect(() => {
    if (inputError) {
      pickUpLocation ? setPickLocE() : setPickLocE("Enter Pickup Location");

      dropOffLocation ? setDropLocE() : setDropLocE("Enter Dropoff Location");

      pickUpDate ? setPickDateE() : setPickDateE("Select Pickup Date");

      dropOffDate ? setDropDateE() : setDropDateE("Select Dropoff Date");
    }
  }, [pickUpLocation, dropOffLocation, pickUpDate, dropOffDate]);

  let minDate = new Date();
  let minDateDrop = pickUpDate && new Date(pickUpDate.getTime() + 1 * 24 * 60 * 60 * 1000);
  return (
    <Fragment>
      <MetaData title="Baspinar - Online Booking" />
      <Breadcrumb parent="Booking" name="Booking" />
      <section className="page-section with-sidebar sub-page" style={{ overflow: "visible" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-9 content" id="content">
              <h3 className="block-title alt" id="pickupanddropoff">
                <i className="fas fa-angle-down"></i>Pick Up & Drop Off
              </h3>
              <div className="row mb-4">
                <div className="col-sm-6 mb-4">
                  <div className="form-group has-icon has-label">
                    <label className="form-label-theme">Picking Up Location</label>
                    <select className="form-control" onChange={(e) => setPickUpLocation(e.target.value)}>
                      <option selected disabled hidden>
                        Select
                      </option>
                      <option value="Ercan Airport">Ercan Airport</option>
                      <option value="Hotel in North Cyprus">Hotel in N.Cyprus</option>
                      <option value="Villa in North Cyprus">Villa in N.Cyprus</option>
                      <option value="Kyrenia Office">Kyrenia Office</option>
                      <option value="Famagusta">Famagusta</option>
                    </select>
                    <span className="text-danger">{pickLocE && pickLocE}</span>
                    <span className="form-control-icon">
                      <i className="fas fa-map-marker-alt"></i>
                    </span>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group has-label has-icon">
                    <label className="form-label-theme">Picking Up Date</label>
                    <DatePicker
                      className="form-control"
                      selected={pickUpDate}
                      onChange={(date) => setPickUpDate(date)}
                      minDate={minDate}
                      placeholderText="dd/MM/yyyy"
                      dateFormat="dd/MM/yyyy"
                    />
                    <span className="form-control-icon">
                      <i className="far fa-calendar"></i>
                    </span>
                    <span className="text-danger">{pickDateE && pickDateE}</span>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group has-icon has-label">
                    <label className="form-label-theme">Picking Up Hour</label>
                    <TimeSelect selectedVal={pickUpTime} onChange={(value) => setPickUpTime(value)} />
                    <span className="form-control-icon">
                      <i className="far fa-clock"></i>
                    </span>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="form-group has-icon has-label">
                    <label className="form-label-theme">Dropping Off Location</label>
                    <select className="form-control" onChange={(e) => setDropOffLocation(e.target.value)}>
                      <option selected disabled hidden>
                        Select
                      </option>
                      <option value="Ercan Airport">Ercan Airport</option>
                      <option value="Hotel in North Cyprus">Hotel in N.Cyprus</option>
                      <option value="Villa in North Cyprus">Villa in N.Cyprus</option>
                      <option value="Kyrenia Office">Kyrenia Office</option>
                      <option value="Famagusta">Famagusta</option>
                    </select>
                    <span className="text-danger">{dropLocE && dropLocE}</span>
                    <span className="form-control-icon">
                      <i className="fas fa-map-marker-alt"></i>
                    </span>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group has-label has-icon">
                    <label className="form-label-theme">Dropping Off Date</label>
                    <DatePicker
                      className="form-control"
                      selected={dropOffDate}
                      onChange={(date) => setDropOffDate(date)}
                      minDate={minDateDrop}
                      placeholderText="dd/MM/yyyy"
                      dateFormat="dd/MM/yyyy"
                    />
                    <span className="form-control-icon">
                      <i className="far fa-calendar"></i>
                    </span>
                    <span className="text-danger">{dropDateE && dropDateE}</span>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div className="form-group has-icon has-label">
                    <label className="form-label-theme">Dropping Off Hour</label>
                    <TimeSelect selectedVal={dropOffTime} onChange={(value) => setDropOffTime(value)} />
                    <span className="form-control-icon">
                      <i className="far fa-clock"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-8 d-flex flex-column justify-content-center"></div>
                <div className="col-lg-4">
                  <input
                    value="Continue"
                    type="submit"
                    className="btn btn-theme pull-right btn-reservation-now float-end"
                    onClick={bookingSubmitHandler}
                  />
                </div>
              </div>
            </div>
            <aside className="col-md-3 sidebar">
              <div className="widget shadow widget-helping-center">
                <h4 className="widget-title">Helping Center</h4>
                <div className="widget-content">
                  <p>In case of an emergency please call our 24/7 phone number</p>
                  <h5 className="widget-title-sub">+90 542 851 43 33 </h5>
                  <p>
                    <a href="mailto:info@baspinar-rentals.com">info@baspinar-rentals.com</a>
                  </p>
                  <div className="button">
                    <a id="btnfooterpage" href="#" className="btn btn-theme w-100">
                      Support Center
                    </a>
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </section>
      <ContactFooter />
    </Fragment>
  );
};

export default Booking;
