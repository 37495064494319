import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import ReCAPTCHA from "react-google-recaptcha";

const ContactFooter = () => {
  const alert = useAlert();

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [subject, setSubject] = useState();
  const [message, setMessage] = useState();

  // Input Errors
  const [inputError, setInputError] = useState(false);
  const [nameE, setNameE] = useState();
  const [emailE, setEmailE] = useState();
  const [subjectE, setSubjectE] = useState();
  const [messageE, setMessageE] = useState();
  const [tokenE, setTokenE] = useState();
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const captchaRef = useRef(null);

  const contact = {
    name: name,
    email: email,
    subject: subject,
    message: message,
  };

  const SendContact = async () => {
    const token = captchaRef.current.getValue();
    captchaRef.current.reset();
    let isError = false;

    if (!name) {
      setNameE("Enter Your Name");
      setInputError(true);
      isError = true;
    }
    if (!email || !emailRegex.test(email)) {
      setEmailE("Enter Your Email");
      setInputError(true);
      isError = true;
    }
    if (!subject) {
      setSubjectE("Enter Your Subject");
      setInputError(true);
      isError = true;
    }
    if (!message) {
      setMessageE("Enter Your Message");
      setInputError(true);
      isError = true;
    }
    if (!token) {
      setTokenE("Fill Recaptcha");
      setInputError(true);
      isError = true;
    }
    if (!isError) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        await axios.post("/api/v1/contact", contact, token, config);

        alert.success("Message Successfully Sent");
      } catch (error) {
        alert.error(error.response.data.message);
      }
    }
  };
  useEffect(() => {
    if (inputError) {
      name ? setNameE() : setNameE("Enter Your Name");

      subject ? setSubjectE() : setSubjectE("Enter Your Subject");

      !email || !emailRegex.test(email) ? setEmailE("Email is not Valid") : setEmailE();

      message ? setMessageE() : setMessageE("Enter Your Message");
    }
  }, [name, subject, email, message]);
  return (
    <section className="page-section contact dark">
      <div id="footerpage" className="container">
        <h2 className="section-title">
          <small>Feel Free to Say Hello!</small>
          <span>Get in Touch With Us</span>
        </h2>

        <div className="row">
          <div className="col-md-6">
            <div className="contact-form alt" id="contact-form">
              <div className="row">
                <div className="col-md-6 my-2">
                  <div className="outer required">
                    <div className="form-group af-inner has-icon">
                      <label className="sr-only" for="name">
                        Name
                      </label>
                      <input
                        type="text"
                        placeholder="Name"
                        className="form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      <span className="form-control-icon">
                        <i className="fa fa-user"></i>
                      </span>
                      <span className="text-danger">{nameE && nameE}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 my-2">
                  <div className="outer required">
                    <div className="form-group af-inner has-icon">
                      <label className="sr-only" for="email">
                        Email
                      </label>
                      <input
                        type="text"
                        placeholder="Email"
                        className="form-control"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <span className="form-control-icon">
                        <i className="fa fa-envelope"></i>
                      </span>
                      <span className="text-danger">{emailE && emailE}</span>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 my-3">
                  <div className="outer required">
                    <div className="form-group af-inner has-icon">
                      <label className="sr-only" for="email">
                        Subject
                      </label>
                      <input
                        type="text"
                        placeholder="Subject"
                        className="form-control"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                      />
                      <span className="form-control-icon">
                        <i className="fa fa-envelope"></i>
                      </span>
                      <span className="text-danger">{subjectE && subjectE}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group af-inner has-icon">
                <label className="sr-only" for="input-message">
                  Message
                </label>
                <textarea
                  placeholder="Message"
                  rows="4"
                  cols="50"
                  className="form-control"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
                <span className="form-control-icon">
                  <i className="fa fa-bars"></i>
                </span>
                <span className="text-danger">{messageE && messageE}</span>
              </div>
              <br />
              <ReCAPTCHA
                sitekey="6LeKxzMkAAAAANgCKy2BAxnM3zi6ECNwJt1sg2Bu"
                ref={captchaRef}
                onChange={(e) => setTokenE(null)}
              />
              <span className="text-danger">{tokenE && tokenE}</span>
              <div className="outer required mt-3">
                <div className="form-group af-inner">
                  <input
                    type="submit"
                    className="form-button form-button-submit btn btn-block btn-theme w-100"
                    onClick={SendContact}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <p>
              If you would like to receive a quotation for car hire, please complete our online reservation form. For
              all other enquires, please complete the form below.
            </p>

            <ul className="media-list contact-list">
              <li className="media my-3">
                <div className="media-left pe-3">
                  <i className="fa fa-home"></i>
                </div>
                <div className="media-body">Kyrenia Head Office Besparmak Caddesi. No 1 / 4, Çatalkoy</div>
              </li>
              <li className="media my-3">
                <div className="media-left pe-3">
                  <i className="fa fa"></i>
                </div>
                <div className="media-body">North Cyprus</div>
              </li>
              <li className="media my-3">
                <div className="media-left pe-3">
                  <i className="fa fa-phone"></i>
                </div>
                <div className="media-body">Support Phone: +90 542 851 43 33</div>
              </li>
              <li className="media my-3">
                <div className="media-left pe-3">
                  <i className="fa fa-envelope"></i>
                </div>
                <div className="media-body">E mails: info@baspinar-rentals.com</div>
              </li>
              <li className="media my-3">
                <div className="media-left pe-3">
                  <i className="fa fa-clock-o"></i>
                </div>
                <div className="media-body">
                  High season 08:00am until 18:00am 15th June -25th October
                  <br />
                  Low season 08:00am until 17:00am 26th October -14th June
                  <br />
                  Saturdays 09:00am until 16:00am and 09:00am until 17:00pm
                </div>
              </li>
              <li className="media my-3">
                <div className="media-left pe-3">
                  <i className="fa fa-map-marker"></i>
                </div>
                <div className="media-body">View on The Map</div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactFooter;
