import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-meta">
        <div className="container">
          <div className="row">
            <div className="col-md-3">
              <h2 className="text-theme">Customer support</h2>
              <ul className="footerlist">
                <li>
                  <Link to="/faq">F.A.Q.</Link>
                </li>
                <li>
                  <Link to="/travel-guide">Travel Guide</Link>
                </li>
                <li>
                  <Link to="/booking">How do I make a reservation?</Link>
                </li>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
            <div className="col-sm-8">
              <div className="row">
                <div className="col-sm-12">
                  <p className="btn-row text-center">
                    <a
                      href="https://www.facebook.com/BaspinarRentACar/"
                      className="btn btn-theme ripple-effect btn-icon-left facebook wow fadeInDown"
                      data-wow-offset="20"
                      data-wow-delay="100ms"
                    >
                      <i className="fab fa-facebook-f pe-2"></i>FACEBOOK
                    </a>
                    <a
                      href="https://twitter.com/"
                      className="btn btn-theme btn-icon-left ripple-effect twitter wow fadeInDown"
                      data-wow-offset="20"
                      data-wow-delay="200ms"
                    >
                      <i className="fab fa-twitter pe-2"></i>TWITTER
                    </a>
                    <a
                      href="#"
                      className="btn btn-theme btn-icon-left ripple-effect pinterest wow fadeInDown"
                      data-wow-offset="20"
                      data-wow-delay="300ms"
                    >
                      <i className="fab fa-pinterest pe-2"></i>PINTEREST
                    </a>
                    <a
                      href="#"
                      className="btn btn-theme btn-icon-left ripple-effect google wow fadeInDown"
                      data-wow-offset="20"
                      data-wow-delay="400ms"
                    >
                      <i className="fab fa-google pe-2"></i>GOOGLE
                    </a>
                  </p>
                  <div className="copyright">© 2022 Baspinar Rent A Car</div>
                </div>
                <div className="col-sm-12">
                  <div style={{ textAlign: "center" }}>
                    <Link to="/terms-conditions">Terms &amp; Conditions</Link> |
                    <a href="#">Privacy Policy &amp; Cookies</a> |<Link to="/about">About Us</Link> |
                    <Link to="/contact">Contact</Link>
                    <div>
                      <a href="https://www.siteadvisor.com/sites/baspinar-rentals.com" target="_blank">
                        <img
                          src="https://baspinar-rentals.com/images/mcafee_siteadvisor_baspinar.jpg"
                          className="img-fluid"
                          alt="McAfee Site Advisor HappyDaysHoliday.com"
                          title="McAfee Site Advisor HappyDaysHoliday.com"
                        />
                      </a>
                    </div>
                    <div>
                      <img
                        src="https://baspinar-rentals.com/images/garanti.gif"
                        className="img-fluid"
                        alt="Payment Methods"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
