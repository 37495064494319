import React, { Fragment, useEffect, useState } from "react";
import "./productList.css";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import MetaData from "../layout/MetaData";
import { getAllCategories, deleteCategory } from "../../actions/categoryAction";
import { DELETE_CATEGORY_RESET } from "../../constants/categoryConstants";
import Loader from "../layout/Loader/Loader";
import { getAllTransferPrices, clearErrors, deleteTransferPrice } from "../../actions/transferPriceAction";
import { DELETE_TRANSFER_PRICE_RESET } from "../../constants/transferPriceConstants";

const TransferPrices = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();

  const { error, transferPrices, loading } = useSelector((state) => state.transferPrices);

  const { error: deleteError, isDeleted } = useSelector((state) => state.transferPrice);

  const deleteTransferPriceHandler = (id) => {
    dispatch(deleteTransferPrice(id));
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (deleteError) {
      alert.error(deleteError);
      dispatch(clearErrors());
    }
    if (isDeleted) {
      alert.success("Transfer Price Deleted Successfully");
      navigate("/admin/transfer-prices");
      dispatch({ type: DELETE_TRANSFER_PRICE_RESET });
    }

    dispatch(getAllTransferPrices());
  }, [dispatch, alert, error, deleteError, navigate, isDeleted]);

  return (
    <Fragment>
      <MetaData title={`ALL Users - Admin`} />
      <div className="card">
        <h1 id="productListHeading">All Transfer Prices</h1>
        {loading ? (
          <Loader />
        ) : (
          <div className="card-body px-0 py-0">
            <div className="table-responsive scrollbar">
              <table className="table table-sm fs--1 mb-0 overflow-hidden">
                <thead className="bg-200 text-900">
                  <tr>
                    <th className="align-middle white-space-nowrap">From</th>
                    <th className="align-middle white-space-nowrap">To</th>
                    <th className="align-middle white-space-nowrap text-end">Low Price</th>
                    <th className="align-middle white-space-nowrap text-end">High Price</th>
                    <th className="align-middle white-space-nowrap text-end">Created At</th>
                    <th className="no-align-middle data-table-row-action"></th>
                  </tr>
                </thead>
                <tbody className="list" id="table-purchase-body">
                  {transferPrices &&
                    transferPrices.map((item) => (
                      <tr className="btn-reveal-trigger">
                        <td className="align-middle white-space-nowrap">{item.from}</td>
                        <td className="align-middle white-space-nowrap">{item.to}</td>
                        <td className="align-middle white-space-nowrap text-end">{item.lowPrice}</td>
                        <td className="align-middle white-space-nowrap text-end">{item.highPrice}</td>
                        <td className="align-middle text-end">{String(item.createdAt).substr(0, 10)}</td>
                        <td className="align-middle white-space-nowrap text-end">
                          <div className="dropstart font-sans-serif position-static d-inline-block">
                            <button
                              className="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal float-end"
                              type="button"
                              id="dropdown0"
                              data-bs-toggle="dropdown"
                              data-boundary="window"
                              aria-haspopup="true"
                              aria-expanded="false"
                              data-bs-reference="parent"
                            >
                              <svg
                                className="svg-inline--fa fa-ellipsis-h fa-w-16 fs--1"
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fas"
                                data-icon="ellipsis-h"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                data-fa-i2svg=""
                              >
                                <path
                                  fill="currentColor"
                                  d="M328 256c0 39.8-32.2 72-72 72s-72-32.2-72-72 32.2-72 72-72 72 32.2 72 72zm104-72c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm-352 0c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72z"
                                ></path>
                              </svg>
                            </button>
                            <div className="dropdown-menu dropdown-menu-end border py-2" aria-labelledby="dropdown0">
                              <Link className="dropdown-item" to={`/admin/transfer-price/${item.id}`}>
                                Edit
                              </Link>
                              <div className="dropdown-divider"></div>
                              <Link
                                onClick={() => deleteTransferPriceHandler(item.id)}
                                className="dropdown-item text-danger"
                              >
                                Delete
                              </Link>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default TransferPrices;
