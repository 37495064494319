import { Splide, SplideSlide } from "@splidejs/react-splide";
import React, { Fragment } from "react";
import Breadcrumb from "../layout/Breadcrumb";

const Guzelyurt = () => {
  return (
    <Fragment>
      <Breadcrumb name="guzelyurt" parent="travel-guide" />
      <section className="page-section" style={{ paddingTop: "20px" }}>
        <div className="container">
          <div id="slider" className="row travel-guide">
            <div className="col-md-12">
              <article className="post-wrap">
                <Splide>
                  <SplideSlide>
                    <img src="/img/TravelGuide/Guzelyurt/1.jpg" className="img-fluid" alt="image 1" />
                  </SplideSlide>
                  <SplideSlide>
                    <img src="/img/TravelGuide/Guzelyurt/2.jpg" className="img-fluid" alt="image 2" />
                  </SplideSlide>
                  <SplideSlide>
                    <img src="/img/TravelGuide/Guzelyurt/3.jpg" className="img-fluid" alt="image 3" />
                  </SplideSlide>
                  <SplideSlide>
                    <img src="/img/TravelGuide/Guzelyurt/4.jpg" className="img-fluid" alt="image 4" />
                  </SplideSlide>
                  <SplideSlide>
                    <img src="/img/TravelGuide/Guzelyurt/5.jpg" className="img-fluid" alt="image 5" />
                  </SplideSlide>
                </Splide>
              </article>
            </div>
          </div>
          <p>
            <span id="lbPageContent"></span>
          </p>
          <div
            style={{
              boxSizing: "border-box",
              color: "#999999",
              fontFamily: "lato",
              fontSize: "16px",
              lineHeight: "22.8571434020996px",
            }}
          >
            <span style={{ boxSizing: "border-box", fontSize: "12pt" }}>
              Güzelyurt, formerly Gelveri, is a town and district of Aksaray Province in the Central Anatolia region of
              Turkey, at a distance of 45 km (28 mi) from the city of Aksaray. According to 2000 census, population of
              the district is 16,836 of which 3,775 live in the town of Güzelyurt. The district covers an area of 322
              km2 (124 sq mi), and the average elevation is 1,485 m (4,872 ft).
            </span>
          </div>
          <br />
          <div
            style={{
              boxSizing: "border-box",
              color: "#999999",
              fontFamily: "lato",
              fontSize: "16px",
              lineHeight: "22.8571434020996px",
            }}
          >
            <span style={{ boxSizing: "border-box", fontSize: "12pt" }}>
              This is an area of great natural beauty and historical importance, part of the ancient region of
              Cappadocia, near the much-visited Ihlara Valley.
            </span>
          </div>
          <br />
          <div
            style={{
              boxSizing: "border-box",
              color: "#999999",
              fontFamily: "lato",
              fontSize: "16px",
              lineHeight: "22.8571434020996px",
            }}
          >
            <span style={{ boxSizing: "border-box", fontSize: "12pt" }}>
              History Cappadocia has an important place in the history of Christianity and Gregory of Nazianzus lived in
              the area. A historically large Greek population existed in the area until the 1924 population exchange
              (see Cappadocian Greeks), when they were replaced with the Turks from Thessaloniki and Kavala.
            </span>
          </div>
          <p></p>
          <hr className="page-divider small" />
          <input type="hidden" name="hiddeUserControlType" id="hiddeUserControlType" />
          <div id="pnlUserControl"></div>
        </div>
      </section>
    </Fragment>
  );
};

export default Guzelyurt;
