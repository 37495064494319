import React, { Fragment, useEffect, useState } from "react";
import "./newProduct.css";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import MetaData from "../layout/MetaData";
import { useNavigate } from "react-router-dom";
import { createCategory, clearErrors } from "../../actions/categoryAction";
import { CREATE_CATEGORY_RESET } from "../../constants/categoryConstants";

const CreateCategory = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  const { loading, error, success } = useSelector((state) => state.createCategory);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (success) {
      alert.success("Category Created Successfully");

      navigate("/admin/categories");

      dispatch({ type: CREATE_CATEGORY_RESET });
    }
  }, [dispatch, alert, error, navigate, success]);

  const categoryImageHandler = (e) => {
    setImage(e.target.files[0]);
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setImagePreview(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const createCategorySubmitHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("name", name);
    myForm.set("description", description);
    myForm.set("image", image);

    dispatch(createCategory(myForm));
  };

  return (
    <Fragment>
      <MetaData title="Create Category" />
      <div className="card">
        <h1 id="productListHeading">Create category</h1>
        <div className="card-body px-0 py-0">
          <form className="AddProductForm" encType="multipart/form-data" onSubmit={createCategorySubmitHandler}>
            <div className="row">
              <div className="col1 col-lg-12">
                <div>
                  <label for="name" className="prodFormLabel">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    placeholder="Enter Category Name..."
                    className="prodFormInput"
                    required
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>

              <div className="col1 col-lg-8">
                <div>
                  <label for="additionalInfo" className="prodFormLabel">
                    Description
                  </label>
                  <textarea
                    type="text"
                    name="additionalInfo"
                    placeholder="Enter Description"
                    className="prodFormTextArea"
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </div>
              <div className="col1 col-lg-4 flex-column align-items-start">
                <label style={{ fontSize: "15px", margin: 0 }}>Images</label>
                <div className="file-input">
                  <label className="forImg prodFormLabel" style={{ background: `url('${imagePreview}')` }} for="images">
                    Select Image
                  </label>
                  <input
                    type="file"
                    placeholder="Select Product Images"
                    className="prodFormInputFile file"
                    onChange={categoryImageHandler}
                  />
                </div>
              </div>
            </div>
            <div className="col1 col-lg-12">
              <div>
                <input type="submit" className="btn btn-theme" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default CreateCategory;
