import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Breadcrumb from "../layout/Breadcrumb";
import ContactFooter from "../layout/ContactFooter";
import Loader from "../layout/Loader/LoaderSlider";
import MetaData from "../layout/MetaData";

const BookingSuccess = () => {
  const location = useLocation();

  const { booking, loading } = useSelector((state) => state.createBooking);
  const { transfer } = useSelector((state) => state.createTransfer);
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title="Baspinar - Booking Success" />
          <Breadcrumb parent="booking" name="Success" />
          <section className="page-section">
            <div className="container">
              <h2 className="section-title">
                <span>Enquiry Completed</span>
              </h2>

              <div className="message-box bg-success">
                <div className="message-box-inner">
                  <h2>
                    Thank you for your{" "}
                    {location.state.transfer && location.state.booking
                      ? "Booking & Transfer"
                      : location.state.booking
                      ? "Booking"
                      : "Transfer"}
                    .
                  </h2>
                  {location.state.transfer && location.state.booking
                    ? `Your Booking Number is ${booking.id} & Transfer Number is ${transfer && transfer.id}`
                    : location.state.booking
                    ? `Your Booking Number is ${booking.id}`
                    : `Your Transfer Number is ${transfer && transfer.id}`}
                  <p>We will contact you within the next 48 hours.</p>
                </div>
              </div>
            </div>
          </section>
          <ContactFooter />
        </Fragment>
      )}
    </Fragment>
  );
};

export default BookingSuccess;
