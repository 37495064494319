import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import {
  allUsersReducer,
  forgotPasswordReducer,
  profileReducer,
  userDetailsReducer,
  userReducer,
} from "./reducers/userReducer";
import {
  categoriesReducer,
  categoryDetailsReducer,
  categoryReducer,
  createCategoryReducer,
} from "./reducers/categoryReducer";
import {
  createCarReducer,
  carReducer,
  carsReducer,
  carDetailsReducer,
} from "./reducers/carReducer";
import {
  allBookingsReducer,
  bookingDetailsReducer,
  bookingReducer,
  myBookingsReducer,
  newBookingReducer,
} from "./reducers/bookingReducer";
import {
  allTransfersReducer,
  myTransfersReducer,
  newTransferReducer,
  transferDetailsReducer,
  transferReducer,
} from "./reducers/transferReducer";
import {
  createTransferPriceReducer,
  transferPriceDetailsReducer,
  transferPriceReducer,
  transferPricesReducer,
} from "./reducers/transferPriceReducer";

const reducer = combineReducers({
  user: userReducer,
  profile: profileReducer,
  forgotPassword: forgotPasswordReducer,
  allUsers: allUsersReducer,
  userDetails: userDetailsReducer,
  categories: categoriesReducer,
  createCategory: createCategoryReducer,
  category: categoryReducer,
  categoryDetails: categoryDetailsReducer,
  transferPrices: transferPricesReducer,
  createTransferPrice: createTransferPriceReducer,
  transferPrice: transferPriceReducer,
  transferPriceDetails: transferPriceDetailsReducer,
  cars: carsReducer,
  createCar: createCarReducer,
  car: carReducer,
  carDetails: carDetailsReducer,
  createBooking: newBookingReducer,
  bookings: allBookingsReducer,
  booking: bookingReducer,
  bookingDetails: bookingDetailsReducer,
  myBookings: myBookingsReducer,
  createTransfer: newTransferReducer,
  transfers: allTransfersReducer,
  transfer: transferReducer,
  transferDetails: transferDetailsReducer,
  myTransfers: myTransfersReducer,
});

let initialState = {
  createBooking: {
    bookingInfo: localStorage.getItem("bookingInfo")
      ? JSON.parse(localStorage.getItem("bookingInfo"))
      : {},
  },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
