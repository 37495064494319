import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const Breadcrumb = (props) => {
  return (
    <Fragment>
      <section className="page-section breadcrumbs">
        <div className="container">
          <div className="page-header">
            <h1>{props.name}</h1>
          </div>
          <ul className="breadcrumb">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <a>{props.parent}</a>
            </li>
            <li className="active">{props.name}</li>
          </ul>
        </div>
      </section>
    </Fragment>
  );
};

export default Breadcrumb;
