import {
  CREATE_TRANSFER_REQUEST,
  CREATE_TRANSFER_SUCCESS,
  CREATE_TRANSFER_FAIL,
  MY_TRANSFERS_REQUEST,
  MY_TRANSFERS_SUCCESS,
  MY_TRANSFERS_FAIL,
  ALL_TRANSFERS_REQUEST,
  ALL_TRANSFERS_SUCCESS,
  ALL_TRANSFERS_FAIL,
  UPDATE_TRANSFER_REQUEST,
  UPDATE_TRANSFER_SUCCESS,
  UPDATE_TRANSFER_FAIL,
  UPDATE_TRANSFER_RESET,
  DELETE_TRANSFER_REQUEST,
  DELETE_TRANSFER_SUCCESS,
  DELETE_TRANSFER_FAIL,
  DELETE_TRANSFER_RESET,
  TRANSFER_DETAILS_REQUEST,
  TRANSFER_DETAILS_SUCCESS,
  TRANSFER_DETAILS_FAIL,
  CLEAR_ERRORS,
  CREATE_TRANSFER_RESET,
} from "../constants/transferConstants";

export const newTransferReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_TRANSFER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_TRANSFER_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        transfer: action.payload.transfer,
      };
    case CREATE_TRANSFER_RESET:
      return {
        ...state,
        success: false,
      };
    case CREATE_TRANSFER_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const myTransfersReducer = (state = { transfers: [] }, action) => {
  switch (action.type) {
    case MY_TRANSFERS_REQUEST:
      return {
        loading: true,
      };
    case MY_TRANSFERS_SUCCESS:
      return {
        loading: false,
        transfers: action.payload,
      };
    case MY_TRANSFERS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const allTransfersReducer = (state = { transfers: [] }, action) => {
  switch (action.type) {
    case ALL_TRANSFERS_REQUEST:
      return {
        loading: true,
      };
    case ALL_TRANSFERS_SUCCESS:
      return {
        loading: false,
        transfers: action.payload,
      };
    case ALL_TRANSFERS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const transferReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_TRANSFER_REQUEST:
    case DELETE_TRANSFER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_TRANSFER_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_TRANSFER_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };
    case UPDATE_TRANSFER_FAIL:
    case DELETE_TRANSFER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_TRANSFER_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case DELETE_TRANSFER_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const transferDetailsReducer = (state = { transfer: {} }, action) => {
  switch (action.type) {
    case TRANSFER_DETAILS_REQUEST:
      return {
        loading: true,
      };
    case TRANSFER_DETAILS_SUCCESS:
      return {
        loading: false,
        transfer: action.payload,
      };
    case TRANSFER_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
