import "./App.css";
import { useLayoutEffect, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import React from "react";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import Admin from "./Admin.js";
import Web from "./Web.js";

function App() {
  const Wrapper = ({ children }) => {
    const location = useLocation();

    useLayoutEffect(() => {
      document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);

    return children;
  };

  return (
    <Router>
      <Wrapper>
        <Routes>
          <Route path="/admin/*" element={<Admin />} />
          <Route path="/*" element={<Web />} />
        </Routes>
      </Wrapper>
    </Router>
  );
}

export default App;
