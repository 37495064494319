import { Splide, SplideSlide } from "@splidejs/react-splide";
import React, { Fragment } from "react";
import Breadcrumb from "../layout/Breadcrumb";

const Kyrenia = () => {
  return (
    <Fragment>
      <Breadcrumb name="kyrenia" parent="travel-guide" />
      <section className="page-section" style={{ paddingTop: "20px" }}>
        <div className="container">
          <div id="slider" className="row travel-guide">
            <div className="col-md-12">
              <article className="post-wrap">
                <Splide>
                  <SplideSlide>
                    <img src="/img/TravelGuide/Kyrenia/1.jpg" className="img-fluid" alt="image 1" />
                  </SplideSlide>
                  <SplideSlide>
                    <img src="/img/TravelGuide/Kyrenia/2.jpg" className="img-fluid" alt="image 2" />
                  </SplideSlide>
                  <SplideSlide>
                    <img src="/img/TravelGuide/Kyrenia/3.jpg" className="img-fluid" alt="image 3" />
                  </SplideSlide>
                  <SplideSlide>
                    <img src="/img/TravelGuide/Kyrenia/4.jpg" className="img-fluid" alt="image 4" />
                  </SplideSlide>
                  <SplideSlide>
                    <img src="/img/TravelGuide/Kyrenia/5.jpg" className="img-fluid" alt="image 5" />
                  </SplideSlide>
                </Splide>
              </article>
            </div>
          </div>
          <p>
            <span id="lbPageContent"></span>
          </p>
          <div
            style={{
              boxSizing: "border-box",
              color: "#999999",
              fontFamily: "lato",
              fontSize: "16px",
              lineHeight: "22.8571434020996px",
            }}
          >
            <span style={{ boxSizing: "border-box", fontSize: "12pt" }}>
              North Cyprus is home to the harbour city of Kyrenia and some of the most amazing sites in Cyprus.
              &nbsp;Although many holiday makers may be forgiven for thinking that Kyrenia is just a harbour town, there
              really is a lot more to see and do. &nbsp;Filled with historical buildings, superb scenery, spectacular
              natural un-spoilt sandy beaches, and much more, Kyrenia makes the perfect destination for any holiday.
            </span>
          </div>
          <div
            style={{
              boxSizing: "border-box",
              color: "#999999",
              fontFamily: "lato",
              fontSize: "16px",
              lineHeight: "22.8571434020996px",
            }}
          >
            <span style={{ boxSizing: "border-box", fontSize: "12pt" }}>
              The calming atmosphere of this beautiful sea-front city makes holidaying in Kyrenia a relaxing and
              thoroughly enjoyable experience. &nbsp;The old town of Kyrenia is filled with whitewashed buildings and
              the ruins of walls and towers that once protected this city from attack. &nbsp;Whilst the city of Kyrenia
              may seem quite and asleep during the day, once the sun sets the atmosphere is transformed as restaurant
              and tavern owners prepare for the night and the entertainment it brings.
            </span>
          </div>

          <p></p>
          <hr className="page-divider small" />
          <input type="hidden" name="hiddeUserControlType" id="hiddeUserControlType" />
          <div id="pnlUserControl"></div>
        </div>
      </section>
    </Fragment>
  );
};

export default Kyrenia;
