import "./App.css";
import { useState, useEffect, Fragment } from "react";
import Header from "./component/layout/Header/Header.js";
import Footer from "./component/layout/Footer/Footer.js";
import Home from "./component/Home/Home.js";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import React from "react";
import LoginSignUp from "./component/User/LoginSignUp";
import UserProfile from "./component/User/UserProfile.js";
import UserBookings from "./component/User/UserBookings";
import ForgotPassowrd from "./component/User/ForgotPassowrd.js";
import ResetPassword from "./component/User/ResetPassword.js";
import ProtectedRoute from "./component/Route/ProtectedRoute";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import CarPrices from "./component/Cars/CarPrices.js";
import Cars from "./component/Cars/Cars.js";
import About from "./component/Pages/About.js";
import Contact from "./component/Pages/Contact.js";
import Kyrenia from "./component/TravelGuide/Kyrenia.js";
import Nicosia from "./component/TravelGuide/Nicosia.js";
import Famagusta from "./component/TravelGuide/Famagusta.js";
import Karpaz from "./component/TravelGuide/Karpaz.js";
import Guzelyurt from "./component/TravelGuide/Guzelyurt.js";
import CarBooking from "./component/Cars/CarBooking";
import Transfer from "./component/Transfer/Transfer";
import UserTransfers from "./component/User/UserTransfers";
import ConfirmBooking from "./component/Cars/ConfirmBooking";
import BookingSuccess from "./component/Cars/BookingSuccess";
import Booking from "./component/Booking/Booking";
import TermsConditions from "./component/Pages/TermsConditions";
import FAQs from "./component/Pages/FAQs";
import TravelGuide from "./component/Pages/TravelGuide";

const Web = () => {
  return (
    <Fragment>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route exact path="/cars" element={<Cars />} />
        <Route path="/car-prices" element={<CarPrices />} />
        <Route exact path="/car/:id" element={<CarBooking />} />
        <Route exact path="/booking" element={<Booking />} />
        <Route exact path="/booking/confirm" element={<ConfirmBooking />} />
        <Route exact path="/booking/success" element={<BookingSuccess />} />
        <Route path="/about" element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms-conditions" element={<TermsConditions />} />
        <Route path="/faq" element={<FAQs />} />
        <Route path="/transfer" element={<Transfer />} />
        <Route path="/travel-guide" element={<TravelGuide />} />
        <Route path="/travel-guide/kyrenia" element={<Kyrenia />} />
        <Route path="/travel-guide/nicosia" element={<Nicosia />} />
        <Route path="/travel-guide/famagusta" element={<Famagusta />} />
        <Route path="/travel-guide/karpaz" element={<Karpaz />} />
        <Route path="/travel-guide/guzelyurt" element={<Guzelyurt />} />
        <Route exact path="/login" element={<LoginSignUp />} />
      </Routes>
      <Footer />
    </Fragment>
  );
};

export default Web;
