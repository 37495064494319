import React, { Fragment, useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { clearErrors, createTransfer } from "../../actions/transferAction";
import Breadcrumb from "../layout/Breadcrumb";
import Loader from "../layout/Loader/Loader";
import $ from "jquery";
import { CREATE_TRANSFER_RESET } from "../../constants/transferConstants";
import axios from "axios";
import DatePicker from "react-datepicker";
import TimeSelect from "../layout/TimeSelect";
import ReCAPTCHA from "react-google-recaptcha";
import moment from "moment";

const Transfer = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { error, loading, success } = useSelector((state) => state.createTransfer);
  const [transferPrices, setTransferPrices] = useState();

  useEffect(() => {
    axios.get("/api/v1/transfer-prices").then((response) => {
      setTransferPrices(response.data.transferPrices);
    });
  }, []);

  const [arrival, setArrival] = useState(true);
  const [arrivalPickUpLocation, setArrivalPickUpLocation] = useState("");
  const [arrivalPickUpDate, setArrivalPickUpDate] = useState("");
  const [arrivalPickUpTime, setArrivalPickUpTime] = useState("00:00");
  const [arrivalPassengers, setArrivalPassengers] = useState(1);
  const [arrivalFlightNo, setArrivalFlightNo] = useState("");
  const [arrivalBabySeat, setArrivalBabySeat] = useState(0);
  const [arrivalChildBooster, setArrivalChildBooster] = useState(0);
  const [arrivalDropOffLocation, setArrivalDropOffLocation] = useState("");
  const [arrivalDropOffLocationDetails, setArrivalDropOffLocationDetails] = useState("");
  const [departure, setDeparture] = useState(true);
  const [departurePickUpLocation, setDeparturePickUpLocation] = useState("");
  const [departurePickUpDate, setDeparturePickUpDate] = useState("");
  const [departurePickUpTime, setDeparturePickUpTime] = useState("00:00");
  const [departurePassengers, setDeparturePassengers] = useState(1);
  const [departureBabySeat, setDepartureBabySeat] = useState(0);
  const [departureChildBooster, setDepartureChildBooster] = useState(0);
  const [departureDropOffLocation, setDepartureDropOffLocation] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerSurname, setCustomerSurname] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [customerPlaceInCyprus, setCustomerPlaceInCyprus] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerCellPhone, setCustomerCellPhone] = useState("");
  const [customerMessage, setCustomerMessage] = useState("");
  const [customerDateOfBirth, setCustomerDateOfBirth] = useState("");
  const [termsConditions, setTermsConditions] = useState("");

  const captchaRef = useRef(null);

  const [arrivalPrice, setArrivalPrice] = useState(0);

  const monthArrival = arrivalPickUpDate && arrivalPickUpDate.getMonth();

  useEffect(() => {
    axios.get(`/api/v1/transfer-prices?from=${arrivalPickUpLocation}&to=${arrivalDropOffLocation}`).then((response) => {
      monthArrival >= 10 || monthArrival <= 1
        ? setArrivalPrice(response.data.transferPrices[0].lowPrice)
        : setArrivalPrice(response.data.transferPrices[0].highPrice);
    });
  }, [monthArrival, arrivalPickUpLocation, arrivalDropOffLocation]);

  const [departurePrice, setDeparturePrice] = useState(0);

  const monthDeparture = departurePickUpDate && departurePickUpDate.getMonth();

  useEffect(() => {
    axios
      .get(`/api/v1/transfer-prices?from=${departureDropOffLocation}&to=${departurePickUpLocation}`)
      .then((response) => {
        monthDeparture >= 10 || monthDeparture <= 1
          ? setDeparturePrice(response.data.transferPrices[0].lowPrice)
          : setDeparturePrice(response.data.transferPrices[0].highPrice);
      });
  }, [monthDeparture, departureDropOffLocation, departurePickUpLocation]);

  const totalPrice =
    arrivalPrice +
    arrivalBabySeat * 2 +
    arrivalChildBooster * 2 +
    departurePrice +
    departureBabySeat * 2 +
    departureChildBooster * 2;

  var transfer = {
    ...(arrival && {
      arrivalPickupLocation: arrivalPickUpLocation,
      arrivalPickupDate: moment(arrivalPickUpDate).format("DD/MM/YYYY"),
      arrivalPickupTime: arrivalPickUpTime,
      arrivalPassengers: arrivalPassengers,
      arrivalBabySeat: arrivalBabySeat,
      arrivalChildBooster: arrivalChildBooster,
      arrivalFlightNo: arrivalFlightNo,
      arrivalDropoffLocation: arrivalDropOffLocation,
      arrivalDropoffLocationDetails: arrivalDropOffLocationDetails,
    }),
    ...(departure && {
      departurePickupLocation: departurePickUpLocation,
      departurePickupDate: moment(departurePickUpDate).format("DD/MM/YYYY"),
      departurePickupTime: departurePickUpTime,
      departurePassengers: departurePassengers,
      departureBabySeat: departureBabySeat,
      departureChildBooster: departureChildBooster,
      departureDropoffLocation: departureDropOffLocation,
    }),
    customerDetails: {
      name: customerName,
      surname: customerSurname,
      email: customerEmail,
      address: customerAddress,
      placeInNorthCyprus: customerPlaceInCyprus,
      phone: customerPhone,
      cellPhone: customerCellPhone,
      message: customerMessage,
      dateOfBirth: customerDateOfBirth,
    },
    totalPrice: totalPrice,
  };

  const handleChangeArrival = () => {
    if (arrival === false) {
      $("#ArrivalDiv").slideDown(400);
    } else {
      $("#ArrivalDiv").slideUp(400);
    }
    setArrival(!arrival);
  };

  const handleChangeDeparture = () => {
    if (departure === false) {
      $("#DepartureDiv").slideDown(400);
    } else {
      $("#DepartureDiv").slideUp(400);
    }
    setDeparture(!departure);
  };

  // Form Input Errors
  const [inputError, setInputError] = useState(false);
  const [tokenE, setTokenE] = useState();
  // Transfer
  const [arrPickLocE, setArrPickLocE] = useState();
  const [arrDropLocE, setArrDropLocE] = useState();
  const [arrPickDateE, setArrPickDateE] = useState();
  const [arrFlightE, setArrFlightE] = useState();
  const [depPickLocE, setDepPickLocE] = useState();
  const [depDropLocE, setDepDropLocE] = useState();
  const [depPickDateE, setDepPickDateE] = useState();
  // Customer
  const [cusNameE, setCusNameE] = useState();
  const [cusSurnameE, setCusSurnameE] = useState();
  const [cusEmailE, setCusEmailE] = useState();
  const [cusPhoneE, setCusPhoneE] = useState();
  const [cusCellPhoneE, setCusCellPhoneE] = useState();
  const [cusMessageE, setCusMessageE] = useState();
  const [cusPlaceCyprusE, setCusPlaceCyprusE] = useState();
  const [termsCondE, setTermsCondE] = useState(false);
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const transferSubmitHandler = (e) => {
    e.preventDefault();
    let isError = false;
    const token = captchaRef.current.getValue();
    captchaRef.current.reset();

    if (!customerName) {
      window.scrollTo({
        top: document.getElementById("customerinformation").offsetTop,
      });
      setCusNameE("Enter Your Name");
      setInputError(true);
      isError = true;
    }
    if (!customerSurname) {
      window.scrollTo({
        top: document.getElementById("customerinformation").offsetTop,
      });
      setCusSurnameE("Enter Your Surname");
      setInputError(true);
      isError = true;
    }
    if (!customerEmail || !emailRegex.test(customerEmail)) {
      window.scrollTo({
        top: document.getElementById("customerinformation").offsetTop,
      });
      setCusEmailE("Email is not Valid");
      setInputError(true);
      isError = true;
    }
    if (!customerPlaceInCyprus) {
      window.scrollTo({
        top: document.getElementById("customerinformation").offsetTop,
      });
      setCusPlaceCyprusE("Enter Your Place to Stay");
      setInputError(true);
      isError = true;
    }
    if (!customerPhone) {
      window.scrollTo({
        top: document.getElementById("customerinformation").offsetTop,
      });
      setCusPhoneE("Enter Your Phone Number");
      setInputError(true);
      isError = true;
    }
    if (!customerMessage) {
      window.scrollTo({
        top: document.getElementById("customerinformation").offsetTop,
      });
      setCusMessageE("Enter Your Message");
      setInputError(true);
      isError = true;
    }
    if (!customerCellPhone) {
      window.scrollTo({
        top: document.getElementById("customerinformation").offsetTop,
      });
      setCusCellPhoneE("Enter Your Cell Phone Number");
      setInputError(true);
      isError = true;
    }
    if (!termsConditions) {
      window.scrollTo({
        top: document.getElementById("customerinformation").offsetTop,
      });
      setTermsCondE("Please accept all Terms and Conditions");
      setInputError(true);
      isError = true;
    }
    if (!token) {
      window.scrollTo({
        top: document.getElementById("customerinformation").offsetTop,
      });
      setTokenE("Fill Recaptcha");
      setInputError(true);
      isError = true;
    }
    if (arrival) {
      if (!arrivalPickUpLocation) {
        window.scrollTo({
          top: document.getElementById("transfersection").offsetTop,
        });
        setArrPickLocE("Enter Pickup Location");
        setInputError(true);
        isError = true;
      }
      if (!arrivalDropOffLocation) {
        window.scrollTo({
          top: document.getElementById("transfersection").offsetTop,
        });
        setArrDropLocE("Enter Dropoff Location");
        setInputError(true);
        isError = true;
      }
      if (!arrivalPickUpDate) {
        window.scrollTo({
          top: document.getElementById("transfersection").offsetTop,
        });
        setArrPickDateE("Select Pickup Date");
        setInputError(true);
        isError = true;
      }
      if (!arrivalFlightNo) {
        window.scrollTo({
          top: document.getElementById("transfersection").offsetTop,
        });
        setArrFlightE("Enter Flight No");
        setInputError(true);
        isError = true;
      }
    }
    if (departure) {
      if (!departurePickUpLocation) {
        window.scrollTo({
          top: document.getElementById("transfersection").offsetTop,
        });
        setDepPickLocE("Enter Pickup Location");
        setInputError(true);
        isError = true;
      }
      if (!departureDropOffLocation) {
        window.scrollTo({
          top: document.getElementById("transfersection").offsetTop,
        });
        setDepDropLocE("Enter Dropoff Location");
        setInputError(true);
        isError = true;
      }
      if (!departurePickUpDate) {
        window.scrollTo({
          top: document.getElementById("transfersection").offsetTop,
        });
        setDepPickDateE("Select Pickup Date");
        setInputError(true);
        isError = true;
      }
    }

    if (!isError) {
      setInputError(false);
      dispatch(createTransfer(transfer));
    }
  };

  useEffect(() => {
    if (inputError) {
      arrivalPickUpLocation ? setArrPickLocE() : setArrPickLocE("Enter Pickup Location");
      arrivalDropOffLocation ? setArrDropLocE() : setArrDropLocE("Enter Dropoff Location");
      arrivalPickUpDate ? setArrPickDateE() : setArrPickDateE("Select Pickup Date");
      arrivalFlightNo ? setArrFlightE() : setArrFlightE("Enter Flight No");
      departurePickUpLocation ? setDepPickLocE() : setDepPickLocE("Enter Pickup Location");
      departureDropOffLocation ? setDepDropLocE() : setDepDropLocE("Enter Dropoff Location");
      departurePickUpDate ? setDepPickDateE() : setDepPickDateE("Select Pickup Date");
      customerName ? setCusNameE() : setCusNameE("Enter Your Name");
      customerPhone ? setCusPhoneE() : setCusPhoneE("Enter Your Phone Number");
      customerCellPhone ? setCusCellPhoneE() : setCusCellPhoneE("Enter Your Cell Phone Number");
      customerSurname ? setCusSurnameE() : setCusSurnameE("Enter Your Surname");
      customerMessage ? setCusMessageE() : setCusMessageE("Enter Your Message");
      !customerEmail || !emailRegex.test(customerEmail) ? setCusEmailE("Email is not Valid") : setCusEmailE();
      customerPlaceInCyprus ? setCusPlaceCyprusE() : setCusPlaceCyprusE("Enter Your Place to Stay");

      termsConditions ? setTermsCondE() : setTermsCondE("Please accept all Terms and Conditions");
    }
  }, [
    customerName,
    customerSurname,
    customerEmail,
    customerPlaceInCyprus,
    customerPhone,
    customerMessage,
    customerCellPhone,
    termsConditions,
    arrivalPickUpLocation,
    arrivalDropOffLocation,
    arrivalPickUpDate,
    departurePickUpLocation,
    departureDropOffLocation,
    departurePickUpDate,
    arrivalFlightNo,
  ]);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (success) {
      navigate("/booking/success", { state: { transfer: true } });
      dispatch({ type: CREATE_TRANSFER_RESET });
    }
  }, [$, dispatch, error, alert, success]);

  const handleChangeTerms = () => {
    setTermsConditions(!termsConditions);
  };

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <Breadcrumb parent="cars" name="Transfer" />
          <section className="page-section with-sidebar sub-page">
            <div className="container">
              <div className="row">
                <div className="col-md-9 content" id="content">
                  <div className="mb-4">
                    <button
                      className="btn btn-theme w-100"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#transferPrices"
                      aria-expanded="false"
                      aria-controls="transferPrices"
                    >
                      Show Transfer Prices
                    </button>
                    <div className="collapse" id="transferPrices">
                      <table className="table table-striped panel panel-default mb-0">
                        <thead>
                          <tr>
                            <th scope="col">From</th>
                            <th scope="col">To</th>
                            <th scope="col">Low Season</th>
                            <th scope="col">High Season</th>
                          </tr>
                        </thead>
                        <tbody>
                          {transferPrices &&
                            transferPrices.map((item) => (
                              <tr>
                                <td>{item.from}</td>
                                <td>{item.to}</td>
                                <td>{item.lowPrice}</td>
                                <td>{item.highPrice}</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="panel panel-default" id="transfersection">
                    <div className="panel-heading">
                      <h3 className="text-theme">
                        <input type="checkbox" checked={arrival} onChange={handleChangeArrival} />
                        Arrival
                      </h3>
                    </div>
                    <div className="panel-body" id="ArrivalDiv">
                      <div className="row">
                        <div className="col-md-4">
                          <label className="form-label-theme">Pick Up</label>
                          <select
                            className="form-control"
                            required
                            onChange={(e) => setArrivalPickUpLocation(e.target.value)}
                          >
                            <option selected disabled hidden>
                              Select
                            </option>
                            {transferPrices &&
                              Array.from(new Set(transferPrices.map((item) => item.from))).map((item) => (
                                <option value={item}>{item}</option>
                              ))}
                          </select>
                          <span className="text-danger">{arrPickLocE && arrPickLocE}</span>
                          <div className="clearfix"></div>
                          <label className="form-label-theme">Drop Off</label>
                          <select
                            className="form-control"
                            required
                            onChange={(e) => setArrivalDropOffLocation(e.target.value)}
                          >
                            <option selected disabled hidden>
                              Select
                            </option>
                            {transferPrices &&
                              Array.from(new Set(transferPrices.map((item) => item.to))).map((item) => (
                                <option value={item}>{item}</option>
                              ))}
                          </select>
                          <span className="text-danger">{arrDropLocE && arrDropLocE}</span>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Detail Address"
                            required
                            onChange={(e) => setArrivalDropOffLocationDetails(e.target.value)}
                          />
                        </div>
                        <div className="col-md-3">
                          <div className="f-item datepicker">
                            <div className="form-group has-icon has-label">
                              <label className="form-label-theme">Pick Up Date</label>
                              <DatePicker
                                className="form-control"
                                selected={arrivalPickUpDate}
                                onChange={(date) => setArrivalPickUpDate(date)}
                                minDate={new Date()}
                                placeholderText="dd/MM/yyyy"
                                dateFormat="dd/MM/yyyy"
                              />
                              <span className="form-control-icon">
                                <i className="far fa-calendar"></i>
                              </span>
                              <span className="text-danger">{arrPickDateE && arrPickDateE}</span>
                            </div>
                            <div className="form-group has-label has-icon">
                              <label className="form-label-theme">Time</label>
                              <TimeSelect onChange={(value) => setArrivalPickUpTime(value)} />
                              <span className="form-control-icon">
                                <i className="far fa-clock"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="f-item small">
                            <label className="form-label-theme">Passenger</label>
                            <select
                              className="form-control"
                              required
                              onChange={(e) => setArrivalPassengers(e.target.value)}
                            >
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                            </select>

                            <label className="form-label-theme">Flight No</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Flight No"
                              required
                              onChange={(e) => setArrivalFlightNo(e.target.value)}
                            />
                            <span className="text-danger">{arrFlightE && arrFlightE}</span>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <label className="form-label-theme">Baby Seats</label>
                          <select className="form-control" onChange={(e) => setArrivalBabySeat(e.target.value)}>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                          </select>
                          <label className="form-label-theme">Child Booster</label>
                          <select className="form-control" onChange={(e) => setArrivalChildBooster(e.target.value)}>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="clearfix"></div>
                  <div className="line4"></div>
                  <div className="panel panel-default" style={{ borderBottom: "none" }}>
                    <div className="panel-heading">
                      <h3 className="text-theme">
                        <input type="checkbox" checked={departure} onChange={handleChangeDeparture} />
                        Departure
                      </h3>
                    </div>
                    <div className="panel-body" id="DepartureDiv">
                      <div className="row">
                        <div className="col-md-4">
                          <div className="f-item">
                            <label className="form-label-theme">Pick Up</label>
                            <select
                              className="form-control"
                              required
                              onChange={(e) => setDeparturePickUpLocation(e.target.value)}
                            >
                              <option selected disabled hidden>
                                Select
                              </option>
                              {transferPrices &&
                                Array.from(new Set(transferPrices.map((item) => item.to))).map((item) => (
                                  <option value={item}>{item}</option>
                                ))}
                            </select>
                            <span className="text-danger">{depPickLocE && depPickLocE}</span>
                            <div className="clearfix"></div>
                            <label className="form-label-theme">Drop Off</label>
                            <select
                              required
                              onChange={(e) => setDepartureDropOffLocation(e.target.value)}
                              className="form-control"
                            >
                              <option selected disabled hidden>
                                Select
                              </option>
                              {transferPrices &&
                                Array.from(new Set(transferPrices.map((item) => item.from))).map((item) => (
                                  <option value={item}>{item}</option>
                                ))}
                            </select>
                            <span className="text-danger">{depDropLocE && depDropLocE}</span>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="f-item datepicker">
                            <div className="form-group has-icon has-label">
                              <label className="form-label-theme">Pick Up Date</label>
                              <div className="datepicker-wrap">
                                <DatePicker
                                  className="form-control"
                                  selected={departurePickUpDate}
                                  onChange={(date) => setDeparturePickUpDate(date)}
                                  minDate={new Date()}
                                  placeholderText="dd/MM/yyyy"
                                  dateFormat="dd/MM/yyyy"
                                />
                                <span className="form-control-icon">
                                  <i className="far fa-calendar"></i>
                                </span>
                                <span className="text-danger">{depPickDateE && depPickDateE}</span>
                              </div>
                            </div>
                            <div className="form-group has-label has-icon">
                              <label className="form-label-theme">Time</label>
                              <TimeSelect onChange={(value) => setDeparturePickUpTime(value)} />
                              <span className="form-control-icon">
                                <i className="far fa-clock"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2">
                          <div className="f-item small">
                            <label className="form-label-theme">Passenger</label>
                            <select
                              className="form-control"
                              required
                              onChange={(e) => setDeparturePassengers(e.target.value)}
                            >
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <label className="form-label-theme">Baby Seats</label>
                          <select className="form-control" onChange={(e) => setDepartureBabySeat(e.target.value)}>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                          </select>
                          <label className="form-label-theme">Child Booster</label>
                          <select className="form-control" onChange={(e) => setDepartureChildBooster(e.target.value)}>
                            <option value="0">0</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h3 className="block-title alt" id="customerinformation">
                    <i className="fas fa-angle-down"></i>Customer Information
                  </h3>
                  <div className="panel-group payments-options">
                    <div className="panel panel-default">
                      <div className="panel-body">
                        <div className="panel panel-default" style={{ borderBottom: "none" }}>
                          <div className="row pb-3">
                            <div className="col-md-6 mt-4">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="name"
                                  className="form-control alt"
                                  placeholder="Name:*"
                                  required
                                  onChange={(e) => setCustomerName(e.target.value)}
                                />
                                <span className="text-danger">{cusNameE && cusNameE}</span>
                              </div>
                            </div>
                            <div className="col-md-6 mt-4">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="surname"
                                  className="form-control alt"
                                  placeholder="Surname:*"
                                  required
                                  onChange={(e) => setCustomerSurname(e.target.value)}
                                />
                                <span className="text-danger">{cusSurnameE && cusSurnameE}</span>
                              </div>
                            </div>
                            <div className="col-md-6 mt-4">
                              <div className="form-group">
                                <input
                                  placeholder="Date of Birth"
                                  type="text"
                                  name="dateOfBirth"
                                  className="form-control alt"
                                  onChange={(e) => setCustomerDateOfBirth(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-6 mt-4">
                              <div className="form-group">
                                <input
                                  type="email"
                                  name="email"
                                  className="form-control alt"
                                  placeholder="Your Email Address:*"
                                  required
                                  onChange={(e) => setCustomerEmail(e.target.value)}
                                />
                                <span className="text-danger">{cusEmailE && cusEmailE}</span>
                              </div>
                            </div>
                            <div className="col-md-12 mt-4">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="address"
                                  className="form-control alt"
                                  placeholder="Your Address:"
                                  required
                                  onChange={(e) => setCustomerAddress(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-12 mt-4">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="address"
                                  className="form-control alt"
                                  placeholder="Place to Stay in North Cyprus:*"
                                  required
                                  onChange={(e) => setCustomerPlaceInCyprus(e.target.value)}
                                />
                                <span className="text-danger">{cusPlaceCyprusE && cusPlaceCyprusE}</span>
                              </div>
                            </div>
                            <div className="col-md-6 mt-4">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="phoneNo"
                                  className="form-control alt"
                                  placeholder="Phone:*"
                                  required
                                  onChange={(e) => setCustomerPhone(e.target.value)}
                                />
                                <span className="text-danger">{cusPhoneE && cusPhoneE}</span>
                              </div>
                            </div>
                            <div className="col-md-6 mt-4">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="phoneNo"
                                  className="form-control alt"
                                  placeholder="Cell Phone:*"
                                  required
                                  onChange={(e) => setCustomerCellPhone(e.target.value)}
                                />
                                <span className="text-danger">{cusCellPhoneE && cusCellPhoneE}</span>
                              </div>
                            </div>
                            <div className="col-md-12 mt-4">
                              <div className="form-group">
                                <textarea
                                  rows={5}
                                  type="text"
                                  name="message"
                                  className="form-control alt"
                                  placeholder="Message:*"
                                  required
                                  onChange={(e) => setCustomerMessage(e.target.value)}
                                ></textarea>
                                <span className="text-danger">{cusMessageE && cusMessageE}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ReCAPTCHA
                    sitekey="6LeKxzMkAAAAANgCKy2BAxnM3zi6ECNwJt1sg2Bu"
                    ref={captchaRef}
                    onChange={(e) => setTokenE(null)}
                  />
                  <span className="text-danger mb-4">{tokenE && tokenE}</span>
                  <hr className="mt-4" />
                  <div className="row mb-3">
                    <div className="col-9 d-flex flex-column justify-content-center">
                      <div>
                        <input type="checkbox" required onChange={handleChangeTerms} />
                        <label className="fw-bold">
                          I accept all <a className="text-theme">Terms and Conditions</a>
                        </label>
                      </div>
                      <div className="row">
                        <span className="text-danger">{termsCondE && termsCondE}</span>
                      </div>
                    </div>
                    <div className="col-3">
                      <input
                        type="submit"
                        className="btn btn-theme pull-right btn-reservation-now float-end"
                        onClick={transferSubmitHandler}
                      />
                    </div>
                  </div>
                </div>
                <aside className="col-md-3 sidebar">
                  <div className="widget shadow widget-helping-center">
                    <h4 className="widget-title">Helping Center</h4>
                    <div className="widget-content">
                      <p>In case of an emergency please call our 24/7 phone number</p>
                      <h5 className="widget-title-sub">+90 542 851 43 33 </h5>
                      <p>
                        <a href="mailto:info@baspinar-rentals.com">info@baspinar-rentals.com</a>
                      </p>
                      <div className="button">
                        <a id="btnfooterpage" href="#" className="btn btn-theme w-100">
                          Support Center
                        </a>
                      </div>
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </section>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Transfer;
