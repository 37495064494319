import React, { Fragment, useState, useEffect } from "react";
import "./LoginSignUp.css";
import Loader from "../layout/Loader/Loader";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, login, register } from "../../actions/userAction";
import { useAlert } from "react-alert";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faLinkedin, faGooglePlus } from "@fortawesome/free-brands-svg-icons";

const LoginSignUp = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  const { user } = useSelector((state) => state.user);
  const { error, loading, isAuthenticated } = useSelector((state) => state.user);

  const [userLogin, setUserLogin] = useState({
    name: "",
    email: "",
    password: "",
  });

  const { name, email, password } = userLogin;

  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  const loginSubmit = (e) => {
    e.preventDefault();
    dispatch(login(loginEmail, loginPassword));
  };

  const registerSubmit = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("name", name);
    myForm.set("email", email);
    myForm.set("password", password);
    dispatch(register(myForm));
  };

  const registerDataChange = (e) => {
    setUserLogin({ ...userLogin, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    $("#signUp").on("click", function () {
      $("#container").addClass("right-panel-active");
    });
    $("#signIn").on("click", function () {
      $("#container").removeClass("right-panel-active");
    });

    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (isAuthenticated) {
      if (user && user.role === "admin") {
        navigate("/admin/dashboard");
        alert.success("Login Successfully");
      } else {
        alert.error("You are not Admin");
      }
    }
  }, [dispatch, error, alert, isAuthenticated, navigate]);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <div className="LoginSignUpContainer">
            <Link className="backtohome" to="/">
              <FontAwesomeIcon icon={faArrowLeft} /> Back to Home
            </Link>
            <div className="login-container" id="container">
              <div className="form-container sign-up-container">
                <form className="login-form" onSubmit={registerSubmit}>
                  <h2>Create Account</h2>
                  <div className="social-container">
                    <a href="#" className="social">
                      <FontAwesomeIcon icon={faFacebook} />
                    </a>
                    <a href="#" className="social">
                      <FontAwesomeIcon icon={faGooglePlus} />
                    </a>
                    <a href="#" className="social">
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                  </div>
                  <span>or use your email for registration</span>
                  <input
                    type="text"
                    placeholder="Name"
                    required
                    name="name"
                    className="login-input"
                    value={name}
                    onChange={registerDataChange}
                  />
                  <input
                    type="email"
                    placeholder="Email"
                    required
                    name="email"
                    className="login-input"
                    value={email}
                    onChange={registerDataChange}
                  />
                  <input
                    type="password"
                    placeholder="Password"
                    name="password"
                    required
                    className="login-input"
                    value={password}
                    onChange={registerDataChange}
                  />

                  <input type="submit" value="Sign Up" className="btn btn-theme" />
                </form>
              </div>
              <div className="form-container sign-in-container">
                <form className="login-form" onSubmit={loginSubmit}>
                  <h2>Sign in</h2>
                  <div className="social-container">
                    <a href="#" className="social">
                      <FontAwesomeIcon icon={faFacebook} />
                    </a>
                    <a href="#" className="social">
                      <FontAwesomeIcon icon={faGooglePlus} />
                    </a>
                    <a href="#" className="social">
                      <FontAwesomeIcon icon={faLinkedin} />
                    </a>
                  </div>
                  <span>or use your account</span>
                  <input
                    type="email"
                    placeholder="Email"
                    required
                    className="login-input"
                    value={loginEmail}
                    onChange={(e) => setLoginEmail(e.target.value)}
                  />
                  <input
                    type="password"
                    placeholder="Password"
                    required
                    className="login-input"
                    value={loginPassword}
                    onChange={(e) => setLoginPassword(e.target.value)}
                  />
                  <a href="#">Forgot your password?</a>
                  <input type="submit" value="Sign In" className="btn btn-theme" />
                </form>
              </div>
              <div className="overlay-container">
                <div className="overlay">
                  <div className="overlay-panel overlay-left">
                    <h1>Welcome Back!</h1>
                    <p>To keep connected with us please login with your personal info</p>
                    <button className="ghost btn btn-theme" id="signIn">
                      Sign In
                    </button>
                  </div>
                  <div className="overlay-panel overlay-right">
                    <h1>Hello, Friend!</h1>
                    <p>Enter your personal details and start journey with us</p>
                    <button className="ghost btn btn-theme" id="signUp">
                      Sign Up
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default LoginSignUp;
