import {
  CREATE_TRANSFER_REQUEST,
  CREATE_TRANSFER_SUCCESS,
  CREATE_TRANSFER_FAIL,
  MY_TRANSFERS_REQUEST,
  MY_TRANSFERS_SUCCESS,
  MY_TRANSFERS_FAIL,
  ALL_TRANSFERS_REQUEST,
  ALL_TRANSFERS_SUCCESS,
  ALL_TRANSFERS_FAIL,
  UPDATE_TRANSFER_REQUEST,
  UPDATE_TRANSFER_SUCCESS,
  UPDATE_TRANSFER_FAIL,
  UPDATE_TRANSFER_RESET,
  DELETE_TRANSFER_REQUEST,
  DELETE_TRANSFER_SUCCESS,
  DELETE_TRANSFER_FAIL,
  DELETE_TRANSFER_RESET,
  TRANSFER_DETAILS_REQUEST,
  TRANSFER_DETAILS_SUCCESS,
  TRANSFER_DETAILS_FAIL,
  CLEAR_ERRORS,
} from "../constants/transferConstants";

import axios from "axios";

// Create Transfer
export const createTransfer = (transfer) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_TRANSFER_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post("/api/v1/transfer/new", transfer, config);

    dispatch({ type: CREATE_TRANSFER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CREATE_TRANSFER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// My Transfers
export const myTransfers = () => async (dispatch) => {
  try {
    dispatch({ type: MY_TRANSFERS_REQUEST });

    const { data } = await axios.get("/api/v1/user/transfers");

    dispatch({ type: MY_TRANSFERS_SUCCESS, payload: data.transfers });
  } catch (error) {
    dispatch({
      type: MY_TRANSFERS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Get All Transfers (admin)
export const getAllTransfers = () => async (dispatch) => {
  try {
    dispatch({ type: ALL_TRANSFERS_REQUEST });

    const { data } = await axios.get("/api/v1/admin/transfers");

    dispatch({ type: ALL_TRANSFERS_SUCCESS, payload: data.transfers });
  } catch (error) {
    dispatch({
      type: ALL_TRANSFERS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update Transfer
export const updateTransfer = (id, transfer) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_TRANSFER_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.put(`/api/v1/admin/transfer/${id}`, transfer, config);

    dispatch({ type: UPDATE_TRANSFER_SUCCESS, payload: data.success });
  } catch (error) {
    dispatch({
      type: UPDATE_TRANSFER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete Transfer
export const deleteTransfer = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_TRANSFER_REQUEST });

    const { data } = await axios.delete(`/api/v1/admin/transfer/${id}`);

    dispatch({ type: DELETE_TRANSFER_SUCCESS, payload: data.success });
  } catch (error) {
    dispatch({
      type: DELETE_TRANSFER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Get Transfer Details
export const getTransferDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: TRANSFER_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/v1/admin/transfer/${id}`);

    dispatch({ type: TRANSFER_DETAILS_SUCCESS, payload: data.transfer });
  } catch (error) {
    dispatch({
      type: TRANSFER_DETAILS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Clearing Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
