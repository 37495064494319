import React, { Fragment, useEffect, useState } from "react";
import "./newProduct.css";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import MetaData from "../layout/MetaData";
import { useNavigate, useParams } from "react-router-dom";
import { createCategory, clearErrors } from "../../actions/categoryAction";
import { CREATE_CATEGORY_RESET } from "../../constants/categoryConstants";
import axios from "axios";
import { createTransferPrice, getTransferPriceDetails, updateTransferPrice } from "../../actions/transferPriceAction";
import { UPDATE_TRANSFER_PRICE_RESET } from "../../constants/transferPriceConstants";

const EditTransferPrice = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  let { id } = useParams();

  id = parseInt(id);

  const { error, transferPrice } = useSelector((state) => state.transferPriceDetails);

  const { loading, error: updateError, isUpdated } = useSelector((state) => state.transferPrice);

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [lowPrice, setLowPrice] = useState(0);
  const [highPrice, setHighPrice] = useState(0);

  const transferPriceId = id;

  useEffect(() => {
    if (transferPrice && transferPrice.id !== transferPriceId) {
      dispatch(getTransferPriceDetails(transferPriceId));
    } else {
      setFrom(transferPrice.from);
      setTo(transferPrice.to);
      setLowPrice(transferPrice.lowPrice);
      setHighPrice(transferPrice.highPrice);
    }

    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (updateError) {
      alert.error(updateError);
      dispatch(clearErrors());
    }

    if (isUpdated) {
      alert.success("Transfer Price Updated Successfully");
      navigate("/admin/transfer-prices");
      dispatch({ type: UPDATE_TRANSFER_PRICE_RESET });
    }
  }, [dispatch, alert, error, navigate, isUpdated, updateError, transferPriceId, transferPrice]);

  const EditTransferPriceHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("from", from);
    myForm.set("to", to);
    myForm.set("lowPrice", lowPrice);
    myForm.set("highPrice", highPrice);

    dispatch(updateTransferPrice(transferPriceId, myForm));
  };

  return (
    <Fragment>
      <MetaData title="Edit Transfer Price" />
      <div className="card">
        <h1 id="productListHeading">Edit Transfer Price</h1>
        <div className="card-body px-0 py-0">
          <form className="AddProductForm" encType="multipart/form-data" onSubmit={EditTransferPriceHandler}>
            <div className="row">
              <div className="col1 col-lg-6">
                <div>
                  <label for="from" className="prodFormLabel">
                    From
                  </label>
                  <input
                    type="text"
                    name="from"
                    placeholder="Enter From..."
                    className="prodFormInput"
                    required
                    value={from}
                    onChange={(e) => setFrom(e.target.value)}
                  />
                </div>
              </div>
              <div className="col1 col-lg-6">
                <div>
                  <label for="to" className="prodFormLabel">
                    To
                  </label>
                  <input
                    type="text"
                    name="to"
                    placeholder="Enter To..."
                    className="prodFormInput"
                    required
                    value={to}
                    onChange={(e) => setTo(e.target.value)}
                  />
                </div>
              </div>
              <div className="col1 col-lg-6">
                <div>
                  <label for="lowPrice" className="prodFormLabel">
                    Low Price
                  </label>
                  <input
                    type="number"
                    name="lowPrice"
                    placeholder="Enter Low Price..."
                    className="prodFormInput"
                    required
                    value={lowPrice}
                    onChange={(e) => setLowPrice(e.target.value)}
                  />
                </div>
              </div>
              <div className="col1 col-lg-6">
                <div>
                  <label for="highPrice" className="prodFormLabel">
                    High Price
                  </label>
                  <input
                    type="number"
                    name="highPrice"
                    placeholder="Enter High Price..."
                    className="prodFormInput"
                    required
                    value={highPrice}
                    onChange={(e) => setHighPrice(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="col1 col-lg-12">
              <div>
                <input type="submit" className="btn btn-theme" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default EditTransferPrice;
