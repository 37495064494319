import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "./component/Admin/Dashboard.js";
import CreateCar from "./component/Admin/CreateCar.js";
import CarsList from "./component/Admin/CarsList.js";
import ProtectedRoute from "./component/Route/ProtectedRoute";
import Sidebar from "./component/Admin/Sidebar.js";
import "./Admin.css";
import CategoryList from "./component/Admin/CategoryList.js";
import CreateCategory from "./component/Admin/CreateCategory.js";
import EditCategory from "./component/Admin/EditCategory.js";
import EditCar from "./component/Admin/EditCar.js";
import BookingsList from "./component/Admin/BookingsList.js";
import TransfersList from "./component/Admin/TransfersList.js";
import CreateTransferPrice from "./component/Admin/CreateTransferPrice.js";
import TransferPrices from "./component/Admin/TransferPrices";
import EditTransferPrice from "./component/Admin/EditTransferPrice.js";
import store from "./store.js";
import { loadUser } from "./actions/userAction";
import BookingDetails from "./component/Admin/BookingDetails.js";
import CustomerEnquiries from "./component/Admin/CustomerEnquiries.js";
import UsersList from "./component/Admin/UsersList";
import TransferDetails from "./component/Admin/TransferDetails.js";

const Admin = () => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);
  return (
    <ProtectedRoute>
      <div className="page-wrapper chiller-theme toggled">
        <Sidebar />
        <main className="page-content">
          <div className="container-fluid bg-200">
            <Routes>
              <Route isAdmin={true} exact path="/dashboard" element={<Dashboard />} />
              <Route isAdmin={true} exact path="/cars" element={<CarsList />} />
              <Route isAdmin={true} exact path="/car/new" element={<CreateCar />} />
              <Route isAdmin={true} exact path="/car/:id" element={<EditCar />} />
              <Route isAdmin={true} exact path="/categories" element={<CategoryList />} />
              <Route isAdmin={true} exact path="/category/new" element={<CreateCategory />} />
              <Route isAdmin={true} exact path="/transfer-prices" element={<TransferPrices />} />
              <Route isAdmin={true} exact path="/customer-enquiries" element={<CustomerEnquiries />} />
              <Route isAdmin={true} exact path="/transfer-price/new" element={<CreateTransferPrice />} />
              <Route isAdmin={true} exact path="/transfer-price/:id" element={<EditTransferPrice />} />
              <Route isAdmin={true} exact path="/category/:id" element={<EditCategory />} />
              <Route isAdmin={true} exact path="/bookings" element={<BookingsList />} />
              <Route isAdmin={true} exact path="/booking/:id" element={<BookingDetails />} />
              <Route isAdmin={true} exact path="/transfers" element={<TransfersList />} />
              <Route isAdmin={true} exact path="/transfer/:id" element={<TransferDetails />} />
              <Route isAdmin={true} exact path="/users" element={<UsersList />} />
            </Routes>
          </div>
        </main>
      </div>
    </ProtectedRoute>
  );
};

export default Admin;
