import React, { Fragment, useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getCarDetails } from "../../actions/carAction";
import { clearErrors, createBooking } from "../../actions/bookingAction";
import { createTransfer } from "../../actions/transferAction";
import Breadcrumb from "../layout/Breadcrumb";
import Loader from "../layout/Loader/Loader";
import $ from "jquery";
import { CREATE_BOOKING_RESET } from "../../constants/bookingConstants";
import "react-datepicker/dist/react-datepicker.css";
import { CREATE_TRANSFER_RESET } from "../../constants/transferConstants";
import MetaData from "../layout/MetaData";
import moment from "moment";

const ConfirmBooking = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();

  const { car, loading } = useSelector((state) => state.carDetails);

  const { error, success } = useSelector((state) => state.createBooking);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (success) {
      navigate("/booking/success", {
        state: {
          booking: true,
        },
      });
      dispatch({ type: CREATE_BOOKING_RESET });
    }
    dispatch(getCarDetails(location.state.booking.carId));
  }, [$, dispatch, error, alert, success]);

  const [booking, setBooking] = useState(location.state.booking);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    const updatedCustomerDetails = {
      ...booking.customerDetails,
      [name]: value,
    };
    const updatedBooking = {
      ...booking,
      customerDetails: updatedCustomerDetails,
    };
    setBooking(updatedBooking);
  };

  const SubmitBooking = () => {
    dispatch(createBooking(booking));
  };

  return (
    <Fragment>
      {booking && loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title="Baspinar - Confirm Booking" />
          <Breadcrumb parent="booking" name="Confirm" />
          <section className="page-section with-sidebar sub-page">
            <div className="container">
              <div className="row">
                <div className="col-md-9 content" id="content">
                  <h3 className="block-title alt">
                    <i className="fas fa-angle-down"></i>Car Information
                  </h3>
                  <div className="car-big-card alt">
                    <div className="row">
                      <div
                        className="col-md-7"
                        style={{
                          backgroundColor: "white",
                          textAlign: "-webkit-center",
                        }}
                      >
                        <img src={car.image && `/images/${car.image}`} className="img-fluid" />
                      </div>
                      <div className="col-md-5">
                        <div className="car-details">
                          <div className="list">
                            <ul>
                              <li className="title">
                                <h2>
                                  {car.name} / <span>{car.category}</span>
                                </h2>
                              </li>
                              <li>{car.engine && car.engine.toPrecision(2)} lt</li>
                              <li>{car.gearbox} </li>
                              <li>{car.fuel} </li>
                              <li>{car.passengers} Seats </li>
                              <li>{car.doors} Doors</li>
                              <li>{car.additionalInfo}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h3 className="block-title alt mt-4">
                    <i className="fas fa-angle-down"></i>Customer Information
                  </h3>
                  <div className="panel-group payments-options">
                    <div className="panel panel-default">
                      <div className="panel-body">
                        <div className="panel panel-default" style={{ borderBottom: "none" }}>
                          <div className="row pb-3">
                            <div className="col-md-6 mt-4">
                              <div className="form-group">
                                <label className="form-label-theme">Name</label>
                                <input
                                  type="text"
                                  className="form-control alt"
                                  name="name"
                                  value={booking.customerDetails.name}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-6 mt-4">
                              <div className="form-group">
                                <label className="form-label-theme">Surname</label>
                                <input
                                  type="text"
                                  className="form-control alt"
                                  name="surname"
                                  value={booking.customerDetails.surname}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-6 mt-4">
                              <div className="form-group">
                                <label className="form-label-theme">Birth Date</label>
                                <input
                                  type="test"
                                  className="form-control alt"
                                  name="dateOfBirth"
                                  value={booking.customerDetails.dateOfBirth}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-6 mt-4">
                              <div className="form-group">
                                <label className="form-label-theme">Email</label>
                                <input
                                  type="text"
                                  className="form-control alt"
                                  name="email"
                                  value={booking.customerDetails.email}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-12 mt-4">
                              <div className="form-group">
                                <label className="form-label-theme">Address</label>
                                <input
                                  type="text"
                                  className="form-control alt"
                                  name="address"
                                  value={booking.customerDetails.address}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-12 mt-4">
                              <div className="form-group">
                                <label className="form-label-theme">Place to Stay in North Cyprus</label>
                                <input
                                  type="text"
                                  className="form-control alt"
                                  name="placeInNorthCyprus"
                                  value={booking.customerDetails.placeInNorthCyprus}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-6 mt-4">
                              <div className="form-group">
                                <label className="form-label-theme">Phone</label>
                                <input
                                  type="text"
                                  className="form-control alt"
                                  name="phone"
                                  value={booking.customerDetails.phone}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-6 mt-4">
                              <div className="form-group">
                                <label className="form-label-theme">Cell Phone</label>
                                <input
                                  type="text"
                                  className="form-control alt"
                                  name="cellPhone"
                                  value={booking.customerDetails.cellPhone}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                            <div className="col-md-12 mt-4">
                              <div className="form-group">
                                <label className="form-label-theme">Message</label>
                                <textarea
                                  type="text"
                                  className="form-control alt"
                                  name="message"
                                  value={booking.customerDetails.message}
                                  onChange={handleInputChange}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <input
                    type="button"
                    className="btn btn-theme float-end mb-3"
                    value="Send Enquiry"
                    onClick={SubmitBooking}
                  />
                </div>
                <aside className="col-md-3 sidebar">
                  <div className="widget shadow widget-helping-center">
                    <h4 className="widget-title">Your Total Booking Price</h4>
                    <div className="widget-content">
                      <h6 style={{ fontWeight: "800", color: "red" }} className="mb-4">
                        Total Price: £{" "}
                        {booking.totalPrice + (location.state.transfer ? location.state.transfer.totalPrice : 0)}
                      </h6>
                      <p style={{ fontWeight: "500" }}>No Debit Card charges!</p>
                      <span style={{ fontSize: "small" }}>
                        * This price may not be your approved price. For guarantted price wait for the latest approved
                        price voucher.
                      </span>
                    </div>
                  </div>
                  <div className="widget shadow widget-helping-center">
                    <h4 className="widget-title d-flex align-items-center">
                      <i className="fas fa-car text-theme fs-3 me-3"></i> {car.name}
                    </h4>
                    <div className="widget-content">
                      <ul className="bookingDetails">
                        <li>
                          <span className="attr">Pick Up:</span> <span>{booking.pickupLocation}</span>
                        </li>
                        <li>
                          <span className="attr">Drop Off:</span> <span>{booking.dropoffLocation}</span>
                        </li>
                        <li>
                          <span className="attr">Pick Up Date:</span>{" "}
                          <span>
                            {booking.pickupDate} {booking.pickupTime}
                          </span>
                        </li>
                        <li>
                          <span className="attr">Drop Off Date:</span>{" "}
                          <span>
                            {booking.dropoffDate} {booking.dropoffTime}
                          </span>
                        </li>
                        <li>
                          <span className="attr">Extras:</span>
                          <ul>
                            <li>
                              <span className="attr">Baby Seat:</span> <span>{booking.babySeat}</span>
                            </li>
                            <li>
                              <span className="attr">Child Seat:</span> <span>{booking.childSeat}</span>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="widget shadow widget-helping-center">
                    <h4 className="widget-title">Helping Center</h4>
                    <div className="widget-content">
                      <p>In case of an emergency please call our 24/7 phone number</p>
                      <h5 className="widget-title-sub">+90 542 851 43 33 </h5>
                      <p>
                        <a href="mailto:info@baspinar-rentals.com">info@baspinar-rentals.com</a>
                      </p>
                      <div className="button">
                        <a id="btnfooterpage" href="#" className="btn btn-theme w-100">
                          Support Center
                        </a>
                      </div>
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </section>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ConfirmBooking;
