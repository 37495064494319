import React, { Fragment, useEffect, useState } from "react";
import "./newProduct.css";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import MetaData from "../layout/MetaData";
import { useNavigate } from "react-router-dom";
import { createCategory, clearErrors } from "../../actions/categoryAction";
import { CREATE_CATEGORY_RESET } from "../../constants/categoryConstants";
import axios from "axios";
import { createTransferPrice } from "../../actions/transferPriceAction";

const CreateTransferPrice = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  const { loading, error, success } = useSelector((state) => state.createTransferPrice);

  const [from, setFrom] = useState("");
  const [to, setTo] = useState("");
  const [lowPrice, setLowPrice] = useState(0);
  const [highPrice, setHighPrice] = useState(0);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (success) {
      alert.success("Transfer Price Created Successfully");

      navigate("/admin/transfer-prices");

      dispatch({ type: CREATE_CATEGORY_RESET });
    }
  }, [dispatch, alert, error, navigate, success]);

  const createTransferPriceHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("from", from);
    myForm.set("to", to);
    myForm.set("lowPrice", lowPrice);
    myForm.set("highPrice", highPrice);

    dispatch(createTransferPrice(myForm));
  };

  return (
    <Fragment>
      <MetaData title="Create Transfer Price" />
      <div className="card">
        <h1 id="productListHeading">Create Transfer Price</h1>
        <div className="card-body px-0 py-0">
          <form className="AddProductForm" encType="multipart/form-data" onSubmit={createTransferPriceHandler}>
            <div className="row">
              <div className="col1 col-lg-6">
                <div>
                  <label for="from" className="prodFormLabel">
                    From
                  </label>
                  <input
                    type="text"
                    name="from"
                    placeholder="Enter From..."
                    className="prodFormInput"
                    required
                    onChange={(e) => setFrom(e.target.value)}
                  />
                </div>
              </div>
              <div className="col1 col-lg-6">
                <div>
                  <label for="to" className="prodFormLabel">
                    To
                  </label>
                  <input
                    type="text"
                    name="to"
                    placeholder="Enter To..."
                    className="prodFormInput"
                    required
                    onChange={(e) => setTo(e.target.value)}
                  />
                </div>
              </div>
              <div className="col1 col-lg-6">
                <div>
                  <label for="lowPrice" className="prodFormLabel">
                    Low Price
                  </label>
                  <input
                    type="number"
                    name="lowPrice"
                    placeholder="Enter Low Price..."
                    className="prodFormInput"
                    required
                    onChange={(e) => setLowPrice(e.target.value)}
                  />
                </div>
              </div>
              <div className="col1 col-lg-6">
                <div>
                  <label for="highPrice" className="prodFormLabel">
                    High Price
                  </label>
                  <input
                    type="number"
                    name="highPrice"
                    placeholder="Enter High Price..."
                    className="prodFormInput"
                    required
                    onChange={(e) => setHighPrice(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <div className="col1 col-lg-12">
              <div>
                <input type="submit" className="btn btn-theme" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default CreateTransferPrice;
