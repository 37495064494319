import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Breadcrumb from "../layout/Breadcrumb";
import MetaData from "../layout/MetaData";

const About = () => {
  return (
    <Fragment>
      <MetaData title="Baspinar - About" />
      <Breadcrumb parent="pages" name="about" />
      <section className="page-section color">
        <div className="container">
          <p className="text-center lead">
            <strong>BASPINAR RENT A CAR </strong>
          </p>

          <hr className="page-divider" />
          <div>
            <p>
              <strong>
                Since 1993 Baspinar Rent A Car has been the leader in car rental services in North Cyprus. The company
                has continued to go from strength to strength, operating in the beginning from our first office in Lapta
                before moving to our modern and spacious office in Catalkoy, Kyrenia.
              </strong>
            </p>

            <p>
              <br />
              We can offer small family cars, luxury 4x4 jeeps and fun sports cars. In addition to our car rental
              services we also provide&nbsp;
              <strong>
                <Link to="/transfer" className="text-theme">
                  transfer services
                </Link>
              </strong>
              &nbsp;to and from Ercan airport and also Larnaca and Paphos airports in South Cyprus.
              <br />
              <br />
              One of the main keys to our success has been our commitment to providing our clients with the highest
              level of customer service at a competitive price. This continues to be our main aim. Our friendly and
              experienced staff are always ready to help you with any query you may have. We pride ourselves on offering
              24 hour a day telephone support for all our customers.
              <br />
              <br />
              At Baspinar Rent a Car we are only too aware that without you - the customer, we wouldn't be where we are
              today.
            </p>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default About;
