import axios from "axios";

import {
  ALL_TRANSFER_PRICE_REQUEST,
  ALL_TRANSFER_PRICE_SUCCESS,
  ALL_TRANSFER_PRICE_FAIL,
  CREATE_TRANSFER_PRICE_REQUEST,
  CREATE_TRANSFER_PRICE_SUCCESS,
  CREATE_TRANSFER_PRICE_FAIL,
  TRANSFER_PRICE_DETAILS_REQUEST,
  TRANSFER_PRICE_DETAILS_SUCCESS,
  TRANSFER_PRICE_DETAILS_FAIL,
  UPDATE_TRANSFER_PRICE_REQUEST,
  UPDATE_TRANSFER_PRICE_SUCCESS,
  UPDATE_TRANSFER_PRICE_FAIL,
  DELETE_TRANSFER_PRICE_REQUEST,
  DELETE_TRANSFER_PRICE_SUCCESS,
  DELETE_TRANSFER_PRICE_FAIL,
  CLEAR_ERRORS,
} from "../constants/transferPriceConstants";

// Get All Categories
export const getAllTransferPrices = () => async (dispatch) => {
  try {
    dispatch({ type: ALL_TRANSFER_PRICE_REQUEST });

    const { data } = await axios.get("/api/v1/transfer-prices");

    dispatch({
      type: ALL_TRANSFER_PRICE_SUCCESS,
      payload: data.transferPrices,
    });
  } catch (error) {
    dispatch({
      type: ALL_TRANSFER_PRICE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Create Category
export const createTransferPrice = (transferPriceData) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_TRANSFER_PRICE_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const { data } = await axios.post(
      `/api/v1/admin/transfer-price/new`,
      transferPriceData,
      config
    );

    dispatch({
      type: CREATE_TRANSFER_PRICE_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_TRANSFER_PRICE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Get Category Details
export const getTransferPriceDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: TRANSFER_PRICE_DETAILS_REQUEST });

    const { data } = await axios.get(`/api/v1/admin/transfer-price/${id}`);

    dispatch({
      type: TRANSFER_PRICE_DETAILS_SUCCESS,
      payload: data.transferPrice,
    });
  } catch (error) {
    dispatch({
      type: TRANSFER_PRICE_DETAILS_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update Category
export const updateTransferPrice =
  (id, transferPriceData) => async (dispatch) => {
    try {
      dispatch({ type: UPDATE_TRANSFER_PRICE_REQUEST });

      const config = {
        headers: { "Content-Type": "application/json" },
      };
      const { data } = await axios.put(
        `/api/v1/admin/transfer-price/${id}`,
        transferPriceData,
        config
      );

      dispatch({
        type: UPDATE_TRANSFER_PRICE_SUCCESS,
        payload: data.success,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_TRANSFER_PRICE_FAIL,
        payload: error.response.data.message,
      });
    }
  };

// Delete Category
export const deleteTransferPrice = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_TRANSFER_PRICE_REQUEST });

    const { data } = await axios.delete(`/api/v1/admin/transfer-price/${id}`);

    dispatch({ type: DELETE_TRANSFER_PRICE_SUCCESS, payload: data.success });
  } catch (error) {
    dispatch({
      type: DELETE_TRANSFER_PRICE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Clearing Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
