import React, { Fragment, useEffect } from "react";
import Breadcrumb from "../layout/Breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import { getCars, clearErrors } from "../../actions/carAction";
import { Link } from "react-router-dom";
import MetaData from "../layout/MetaData";
import Loader from "../layout/Loader/Loader";

const CarPrices = () => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const { cars, loading, error } = useSelector((state) => state.cars);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    dispatch(getCars());
  }, [dispatch, error, alert]);

  return (
    <Fragment>
      <MetaData title="Baspinar - Car Fleet" />
      <Breadcrumb parent="cars" name="Car Prices" />
      {loading ? (
        <Loader />
      ) : (
        <section className="page-section my-5">
          <div className="container">
            <div className="row thumbnails portfolio isotope isotope-items">
              <table width="100%" border="0" cellspacing="3" cellpadding="0" class="hidden-xs">
                <tbody>
                  <tr>
                    <td height="20" colspan="17" align="left" valign="top">
                      <h4 className="mb-5">All Prices are in GBP</h4>
                    </td>
                  </tr>
                  <tr>
                    <td width="63" height="20">
                      &nbsp;
                    </td>
                    <td height="20" colspan="2" align="center" bgcolor="#FC9F1A">
                      <strong class="subheader2">Nov-Dec-Jan-Feb</strong>
                    </td>
                    <td height="20" colspan="2" align="center" bgcolor="#FC9F1A">
                      <strong class="subheader2">March</strong>
                    </td>
                    <td height="20" colspan="2" align="center" bgcolor="#FC9F1A">
                      <strong class="subheader2">April</strong>
                    </td>
                    <td height="20" colspan="2" align="center" bgcolor="#FC9F1A">
                      <strong class="subheader2">May</strong>
                    </td>
                    <td height="20" colspan="2" align="center" bgcolor="#FC9F1A">
                      <strong class="subheader2">Jun</strong>
                    </td>
                    <td height="20" colspan="2" align="center" bgcolor="#FC9F1A" class="headerorangex">
                      <strong>Oct</strong>
                    </td>
                    <td height="20" colspan="2" align="center" bgcolor="#FC9F1A" class="headerorangex">
                      <strong class="subheader2">Jul-Sep</strong>
                    </td>
                    <td height="20" colspan="2" align="center" bgcolor="#FC9F1A" class="headerorangex">
                      <strong class="subheader2">Aug</strong>
                    </td>
                  </tr>
                  <tr>
                    <td
                      width="63"
                      height="30"
                      valign="middle"
                      bgcolor="#FC9F1A"
                      class="subheader2"
                      style={{ paddingLeft: "5px" }}
                    >
                      <strong class="header2">DAYS &nbsp;</strong>
                    </td>
                    <td width="51" height="30" align="center" bgcolor="#99CCFF" class="subheader2">
                      <em>1-9 </em>
                    </td>
                    <td width="73" height="30" align="center" bgcolor="#99CCFF" class="subheader2">
                      <em>10+ </em>
                    </td>
                    <td width="43" height="30" align="center" bgcolor="#99CCFF" class="subheader2">
                      <em>1-9 </em>
                    </td>
                    <td width="49" align="center" bgcolor="#99CCFF" class="subheader2">
                      <em>10+ </em>
                    </td>
                    <td width="41" height="30" align="center" bgcolor="#99CCFF" class="subheader2">
                      <em>1-9 </em>
                    </td>
                    <td width="41" height="30" align="center" bgcolor="#99CCFF" class="subheader2">
                      <em>10+ </em>
                    </td>
                    <td width="38" height="30" align="center" bgcolor="#99CCFF" class="subheader2">
                      <em>1-9 </em>
                    </td>
                    <td width="43" align="center" bgcolor="#99CCFF" class="subheader2">
                      <em>10+ </em>
                    </td>
                    <td width="37" height="30" align="center" bgcolor="#99CCFF" class="subheader2">
                      <em>1-9 </em>
                    </td>
                    <td width="41" height="30" align="center" bgcolor="#99CCFF" class="subheader2">
                      <em>10+ </em>
                    </td>
                    <td height="30" align="center" bgcolor="#99CCFF" class="subheader2">
                      <em>1-9 </em>
                    </td>
                    <td height="30" align="center" bgcolor="#99CCFF" class="subheader2">
                      <em>10+ </em>
                    </td>
                    <td width="49" height="30" align="center" bgcolor="#99CCFF" class="subheader2">
                      <em>1-9 </em>
                    </td>
                    <td width="40" align="center" bgcolor="#99CCFF" class="subheader2">
                      <em>10+ </em>
                    </td>
                    <td width="43" align="center" bgcolor="#99CCFF" class="subheader2">
                      <em>1-9</em>
                    </td>
                    <td width="59" align="center" bgcolor="#99CCFF" class="subheader2">
                      <em>10+ </em>
                    </td>
                  </tr>
                  {cars &&
                    cars.map((car) => (
                      <tr>
                        <td width="63" height="40" bgcolor="#FC9F1A" class="subheader2" style={{ paddingLeft: "5px" }}>
                          {car.name}
                        </td>
                        <td width="51" height="40" align="center" bgcolor="#eaeaea" class="bodytext">
                          {car.prices[0].days1}
                        </td>
                        <td width="73" height="40" align="center" bgcolor="#eaeaea" class="bodytext">
                          {car.prices[0].days2}
                        </td>
                        <td width="43" height="40" align="center" bgcolor="#CCCCCC" class="bodytext">
                          {car.prices[1].days1}
                        </td>
                        <td width="49" height="40" align="center" bgcolor="#CCCCCC" class="bodytext">
                          {car.prices[1].days2}
                        </td>
                        <td width="41" height="40" align="center" bgcolor="#eaeaea" class="bodytext">
                          {car.prices[2].days1}
                        </td>
                        <td width="41" height="40" align="center" bgcolor="#eaeaea" class="bodytext">
                          {car.prices[2].days2}
                        </td>
                        <td width="38" height="40" align="center" bgcolor="#CCCCCC" class="bodytext">
                          {car.prices[3].days1}
                        </td>
                        <td width="43" height="40" align="center" bgcolor="#CCCCCC" class="bodytext">
                          {car.prices[3].days2}
                        </td>
                        <td width="37" height="40" align="center" bgcolor="#eaeaea" class="bodytext">
                          {car.prices[4].days1}
                        </td>
                        <td width="41" height="40" align="center" bgcolor="#eaeaea" class="bodytext">
                          {car.prices[4].days2}
                        </td>
                        <td width="41" height="40" align="center" bgcolor="#CCCCCC" class="bodytext">
                          {car.prices[5].days1}
                        </td>
                        <td width="41" height="40" align="center" bgcolor="#CCCCCC" class="bodytext">
                          {car.prices[5].days2}
                        </td>
                        <td width="49" height="40" align="center" bgcolor="#eaeaea" class="bodytext">
                          {car.prices[6].days1}
                        </td>
                        <td width="40" align="center" bgcolor="#eaeaea" class="bodytext">
                          {car.prices[6].days2}
                        </td>
                        <td width="43" align="center" bgcolor="#CCCCCC" class="bodytext">
                          {car.prices[7].days1}
                        </td>
                        <td width="59" height="40" align="center" bgcolor="#CCCCCC" class="bodytext">
                          {car.prices[7].days2}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      )}
    </Fragment>
  );
};

export default CarPrices;
