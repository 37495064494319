import React, { Fragment, useEffect, useState } from "react";
import "./newProduct.css";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import MetaData from "../layout/MetaData";
import { useNavigate, useParams } from "react-router-dom";
import { createCategory, clearErrors, getCategoryDetails, updateCategory } from "../../actions/categoryAction";
import { CREATE_CATEGORY_RESET, UPDATE_CATEGORY_RESET } from "../../constants/categoryConstants";

const EditCategory = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  let { id } = useParams();

  id = parseInt(id);

  const { error, category } = useSelector((state) => state.categoryDetails);

  const { loading, error: updateError, isUpdated } = useSelector((state) => state.category);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [image2, setImage2] = useState("");
  const [imagePreview, setImagePreview] = useState();

  const categoryId = id;

  useEffect(() => {
    if (category && category.id !== categoryId) {
      dispatch(getCategoryDetails(categoryId));
    } else {
      setName(category.name);
      setDescription(category.description);
      setImage2(category.image);
    }
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (updateError) {
      alert.error(updateError);
      dispatch(clearErrors());
    }

    if (isUpdated) {
      alert.success("Category Updated Successfully");
      navigate("/admin/categories");
      dispatch({ type: UPDATE_CATEGORY_RESET });
    }
  }, [dispatch, alert, error, navigate, isUpdated, categoryId, category, updateError]);

  const updateCategorySubmitHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("name", name);
    myForm.set("description", description);
    if (image !== undefined) {
      myForm.set("oldfile", category.image);
      myForm.set("image", image);
    }

    dispatch(updateCategory(categoryId, myForm));
  };

  const CategoryImageHandler = (e) => {
    setImage(e.target.files[0]);
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setImagePreview(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  return (
    <Fragment>
      <MetaData title="Update Category" />
      <div className="card">
        <h1 id="productListHeading">Update Category</h1>
        <div className="card-body px-0 py-0">
          <form className="AddProductForm" encType="multipart/form-data" onSubmit={updateCategorySubmitHandler}>
            <div className="row">
              <div className="col1 col-lg-12">
                <div>
                  <label for="name" className="prodFormLabel">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    placeholder="Enter Category Name..."
                    className="prodFormInput"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>

              <div className="col1 col-lg-8">
                <div>
                  <label for="additionalInfo" className="prodFormLabel">
                    Description
                  </label>
                  <textarea
                    type="text"
                    name="additionalInfo"
                    placeholder="Enter Description"
                    className="prodFormTextArea"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </div>
              </div>
              <div className="col1 col-lg-4 flex-column align-items-start">
                <label style={{ fontSize: "15px", margin: 0 }}>Images</label>
                <div className="file-input">
                  <label
                    className="forImg prodFormLabel"
                    style={{
                      background: imagePreview ? `url(${imagePreview})` : `url(/images/${image2})`,
                    }}
                    for="images"
                  >
                    Select Image
                  </label>
                  <input
                    type="file"
                    name="images"
                    placeholder="Select Product Images"
                    className="prodFormInputFile file"
                    accept="image/*"
                    onChange={CategoryImageHandler}
                  />
                </div>
              </div>
            </div>
            <div className="col1 col-lg-12">
              <div>
                <input type="submit" className="btn btn-theme" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default EditCategory;
