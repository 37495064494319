export const ALL_CAR_REQUEST = "ALL_CAR_REQUEST";
export const ALL_CAR_SUCCESS = "ALL_CAR_SUCCESS";
export const ALL_CAR_FAIL = "ALL_CAR_FAIL";

export const ADMIN_CAR_REQUEST = "ADMIN_CAR_REQUEST";
export const ADMIN_CAR_SUCCESS = "ADMIN_CAR_SUCCESS";
export const ADMIN_CAR_FAIL = "ADMIN_CAR_FAIL";

export const CREATE_CAR_REQUEST = "CREATE_CAR_REQUEST";
export const CREATE_CAR_SUCCESS = "CREATE_CAR_SUCCESS";
export const CREATE_CAR_RESET = "CREATE_CAR_RESET";
export const CREATE_CAR_FAIL = "CREATE_CAR_FAIL";

export const UPDATE_CAR_REQUEST = "UPDATE_CAR_REQUEST";
export const UPDATE_CAR_SUCCESS = "UPDATE_CAR_SUCCESS";
export const UPDATE_CAR_RESET = "UPDATE_CAR_RESET";
export const UPDATE_CAR_FAIL = "UPDATE_CAR_FAIL";

export const DELETE_CAR_REQUEST = "DELETE_CAR_REQUEST";
export const DELETE_CAR_SUCCESS = "DELETE_CAR_SUCCESS";
export const DELETE_CAR_RESET = "DELETE_CAR_RESET";
export const DELETE_CAR_FAIL = "DELETE_CAR_FAIL";

export const CAR_DETAILS_REQUEST = "CAR_DETAILS_REQUEST";
export const CAR_DETAILS_SUCCESS = "CAR_DETAILS_SUCCESS";
export const CAR_DETAILS_FAIL = "CAR_DETAILS_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
