import { Splide, SplideSlide } from "@splidejs/react-splide";
import React, { Fragment } from "react";
import Breadcrumb from "../layout/Breadcrumb";
const Karpaz = () => {
  return (
    <Fragment>
      <Breadcrumb name="karpaz" parent="travel-guide" />
      <section className="page-section" style={{ paddingTop: "20px" }}>
        <div className="container">
          <div id="slider" className="row travel-guide">
            <div className="col-md-12">
              <article className="post-wrap">
                <Splide>
                  <SplideSlide>
                    <img src="/img/TravelGuide/Karpaz/1.jpg" className="img-fluid" alt="image 1" />
                  </SplideSlide>
                  <SplideSlide>
                    <img src="/img/TravelGuide/Karpaz/2.jpg" className="img-fluid" alt="image 2" />
                  </SplideSlide>
                  <SplideSlide>
                    <img src="/img/TravelGuide/Karpaz/3.jpg" className="img-fluid" alt="image 3" />
                  </SplideSlide>
                  <SplideSlide>
                    <img src="/img/TravelGuide/Karpaz/4.jpg" className="img-fluid" alt="image 4" />
                  </SplideSlide>
                  <SplideSlide>
                    <img src="/img/TravelGuide/Karpaz/5.jpg" className="img-fluid" alt="image 5" />
                  </SplideSlide>
                </Splide>
              </article>
            </div>
          </div>
          <p>
            <span id="lbPageContent"></span>
          </p>
          <div
            style={{
              boxSizing: "border-box",
              color: "#999999",
              fontFamily: "lato",
              fontSize: "16px",
              lineHeight: "22.8571434020996px",
            }}
          >
            <span style={{ boxSizing: "border-box", fontSize: "12pt" }}>
              he Karpass Peninsula (Greek: ?a?pas?a; Turkish: Karpaz), also known as the Karpas Peninsula or Karpasia,
              is a long, finger-like peninsula that is one of the most prominent geographical features of the island of
              Cyprus. Its farthest extent is Cape Apostolos Andreas, and its major population centre is the town of
              Rizokarpaso (Greek: ???????pas?; Turkish: Dipkarpaz). De facto, the peninsula forms the Iskele District of
              Northern Cyprus, while de jure it lay in the Famagusta District of the Republic of Cyprus.
            </span>
          </div>
          <br />
          <div
            style={{
              boxSizing: "border-box",
              color: "#999999",
              fontFamily: "lato",
              fontSize: "16px",
              lineHeight: "22.8571434020996px",
            }}
          >
            <span style={{ boxSizing: "border-box", fontSize: "12pt" }}>
              The peninsula hosts a number of historical sites. These include the Kantara Castle, Apostolos Andreas
              Monastery, ruins of the ancient cities of Karpasia and Aphendrika, Ayias Trias Basilica among numerous
              others.[1]
            </span>
          </div>
          <br />
          <div
            style={{
              boxSizing: "border-box",
              color: "#999999",
              fontFamily: "lato",
              fontSize: "16px",
              lineHeight: "22.8571434020996px",
            }}
          >
            <span style={{ boxSizing: "border-box", fontSize: "12pt" }}>
              There are more than 46 sandy beaches in the peninsula, which are the primary Eastern Mediterranean
              nestling grounds for the loggerhead (Caretta caretta) and green sea turtles (Chelonia mydas).[2] The
              Golden Beach, also known traditionally in Greek-Cypriot as 'Pashi-Amos' (Fat Sand beach), or Nangomi
              Beach, is situated around 15 km from the town of Rizokarpaso and is considered one of the finest and most
              remote beaches of Cyprus. With a total length exceeding 3700 m, it is one of the least tourist-frequented
              beaches in the island.[3][4] The Karpass Peninsula is home to the Karpass donkey, known as a symbol of
              Cyprus; there are campaigns carried out jointly by Turkish and Greek Cypriots to conserve the rare donkeys
              of Karpass.[5]
            </span>
          </div>
          <br />
          <div
            style={{
              boxSizing: "border-box",
              color: "#999999",
              fontFamily: "lato",
              fontSize: "16px",
              lineHeight: "22.8571434020996px",
            }}
          >
            <span style={{ boxSizing: "border-box", fontSize: "12pt" }}>
              Activities[edit] Most of the activities in the Karpass Peninsula are related to agriculture, fishing,
              hunting, and some to micro-tourism. Local farmers take advantage of this natural environment to grow
              different fruits and vegetables mostly as sub-subsistence farming (although for local commerce too). The
              region is mostly known for its "Karpuz" (Turkish for "Watermelon"). Several tourist businesses can be
              found in the town of Rizokarpaso. These are generally restaurants serving traditional Turkish-Cypriot
              Cuisine, including "Mezze".
            </span>
          </div>
          <br />
          <div
            style={{
              boxSizing: "border-box",
              color: "#999999",
              fontFamily: "lato",
              fontSize: "16px",
              lineHeight: "22.8571434020996px",
            }}
          >
            <span style={{ boxSizing: "border-box", fontSize: "12pt" }}>
              Due to its geographical position, the Karpass Peninsula is somewhat protected from human interference.
              This makes it a pristine natural environment, home to many inland and marine species. When hunting season
              starts, the Karpass forests are a popular location to go hunting for "Keklik" (Famous Grousse). Meanwhile,
              the coastal region, with its clear waters, moderate Northern currents, and rocky bottom with cave-like
              structures, is home to two of the most highly valued fish species: Orfoz (Dusky Grouper) and Lahos
              (Broomtail Grouper). The price/kg of each species ranges from 35-80 Turkish Lira, depending on the
              location and the season. However, fishing rates in the Karpass Region and most of North Cyprus
              dramatically decreased last Century because of the use of dynamite. This is why the "Zafer Burunu" (the
              end-nose of the peninsula) is now a protected, natural heritage area, where marine species are slowly
              recovering to healthy population parameters.
            </span>
          </div>
          <p></p>
          <hr className="page-divider small" />
          <input type="hidden" name="hiddeUserControlType" id="hiddeUserControlType" />
          <div id="pnlUserControl"></div>
        </div>
      </section>
    </Fragment>
  );
};

export default Karpaz;
