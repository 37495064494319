import { Splide, SplideSlide } from "@splidejs/react-splide";
import React, { Fragment } from "react";
import Breadcrumb from "../layout/Breadcrumb";

const Nicosia = () => {
  return (
    <Fragment>
      <Breadcrumb name="nicosia" parent="travel-guide" />
      <section className="page-section" style={{ paddingTop: "20px" }}>
        <div className="container">
          <div id="slider" className="row travel-guide">
            <div className="col-md-12">
              <article className="post-wrap">
                <Splide>
                  <SplideSlide>
                    <img src="/img/TravelGuide/Nicosia/1.jpg" className="img-fluid" alt="image 1" />
                  </SplideSlide>
                  <SplideSlide>
                    <img src="/img/TravelGuide/Nicosia/2.jpg" className="img-fluid" alt="image 2" />
                  </SplideSlide>
                  <SplideSlide>
                    <img src="/img/TravelGuide/Nicosia/3.jpg" className="img-fluid" alt="image 3" />
                  </SplideSlide>
                  <SplideSlide>
                    <img src="/img/TravelGuide/Nicosia/4.jpg" className="img-fluid" alt="image 4" />
                  </SplideSlide>
                  <SplideSlide>
                    <img src="/img/TravelGuide/Nicosia/5.jpg" className="img-fluid" alt="image 5" />
                  </SplideSlide>
                </Splide>
              </article>
            </div>
          </div>
          <p>
            <span id="lbPageContent"></span>
          </p>
          <div
            style={{
              boxSizing: "border-box",
              color: "#999999",
              fontFamily: "lato",
              fontSize: "16px",
              lineHeight: "22.8571434020996px",
            }}
          >
            <span style={{ boxSizing: "border-box", fontSize: "12pt" }}>
              Nicosia is the capital and seat of government of the Republic of Cyprus, and as such is the farthest
              southeast of all EU member states' capitals. It has been continuously inhabited for over 4,500 years and
              has been the capital of Cyprus since the 10th century. Nicosia was divided into the southern Greek Cypriot
              and the northern Turkish Cypriot parts in 1963, following the intercommunal violence that broke out in the
              city. Today, the northern part of the city is the capital of Northern Cyprus, a de facto state that is
              considered to be occupied Cypriot territory by the international community.
            </span>
          </div>
          <br />
          <div
            style={{
              boxSizing: "border-box",
              color: "#999999",
              fontFamily: "lato",
              fontSize: "16px",
              lineHeight: "22.8571434020996px",
            }}
          >
            <span style={{ boxSizing: "border-box", fontSize: "12pt" }}>
              Apart from its legislative and administrative functions, Nicosia has established itself as the island's
              financial capital and its main international business centre. In 2012, Nicosia was the 5th richest city in
              the world in relative purchasing power.
            </span>
          </div>

          <p></p>
          <hr className="page-divider small" />
          <input type="hidden" name="hiddeUserControlType" id="hiddeUserControlType" />
          <div id="pnlUserControl"></div>
        </div>
      </section>
    </Fragment>
  );
};

export default Nicosia;
