import * as React from "react";
import "./sidebar.css";
import { Link, useNavigate } from "react-router-dom";
import $ from "jquery";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Fragment } from "react";
import Loader from "../layout/Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { logout } from "../../actions/userAction";
import { useAlert } from "react-alert";
import axios from "axios";

const Sidebar = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, isAuthenticated, loading } = useSelector((state) => state.user);

  const [contacts, setContacts] = React.useState();

  useEffect(() => {
    axios.get("/api/v1/admin/contacts").then((response) => {
      setContacts(response.data.contacts);
    });
  }, []);

  useEffect(() => {
    $(".sidebar-dropdown > a").on("click", function () {
      $(".sidebar-submenu").slideUp(400);
      if ($(this).parent().hasClass("active")) {
        $(".sidebar-dropdown").removeClass("active");
        $(this).parent().removeClass("active");
      } else {
        $(".sidebar-dropdown").removeClass("active");
        $(this).next(".sidebar-submenu").slideDown(400);
        $(this).parent().addClass("active");
      }
    });
    $(".sidebar-sub-dropdown > a").on("click", function () {
      $(".sidebar-sub-submenu").slideUp(400);
      if ($(this).parent().hasClass("active")) {
        $(".sidebar-sub-dropdown").removeClass("active");
        $(this).parent().removeClass("active");
      } else {
        $(".sidebar-sub-dropdown").removeClass("active");
        $(this).next(".sidebar-sub-submenu").slideDown(400);
        $(this).parent().addClass("active");
      }
    });

    $("#close-sidebar").on("click", function () {
      $(".page-wrapper").removeClass("toggled");
    });

    $("#show-sidebar").on("click", function () {
      $(".page-wrapper").addClass("toggled");
    });
  }, [loading, isAuthenticated]);

  function logoutUser() {
    dispatch(logout());
    navigate("/");
    alert.success("Logout Successfully");
  }
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <a id="show-sidebar" className="btn btn-sm btn-dark" href="#">
            <i className="fas fa-bars"></i>
          </a>
          <nav id="sidebar" className="sidebar-wrapper">
            <div className="sidebar-content">
              <div className="sidebar-brand">
                <Link to="/">
                  <FontAwesomeIcon icon={faArrowLeft} className="me-2" /> Back to Home
                </Link>
                <div id="close-sidebar">
                  <i className="fas fa-times"></i>
                </div>
              </div>
              <div className="sidebar-header">
                <div className="user-pic" style={{ color: "#fff" }}>
                  <img
                    src={user.avatar && user.avatar.url ? `/images/${user.avatar.url}` : "/images/Profile.png"}
                    alt="Profile"
                  />
                </div>
                <div className="user-info">
                  <span className="user-name">
                    <strong>{user.name}</strong>
                  </span>
                  <span className="user-role">{user.role === "admin" ? "Administrator" : "Moderator"}</span>
                  <span className="user-status">
                    <i className="fa fa-circle"></i> <span>Online</span>
                  </span>
                </div>
              </div>
              <div className="sidebar-search">
                <div>
                  <div className="input-group">
                    <input type="text" className="form-control search-menu" placeholder="Search..." />
                    <div className="input-group-append">
                      <span
                        className="input-group-text h-100"
                        style={{
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                        }}
                      >
                        <i className="fa fa-search" aria-hidden="true"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="sidebar-menu">
                <ul>
                  <li className="header-menu">
                    <span>General</span>
                  </li>
                  <li>
                    <Link to="/admin/dashboard">
                      <i className="fa fa-tachometer-alt"></i>
                      <span>Dashboard</span>
                    </Link>
                  </li>
                  <li className="sidebar-dropdown">
                    <a href="#">
                      <i className="fas fa-car"></i>
                      <span>Cars</span>
                    </a>
                    <div className="sidebar-submenu">
                      <ul>
                        <li>
                          <Link to="/admin/cars">All Cars</Link>
                        </li>
                        <li>
                          <Link to="/admin/car/new">Create Car</Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li className="sidebar-dropdown">
                    <a href="#">
                      <i className="fas fa-th-large"></i>
                      <span>Categories</span>
                    </a>
                    <div className="sidebar-submenu">
                      <ul>
                        <li>
                          <Link to="/admin/categories">All Categories</Link>
                        </li>
                        <li>
                          <Link to="/admin/category/new">Create Category</Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <Link to="/admin/bookings">
                      <i className="fas fa-calendar-week"></i>
                      <span>Bookings</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/transfers">
                      <i className="fas fa-exchange-alt"></i>
                      <span>Transfers</span>
                    </Link>
                  </li>
                  <li className="sidebar-dropdown">
                    <a href="#">
                      <i className="fas fa-th-large"></i>
                      <span>Transfer Prices</span>
                    </a>
                    <div className="sidebar-submenu">
                      <ul>
                        <li>
                          <Link to="/admin/transfer-prices">All Transfer Prices</Link>
                        </li>
                        <li>
                          <Link to="/admin/transfer-price/new">Create Transfer Price</Link>
                        </li>
                      </ul>
                    </div>
                  </li>
                  <li>
                    <Link to="/admin/users">
                      <i className="fa fa-users"></i>
                      <span>Users</span>
                    </Link>
                  </li>
                  <li>
                    <Link to="/admin/customer-enquiries">
                      <i className="fas fa-comments"></i>
                      <span>Customer Enquiries</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="sidebar-footer">
              <Link to="/admin/customer-enquiries">
                <i className="fa fa-envelope"></i>
                <span className="badge rounded-pill bg-success notification">{contacts && contacts.length}</span>
              </Link>
              <Link to="/" onClick={logoutUser}>
                <i className="fa fa-power-off"></i>
              </Link>
            </div>
          </nav>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Sidebar;
