export const ALL_TRANSFER_PRICE_REQUEST = "ALL_TRANSFER_PRICE_REQUEST";
export const ALL_TRANSFER_PRICE_SUCCESS = "ALL_TRANSFER_PRICE_SUCCESS";
export const ALL_TRANSFER_PRICE_FAIL = "ALL_TRANSFER_PRICE_FAIL";

export const CREATE_TRANSFER_PRICE_REQUEST = "CREATE_TRANSFER_PRICE_REQUEST";
export const CREATE_TRANSFER_PRICE_SUCCESS = "CREATE_TRANSFER_PRICE_SUCCESS";
export const CREATE_TRANSFER_PRICE_RESET = "CREATE_TRANSFER_PRICE_RESET";
export const CREATE_TRANSFER_PRICE_FAIL = "CREATE_TRANSFER_PRICE_FAIL";

export const TRANSFER_PRICE_DETAILS_REQUEST = "TRANSFER_PRICE_DETAILS_REQUEST";
export const TRANSFER_PRICE_DETAILS_SUCCESS = "TRANSFER_PRICE_DETAILS_SUCCESS";
export const TRANSFER_PRICE_DETAILS_FAIL = "TRANSFER_PRICE_DETAILS_FAIL";

export const UPDATE_TRANSFER_PRICE_REQUEST = "UPDATE_TRANSFER_PRICE_REQUEST";
export const UPDATE_TRANSFER_PRICE_SUCCESS = "UPDATE_TRANSFER_PRICE_SUCCESS";
export const UPDATE_TRANSFER_PRICE_RESET = "UPDATE_TRANSFER_PRICE_RESET";
export const UPDATE_TRANSFER_PRICE_FAIL = "UPDATE_TRANSFER_PRICE_FAIL";

export const DELETE_TRANSFER_PRICE_REQUEST = "DELETE_TRANSFER_PRICE_REQUEST";
export const DELETE_TRANSFER_PRICE_SUCCESS = "DELETE_TRANSFER_PRICE_SUCCESS";
export const DELETE_TRANSFER_PRICE_RESET = "DELETE_TRANSFER_PRICE_RESET";
export const DELETE_TRANSFER_PRICE_FAIL = "DELETE_TRANSFER_PRICE_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
