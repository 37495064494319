import { Splide, SplideSlide } from "@splidejs/react-splide";
import React, { Fragment } from "react";
import Breadcrumb from "../layout/Breadcrumb";

const Famagusta = () => {
  return (
    <Fragment>
      <Breadcrumb name="famagusta" parent="travel-guide" />
      <section className="page-section" style={{ paddingTop: "20px" }}>
        <div className="container">
          <div id="slider" className="row travel-guide">
            <div className="col-md-12">
              <article className="post-wrap">
                <Splide>
                  <SplideSlide>
                    <img src="/img/TravelGuide/Famagusta/1.jpg" className="img-fluid" alt="image 1" />
                  </SplideSlide>
                  <SplideSlide>
                    <img src="/img/TravelGuide/Famagusta/2.jpg" className="img-fluid" alt="image 2" />
                  </SplideSlide>
                  <SplideSlide>
                    <img src="/img/TravelGuide/Famagusta/3.jpg" className="img-fluid" alt="image 3" />
                  </SplideSlide>
                  <SplideSlide>
                    <img src="/img/TravelGuide/Famagusta/4.jpg" className="img-fluid" alt="image 4" />
                  </SplideSlide>
                  <SplideSlide>
                    <img src="/img/TravelGuide/Famagusta/5.jpg" className="img-fluid" alt="image 5" />
                  </SplideSlide>
                </Splide>
              </article>
            </div>
          </div>
          <p>
            <span id="lbPageContent"></span>
          </p>
          <div
            style={{
              boxSizing: "border-box",
              color: "#999999",
              fontFamily: "lato",
              fontSize: "16px",
              lineHeight: "22.8571434020996px",
            }}
          >
            <span style={{ boxSizing: "border-box", fontSize: "12pt" }}>
              Famagusta is a city on the east coast of Cyprus. It is located east of Nicosia, and possesses the deepest
              harbour of the island. During the medieval period (especially under the maritime republics of Genoa and
              Venice), Famagusta was the island's most important port city, and a gateway to trade with the ports of the
              Levant, from where the Silk Road merchants carried their goods to Western Europe. The old walled city and
              parts of the modern town presently fall within the de facto Turkish Republic of Northern Cyprus in
              Gazimagusa District of which it is the capital.
            </span>
          </div>
          <br />
          <div
            style={{
              boxSizing: "border-box",
              color: "#999999",
              fontFamily: "lato",
              fontSize: "16px",
              lineHeight: "22.8571434020996px",
            }}
          >
            <span style={{ boxSizing: "border-box", fontSize: "12pt" }}>
              History The city was founded in around 274 BC, after the serious damage to Salamis by an earthquake, by
              Ptolemy II Philadelphus and named "Arsinoe" after his sister. Arsinoe was described as a "fishing town" by
              Strabo in his Geographica in the first century BC. It remained a small fishing village for a long time.[3]
              Later, as a result of the gradual evacuation of Salamis due to the Arab invasion led by Muawiyah I, it
              developed into a small port.
            </span>
          </div>
          <br />
          <div
            style={{
              boxSizing: "border-box",
              color: "#999999",
              fontFamily: "lato",
              fontSize: "16px",
              lineHeight: "22.8571434020996px",
            }}
          >
            <span style={{ boxSizing: "border-box", fontSize: "12pt" }}>
              The turning point for Famagusta was 1192 with the onset of Lusignan rule. It was during this period that
              Famagusta developed as a fully-fledged town. It increased in importance to the Eastern Mediterranean due
              to its natural harbour and the walls that protected its inner town. Its population began to increase. This
              development accelerated in the 13th century as the town became a centre of commerce for both the East and
              West. An influx of Christian refugees fleeing the downfall of Acre (1291) in Palestine transformed it from
              a tiny village into one of the richest cities in Christendom.
            </span>
          </div>
          <br />
          <div
            style={{
              boxSizing: "border-box",
              color: "#999999",
              fontFamily: "lato",
              fontSize: "16px",
              lineHeight: "22.8571434020996px",
            }}
          >
            <span style={{ boxSizing: "border-box", fontSize: "12pt" }}>
              In 1372 the port was seized by Genoa and in 1489 by Venice. This commercial activity turned Famagusta into
              a place where merchants and ship owners led lives of luxury. The belief that people's wealth could be
              measured by the churches they built inspired these merchants to have churches built in varying styles.
              These churches, which still exist, were the reason Famagusta came to be known as "the district of
              churches". The development of the town focused on the social lives of the wealthy people and was centred
              upon the Lusignan palace, the Cathedral, the Square and the harbour.
            </span>
          </div>
          <p></p>
          <hr className="page-divider small" />
          <input type="hidden" name="hiddeUserControlType" id="hiddeUserControlType" />
          <div id="pnlUserControl"></div>
        </div>
      </section>
    </Fragment>
  );
};

export default Famagusta;
