import React, { Fragment } from "react";
import "./Header.css";
import { NavLink, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useAlert } from "react-alert";
import { logout } from "../../../actions/userAction";

const Header = () => {
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const alert = useAlert();

  function logoutUser() {
    dispatch(logout());
    alert.success("Logout Successfully");
  }
  return (
    <Fragment>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-xl">
          <Link className="navbar-brand me-0 col-lg-2" to="/">
            <img src="/img/logo-rentit.webp" alt="Rent It" />
          </Link>
          <button
            className="navbar-toggler ms-auto"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse col-lg-10" id="navbarSupportedContent">
            <ul className="navbar-nav m-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="/">
                  Home
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="/cars">
                  Car Fleet
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="/car-prices">
                  Car Prices
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="/transfer">
                  Transfer
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="active" to="/booking">
                  Online Booking
                </NavLink>
              </li>
              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  activeClassName="active"
                  id="navbarDropdown"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Travel Guide
                </a>
                <ul className="dropdown-menu">
                  <li>
                    <Link className="dropdown-item" to="/travel-guide/kyrenia">
                      Kyrenia
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/travel-guide/nicosia">
                      Nicosia
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/travel-guide/famagusta">
                      Famagusta
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/travel-guide/karpaz">
                      Karpaz
                    </Link>
                  </li>
                  <li>
                    <Link className="dropdown-item" to="/travel-guide/guzelyurt">
                      Guzelyurt
                    </Link>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="actve" to="/about">
                  About
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" activeClassName="actve" to="/contact">
                  Contact
                </NavLink>
              </li>
              <li className="nav-item" style={{ display: "flex" }}>
                <ul className="social-icons">
                  <li>
                    <a
                      href="https://www.facebook.com/BaspinarRentACar/"
                      style={{ color: "blue" }}
                      aria-label="Facebook"
                    >
                      <i className="fab fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" style={{ color: "lightblue" }} aria-label="Twitter">
                      <i className="fab fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" style={{ color: "red" }} aria-label="Instagram">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#" style={{ color: "red" }} aria-label="Pinterest">
                      <i className="fab fa-pinterest"></i>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="col-lg-2 d-flex justify-content-end"></div>
        </div>
      </nav>
    </Fragment>
  );
};

export default Header;
