import React, { Fragment } from "react";
import Breadcrumb from "../layout/Breadcrumb";
import MetaData from "../layout/MetaData";

const FAQs = () => {
  return (
    <Fragment>
      <MetaData title="Baspinar - FAQs" />
      <Breadcrumb parent="pages" name="FAQs" />
      <section className="page-section pt-4">
        <div
          className="container"
          style={{
            fontFamily: "tahoma, arial, helvetica, sans-serif",
            fontSize: "16px",
          }}
        >
          <p>
            <b>
              <span>FREQUENTLY ASKED QUESTIONS – FAQ</span>
            </b>
          </p>
          <p>
            <b>
              <span>How do I make a reservation?</span>
            </b>
          </p>
          <p>
            <span>
              You can book online, by email at{" "}
              <a className="text-theme" href="mailto:info@baspinar-rentals.com">
                info@baspinar-rentals.com
              </a>
              &nbsp;or over the telephone on 0207 993 2806 or 0542 888 4 888 or come in to our office
            </span>
          </p>
          <p>
            <b>
              <span>Can I collect the car from the airport?</span>
            </b>
          </p>
          <p>
            <span>
              Yes we can deliver the cars to Ercan airport for you to collect when you arrive providing it is 5 days in
              low season and 7 days in high season any less than this there will be a delivery charge
            </span>
          </p>
          <p>
            <b>
              <span>Can you deliver car hire to Larnaca airport?</span>
            </b>
          </p>
          <p>
            <span>
              We are unable to deliver cars to Larnaca airport, however we can arrange a transfer to our office and the
              car can be collected from there.
            </span>
          </p>
          <p>
            <b>
              <span>How will I find the car when I arrive at Ercan ?</span>
            </b>
          </p>
          <p>
            <span>Our representative will meet you at arrivals he will be holding a sign with your name</span>
          </p>
          <p>
            <strong>
              <span>Can I pay by Credit Card when I collect my car hire ?</span>
            </strong>
          </p>
          <p>
            <span>
              Yes we accept all card payments there is a charge of 2% on all card payment and 5% on Amex , you can also
              pay in advance directly in to our UK Barclays Bank Account.
            </span>
          </p>
          <p>
            <strong>
              <span>Is there a 24 hour emergency contact number ?</span>
            </strong>
          </p>
          <p>
            <span>If you need to contact us for an emergency the number is 0090 542 8584464</span>
          </p>
          <p>
            <b>
              <span>Can I add additional drivers and is there an extra charge?</span>
            </b>
          </p>
          <p>
            <span>You can add up to a maximum of three drivers, there is a charge of</span>
            <span>£</span>
            <span>1 for each additional driver.</span>
            <span></span>
          </p>
          <p>
            <b>
              <span>Can I pay the driver for my Transfer ?</span>
            </b>
          </p>
          <p>
            <span>
              Yes the driver can be paid directly in cash, or before your arrival over the telephone or directly in to
              our UK account. You can also pay transfers, on collection of your car hire.
            </span>
          </p>
          <hr className="page-divider small" />
        </div>
      </section>
    </Fragment>
  );
};

export default FAQs;
