import React, { Fragment, useEffect, useState } from "react";
import "./productList.css";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import MetaData from "../layout/MetaData";
import { getAllUsers, clearErrors, deleteUser, updateUser } from "../../actions/userAction";
import { DELETE_USER_RESET, UPDATE_USER_RESET } from "../../constants/userConstants";
import Loader from "../layout/Loader/Loader";
import Modal from "react-bootstrap/Modal";

const UsersList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();

  const { error, users, loading } = useSelector((state) => state.allUsers);

  const { error: deleteError, isDeleted, message, isUpdated } = useSelector((state) => state.profile);

  const deleteUserHandler = (id) => {
    dispatch(deleteUser(id));
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (deleteError) {
      alert.error(deleteError);
      dispatch(clearErrors());
    }

    if (isDeleted) {
      alert.success(message);
      navigate("/admin/users");
      dispatch({ type: DELETE_USER_RESET });
    }
    if (isUpdated) {
      alert.success("Status Updated Successfully");
      dispatch({ type: UPDATE_USER_RESET });
    }
    dispatch(getAllUsers());
  }, [dispatch, alert, error, deleteError, navigate, isDeleted, message, isUpdated]);

  const [show, setShow] = useState(false);
  const [modalId, setModalId] = useState("");
  const [updateRole, setUpdateRole] = useState("");

  const handleClose = () => {
    setModalId("");
    setShow(false);
  };
  const handleShow = (id) => {
    setModalId(id);
    setShow(true);
  };
  const handleUpdateStatus = () => {
    let role = { role: updateRole };
    dispatch(updateUser(modalId, role));
    handleClose();
  };
  return (
    <Fragment>
      <MetaData title={`ALL Users - Admin`} />
      <Modal backdrop="static" show={show} onHide={handleClose} keyboard={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>Update Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label className="labels">Update Status</label>
            <select className="form-control" required onChange={(e) => setUpdateRole(e.target.value)}>
              <option selected disabled hidden>
                Select
              </option>
              <option value="admin">admin</option>
              <option value="user">user</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <input type="button" className="btn btn-danger" onClick={handleClose} value="Close" />
          <input type="button" className="btn btn-theme" value="Update" onClick={handleUpdateStatus} />
        </Modal.Footer>
      </Modal>
      <div className="card">
        <h1 id="productListHeading">All Users</h1>
        {loading ? (
          <Loader />
        ) : (
          <div className="card-body px-0 py-0">
            <div className="table-responsive scrollbar">
              <table className="table table-sm fs--1 mb-0 overflow-hidden">
                <thead className="bg-200 text-900">
                  <tr>
                    <th className="sort pe-1 align-middle white-space-nowrap" data-sort="avatar">
                      Avatar
                    </th>
                    <th className="sort pe-1 align-middle white-space-nowrap" data-sort="name">
                      Name
                    </th>
                    <th className="sort pe-1 align-middle white-space-nowrap " data-sort="email">
                      Email
                    </th>

                    <th className="sort pe-1 align-middle white-space-nowrap " data-sort="role">
                      Role
                    </th>
                    <th className="sort pe-1 align-middle white-space-nowrap text-end" data-sort="createdAt">
                      Created At
                    </th>
                    <th className="no-sort pe-1 align-middle data-table-row-action"></th>
                  </tr>
                </thead>
                <tbody className="list" id="table-purchase-body">
                  {users &&
                    users.map((item) => (
                      <tr className="btn-reveal-trigger">
                        <td className="align-middle white-space-nowrap image">
                          <img className="img-fluid" src={`/images/${item.avatar}`} alt="img" />
                        </td>
                        <td className="align-middle white-space-nowrap name">{item.name}</td>
                        <td className="align-middle white-space-nowrap email">{item.email}</td>
                        <td className="align-middle role">{item.role}</td>
                        <td className="align-middle text-end createdAt">{String(item.createdAt).substr(0, 10)}</td>
                        <td className="align-middle white-space-nowrap text-end">
                          <div className="dropstart font-sans-serif position-static d-inline-block">
                            <button
                              className="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal float-end"
                              type="button"
                              id="dropdown0"
                              data-bs-toggle="dropdown"
                              data-boundary="window"
                              aria-haspopup="true"
                              aria-expanded="false"
                              data-bs-reference="parent"
                            >
                              <svg
                                className="svg-inline--fa fa-ellipsis-h fa-w-16 fs--1"
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fas"
                                data-icon="ellipsis-h"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                data-fa-i2svg=""
                              >
                                <path
                                  fill="currentColor"
                                  d="M328 256c0 39.8-32.2 72-72 72s-72-32.2-72-72 32.2-72 72-72 72 32.2 72 72zm104-72c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm-352 0c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72z"
                                ></path>
                              </svg>
                            </button>
                            <div className="dropdown-menu dropdown-menu-end border py-2" aria-labelledby="dropdown0">
                              <Link className="dropdown-item" onClick={() => handleShow(item.id)}>
                                Update Role
                              </Link>
                              <div className="dropdown-divider"></div>
                              <Link onClick={() => deleteUserHandler(item.id)} className="dropdown-item text-danger">
                                Delete
                              </Link>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default UsersList;
