import axios from "axios";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import Breadcrumb from "../layout/Breadcrumb";
import MetaData from "../layout/MetaData";
import ReCAPTCHA from "react-google-recaptcha";

const Contact = () => {
  const alert = useAlert();

  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [subject, setSubject] = useState();
  const [message, setMessage] = useState();

  // Input Errors
  const [inputError, setInputError] = useState(false);
  const [nameE, setNameE] = useState();
  const [emailE, setEmailE] = useState();
  const [subjectE, setSubjectE] = useState();
  const [messageE, setMessageE] = useState();
  const [tokenE, setTokenE] = useState();
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const captchaRef = useRef(null);

  const contact = {
    name: name,
    email: email,
    subject: subject,
    message: message,
  };

  const SendContact = async () => {
    const token = captchaRef.current.getValue();
    captchaRef.current.reset();
    let isError = false;

    if (!name) {
      setNameE("Enter Your Name");
      setInputError(true);
      isError = true;
    }
    if (!email || !emailRegex.test(email)) {
      setEmailE("Enter Your Email");
      setInputError(true);
      isError = true;
    }
    if (!subject) {
      setSubjectE("Enter Your Subject");
      setInputError(true);
      isError = true;
    }
    if (!message) {
      setMessageE("Enter Your Message");
      setInputError(true);
      isError = true;
    }
    if (!token) {
      setTokenE("Fill Recaptcha");
      setInputError(true);
      isError = true;
    }
    if (!isError) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        await axios.post("/api/v1/contact", contact, token, config);

        alert.success("Message Successfully Sent");
      } catch (error) {
        alert.error(error.response.data.message);
      }
    }
  };
  useEffect(() => {
    if (inputError) {
      name ? setNameE() : setNameE("Enter Your Name");

      subject ? setSubjectE() : setSubjectE("Enter Your Subject");

      !email || !emailRegex.test(email) ? setEmailE("Email is not Valid") : setEmailE();

      message ? setMessageE() : setMessageE("Enter Your Message");
    }
  }, [name, subject, email, message]);

  return (
    <Fragment>
      <MetaData title="Baspinar - Contact" />
      <Breadcrumb parent="pages" name="contact" />
      <section className="page-section color">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="contact-info">
                <h2 className="block-title">
                  <span>Contact Us</span>
                </h2>

                <div className="media-list">
                  <div className="media">
                    <i className="pull-left fa fa-home"></i>
                    <div className="media-body">
                      <strong>Address:</strong>
                      <br />
                      Besparmak Caddesi. No 1 / 4, Çatalkoy, Çatalkoy Lemar
                    </div>
                  </div>
                  <div className="media">
                    <i className="pull-left fa fa-envelope"></i>
                    <div className="media-body">
                      <strong>Mail:</strong>
                      <br />
                      info@baspinar-rentals.com
                    </div>
                  </div>
                  <div className="media">
                    <i className="pull-left fa fa-phone"></i>
                    <div className="media-body">
                      <strong>Mobile:</strong>
                      <br />
                      +90 542 851 43 33
                    </div>
                  </div>
                  <div className="media">
                    <div className="media-body">
                      In case of an emergency please call our 24/7 phone number +90 542 851 43 33
                      <br />
                      If you would like to receive a quotation for car hire, please complete our online reservation
                      form. For all other enquires, please complete the form below.
                    </div>
                  </div>
                  <div className="media">
                    <div className="media-body">
                      <strong>Customer Service:</strong>
                      <br />
                      <a className="text-theme" href="info@baspinar-rentals.com">
                        info@baspinar-rentals.com
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-8 text-left">
              <h2 className="block-title">
                <span>Contact Form</span>
              </h2>

              <div className="contact-form" id="contact-form">
                <div className="form-group my-3">
                  <label className="sr-only" for="name">
                    Name
                  </label>
                  <input
                    name="name"
                    type="text"
                    placeholder="Name"
                    size="30"
                    className="form-control"
                    onChange={(e) => setName(e.target.value)}
                  />
                  <span className="text-danger">{nameE && nameE}</span>
                </div>

                <div className="form-group my-3">
                  <label className="sr-only" for="email">
                    Email
                  </label>
                  <input
                    name="email"
                    type="text"
                    placeholder="Email"
                    size="30"
                    className="form-control"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <span className="text-danger">{emailE && emailE}</span>
                </div>

                <div className="form-group my-3">
                  <label className="sr-only" for="subject">
                    Subject
                  </label>
                  <input
                    name="subject"
                    type="text"
                    placeholder="Subject"
                    size="30"
                    className="form-control"
                    onChange={(e) => setSubject(e.target.value)}
                  />
                  <span className="text-danger">{subjectE && subjectE}</span>
                </div>
                <div className="form-group my-3">
                  <label className="sr-only" for="input-message">
                    Message
                  </label>
                  <textarea
                    name="inputmessage"
                    placeholder="Message"
                    rows="4"
                    cols="50"
                    className="form-control"
                    onChange={(e) => setMessage(e.target.value)}
                  ></textarea>
                  <span className="text-danger">{messageE && messageE}</span>
                </div>
                <ReCAPTCHA
                  sitekey="6LeKxzMkAAAAANgCKy2BAxnM3zi6ECNwJt1sg2Bu"
                  ref={captchaRef}
                  onChange={(e) => setTokenE(null)}
                />
                <span className="text-danger">{tokenE && tokenE}</span>
                <div className="form-group my-3">
                  <input
                    type="submit"
                    name="btnSend"
                    onClick={SendContact}
                    className="form-button form-button-submit btn btn-theme btn-theme-dark"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Contact;
