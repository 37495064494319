import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { getAllUsers } from "../../actions/userAction.js";
import MetaData from "../layout/MetaData";
import { Chart, registerables } from "chart.js";
import ReactEcharts from "echarts-for-react";
import { getCars } from "../../actions/carAction.js";
import { getAllBookings } from "../../actions/bookingAction.js";
import { getAllTransfers } from "../../actions/transferAction.js";
import Loader from "../layout/Loader/Loader";

Chart.register(...registerables);

const Dashboard = () => {
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);

  const { users } = useSelector((state) => state.allUsers);

  const { carsCount } = useSelector((state) => state.cars);

  const { bookings, loading } = useSelector((state) => state.bookings);

  const { transfers } = useSelector((state) => state.transfers);

  let transfersPending = 0;
  let bookingsPending = 0;
  let netIncome = 0;

  transfers &&
    transfers.forEach((item) => {
      if (item.status === "Pending") {
        transfersPending++;
      }
      netIncome += item.totalPrice;
    });

  var monthlyChartXaxis = [];
  var monthlyChartYaxis = [0];
  var monthlyChartXaxisCounter = 0;
  var monthlyChartYaxisCounter = 0;

  bookings &&
    bookings.forEach((item) => {
      monthlyChartXaxis[monthlyChartXaxisCounter] = String(new Date(item.createdAt).toUTCString()).substr(5, 6);
      monthlyChartYaxis[monthlyChartYaxisCounter] = item.totalPrice;
      monthlyChartXaxisCounter++;
      monthlyChartYaxisCounter++;
      if (item.status === "Pending") {
        bookingsPending++;
      }
      netIncome += item.totalPrice;
    });

  useEffect(() => {
    dispatch(getAllUsers());
    dispatch(getCars());
    dispatch(getAllBookings());
    dispatch(getAllTransfers());
  }, [dispatch]);

  const monthlyChart = {
    grid: {
      left: 50,
      right: 50,
      top: 30,
      bottom: 30,
    },
    tooltip: {
      trigger: "axis",
      formatter: "{c}$",
      textStyle: {
        fontWeight: "bold",
      },
    },
    xAxis: {
      type: "category",
      data: monthlyChartXaxis,
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: "{value}$",
      },
    },
    series: [
      {
        data: monthlyChartYaxis,
        symbolSize: 10,
        type: "line",
        lineStyle: { color: "#e47911", width: 4 },
        itemStyle: {
          borderWidth: 3,
          borderColor: "#e47911",
          color: "#e47911",
        },
      },
    ],
  };
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title="Dashboard - Admin Panel" />
          <div className="content">
            <div className="row g-3 mb-3">
              <div className="col-xxl-6 col-xl-12">
                <div className="row g-3">
                  <div className="col-12">
                    <div className="card bg-transparent-50 overflow-hidden">
                      <div className="card-header position-relative">
                        <div className="bg-holder d-none d-md-block bg-card z-index-1"></div>

                        <div className="position-relative z-index-2">
                          <div>
                            <h3 className="text-theme mb-1">Good Afternoon, {user.name}!</h3>
                            <p>Here’s what happening with your store today </p>
                          </div>
                        </div>
                      </div>
                      <div className="card-body p-0">
                        <ul className="mb-0 list-unstyled">
                          <li className="alert mb-0 rounded-0 py-4 border-top border-x-0">
                            <div className="row flex-between-center">
                              <div className="col">
                                <div className="d-flex">
                                  <svg
                                    className="svg-inline--fa fa-circle fa-w-16 mt-1 fs--2 text-theme"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="circle"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    data-fa-i2svg=""
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                                    ></path>
                                  </svg>
                                  <p className="fs--1 ps-2 mb-0">
                                    <strong>{bookingsPending} Bookings</strong> have confirmations pending
                                  </p>
                                </div>
                              </div>
                              <div className="col-auto d-flex align-items-center">
                                <Link className="alert-link fs--1 fw-medium text-theme" to="/admin/bookings">
                                  View Bookings
                                  <svg
                                    className="svg-inline--fa fa-chevron-right fa-w-10 ms-1 fs--2 text-theme"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="chevron-right"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 320 512"
                                    data-fa-i2svg=""
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.371-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                                    ></path>
                                  </svg>
                                </Link>
                              </div>
                            </div>
                          </li>
                          <li className="alert mb-0 rounded-0 py-4 px-card greetings-item border-top  border-0">
                            <div className="row flex-between-center">
                              <div className="col">
                                <div className="d-flex align-items-center">
                                  <svg
                                    className="svg-inline--fa fa-circle fa-w-16 fs--2 text-theme"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="circle"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 512 512"
                                    data-fa-i2svg=""
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"
                                    ></path>
                                  </svg>
                                  <p className="fs--1 ps-2 mb-0">
                                    <strong>{transfersPending} Transfers </strong>
                                    need to be processed
                                  </p>
                                </div>
                              </div>
                              <div className="col-auto d-flex align-items-center">
                                <Link className="alert-link fs--1 fw-medium text-theme" to="/admin/transfers">
                                  View Transfers
                                  <svg
                                    className="svg-inline--fa fa-chevron-right fa-w-10 ms-1 fs--2 text-theme"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="chevron-right"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 320 512"
                                    data-fa-i2svg=""
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.371-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                                    ></path>
                                  </svg>
                                </Link>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="row g-3">
                      <div className="col-md-6">
                        <div className="card">
                          <div className="card-header">
                            <h6 className="mb-0 mt-2 d-flex align-items-center">Total Cars</h6>
                          </div>
                          <div className="card-body d-flex flex-column justify-content-end">
                            <div className="row">
                              <div className="col-6 d-flex align-items-end">
                                <p className="font-sans-serif lh-1 mb-1 fs-2">{carsCount}</p>
                              </div>
                              <div className="col-6 d-flex justify-content-center">
                                <i className="fas fa-car"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card">
                          <div className="card-header">
                            <h6 className="mb-0 mt-2 d-flex align-items-center">Total Bookings</h6>
                          </div>
                          <div className="card-body d-flex flex-column justify-content-end">
                            <div className="row">
                              <div className="col-6 d-flex align-items-end">
                                <p className="font-sans-serif lh-1 mb-1 fs-2">{bookings && bookings.length}</p>
                              </div>
                              <div className="col-6 d-flex justify-content-center">
                                <i className="fas fa-calendar-week"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card">
                          <div className="card-header">
                            <h6 className="mb-0 mt-2 d-flex align-items-center">Total Transfers</h6>
                          </div>
                          <div className="card-body d-flex flex-column justify-content-end">
                            <div className="row">
                              <div className="col-6 d-flex align-items-end">
                                <p className="font-sans-serif lh-1 mb-1 fs-2">{transfers && transfers.length}</p>
                              </div>
                              <div className="col-6 d-flex justify-content-center">
                                <i className="fas fa-exchange-alt"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="card">
                          <div className="card-header">
                            <h6 className="mb-0 mt-2 d-flex align-items-center">Total Users</h6>
                          </div>
                          <div className="card-body d-flex flex-column justify-content-end">
                            <div className="row">
                              <div className="col-6 d-flex align-items-end">
                                <p className="font-sans-serif lh-1 mb-1 fs-2">{users && users.length}</p>
                              </div>
                              <div className="col-6 d-flex justify-content-center">
                                <i className="fas fa-users"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-12">
                <div className="card py-3 mb-3">
                  <div className="card-body py-3">
                    <div className="row g-0">
                      <div className="col-6 col-md-6 border-bottom border-end pb-4 ps-3">
                        <h6 className="pb-1 text-700">Cars Booked</h6>
                        <div className="d-flex align-items-center">
                          <h6 className="fs--1 text-500 mb-0">{bookings && bookings.length}</h6>
                        </div>
                      </div>
                      <div className="col-6 col-md-6 border-bottom pb-4 ps-3">
                        <h6 className="pb-1 text-700">Transfers Processing</h6>
                        <div className="d-flex align-items-center">
                          <h6 className="fs--1 text-500 mb-0">{transfersPending}</h6>
                        </div>
                      </div>
                      <div className="col-6 col-md-6 pt-4 border-end ps-3 ">
                        <h6 className="pb-1 text-700">Net Income </h6>
                        <div className="d-flex align-items-center">
                          <h6 className="fs--1 text-500 mb-0">£{netIncome}</h6>
                        </div>
                      </div>
                      <div className="col-6 col-md-6 pt-4 ps-3">
                        <h6 className="pb-1 text-700">Bookings Pending</h6>
                        <div className="d-flex align-items-center">
                          <h6 className="fs--1 text-500 mb-0">{bookingsPending}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header">
                    <div className="row flex-between-center g-0">
                      <div className="col-6 ps-4">
                        <h6 className="mb-0">Total Sales</h6>
                      </div>
                    </div>
                  </div>
                  <div className="card-body pe-xxl-0">
                    <ReactEcharts option={monthlyChart} />
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-3 mb-3">
              <div className="col-lg-12">
                <div className="card z-index-1">
                  <div className="card-header">
                    <div className="row flex-between-center">
                      <div className="col-6 col-sm-auto d-flex align-items-center pe-0">
                        <h5 className="fs-0 mb-0 text-nowrap py-2 py-xl-0 text-uppercase">Recent Bookings</h5>
                      </div>
                    </div>
                  </div>
                  <div className="card-body px-0 py-0">
                    <div className="table-responsive scrollbar">
                      <table className="table table-sm fs--1 mb-0 overflow-hidden">
                        <thead className="bg-200 text-900">
                          <tr>
                            <th className="align-middle white-space-nowrap">Customer</th>
                            <th className="align-middle white-space-nowrap">Email</th>
                            <th className="align-middle white-space-nowrap">Car</th>
                            <th className="align-middle white-space-nowrap text-center">Status</th>
                            <th className="align-middle white-space-nowrap text-end">Amount</th>
                            <th className="no-align-middle data-table-row-action"></th>
                          </tr>
                        </thead>
                        <tbody className="list" id="table-purchase-body">
                          {bookings && bookings.length > 0 ? (
                            bookings.slice(-5).map((item) => (
                              <tr>
                                <td className="align-middle white-space-nowrap">{item.customerDetails.name}</td>
                                <td className="align-middle white-space-nowrap">{item.customerDetails.email}</td>
                                <td className="align-middle white-space-nowrap image">
                                  <img src={`/images/${item.carDetails.image.url}`} className="img-fluid" />
                                </td>
                                <td className="align-middle white-space-nowrap text-center">
                                  <span
                                    className={`px-2 py-1 text-white text-uppercase fw-bold rounded-pill ${
                                      item.status === "Pending" ? "bg-warning" : "bg-success"
                                    }`}
                                    style={{ fontSize: "10px" }}
                                  >
                                    {item.status}
                                  </span>
                                </td>
                                <td className="align-middle white-space-nowrap text-end">{item.totalPrice}$</td>
                                <td className="align-middle white-space-nowrap text-end">
                                  <div className="dropstart font-sans-serif position-static d-inline-block">
                                    <button
                                      className="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal float-end"
                                      type="button"
                                      id="dropdown0"
                                      data-bs-toggle="dropdown"
                                      data-boundary="window"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                      data-bs-reference="parent"
                                    >
                                      <svg
                                        className="svg-inline--fa fa-ellipsis-h fa-w-16 fs--1"
                                        aria-hidden="true"
                                        focusable="false"
                                        data-prefix="fas"
                                        data-icon="ellipsis-h"
                                        role="img"
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 512 512"
                                        data-fa-i2svg=""
                                      >
                                        <path
                                          fill="currentColor"
                                          d="M328 256c0 39.8-32.2 72-72 72s-72-32.2-72-72 32.2-72 72-72 72 32.2 72 72zm104-72c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm-352 0c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72z"
                                        ></path>
                                      </svg>
                                    </button>
                                    <div
                                      className="dropdown-menu dropdown-menu-end border py-2"
                                      aria-labelledby="dropdown0"
                                    >
                                      <Link className="dropdown-item" target="_blank" to={`/admin/booking/${item.id}`}>
                                        View
                                      </Link>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr className="btn-reveal-trigger">
                              <td className="text-center align-middle py-3" colSpan={6}>
                                No Results Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default Dashboard;
