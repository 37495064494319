import React, { Fragment, useEffect } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { clearErrors, getBookingDetails } from "../../actions/bookingAction";
import Loader from "../layout/Loader/Loader";
import MetaData from "../layout/MetaData";

const BookingDetails = () => {
  const dispatch = useDispatch();
  const alert = useAlert();

  let { id } = useParams();

  const { error, loading, booking } = useSelector((state) => state.bookingDetails);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    dispatch(getBookingDetails(id));
  }, [dispatch, id, error, alert]);
  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        booking && (
          <Fragment>
            <MetaData title={`Booking Details - ${booking.id}`} />
            <div className="card">
              <h1 id="productListHeading">Booking Details</h1>
              <div className="container-fluid">
                <h3 className="block-title">
                  Booking Id: <span className="fw-normal">{booking.id}</span>
                </h3>
                <h3 className="block-title">
                  <span>Car Information</span>
                </h3>
                <div className="car-big-card alt">
                  <div className="row">
                    <div
                      className="col-md-7"
                      style={{
                        backgroundColor: "white",
                        textAlign: "-webkit-center",
                      }}
                    >
                      <img src={booking.carDetails && `/images/${booking.carDetails.image}`} className="img-fluid" />
                    </div>
                    <div className="col-md-5">
                      <div className="car-details">
                        <div className="list">
                          <ul>
                            <li className="title">
                              <h2>
                                {booking.carDetails && booking.carDetails.name} /{" "}
                                <span>{booking.carDetails && booking.carDetails.category}</span>
                              </h2>
                            </li>
                            <li>
                              {booking.carDetails &&
                                booking.carDetails.engine &&
                                booking.carDetails.engine.toPrecision(2)}{" "}
                              lt
                            </li>
                            <li>{booking.carDetails && booking.carDetails.gearbox} </li>
                            <li>{booking.carDetails && booking.carDetails.fuel} </li>
                            <li>{booking.carDetails && booking.carDetails.passengers} Seats </li>
                            <li>{booking.carDetails && booking.carDetails.doors} Doors</li>
                            <li>{booking.carDetails && booking.carDetails.additionalInfo}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="page-divider half transparent" />
                <h3 className="block-title" id="pickupanddropoff">
                  <span>Pick Up & Drop Off</span>
                </h3>
                <div className="row mb-4 px-5">
                  <div className="col-sm-6 mb-2 mb-sm-4">
                    <div className="form-group has-icon has-label">
                      <label className="form-label-theme">Picking Up Location:</label> {booking.pickupLocation}
                    </div>
                  </div>
                  <div className="col-sm-6 mb-2 mb-sm-4">
                    <div className="form-group has-label has-icon">
                      <label className="form-label-theme">Picking Up Date:</label> {booking.pickupDate}{" "}
                      {booking.pickupTime}
                    </div>
                  </div>
                  <div className="col-sm-6 mb-2 mb-sm-4">
                    <div className="form-group has-icon has-label">
                      <label className="form-label-theme">Dropping Off Location:</label> {booking.dropoffLocation}
                    </div>
                  </div>
                  <div className="col-sm-6 mb-2 mb-sm-4">
                    <div className="form-group has-label has-icon">
                      <label className="form-label-theme">Dropping Off Date:</label> {booking.dropoffDate}{" "}
                      {booking.dropoffTime}
                    </div>
                  </div>
                </div>
                <h3 className="block-title">
                  <span>Extras</span>
                </h3>
                <div className="row mb-4 px-5">
                  <div className="col-md-6 d-flex align-items-center my-2">
                    <label className="form-label-theme">Baby Seat:</label>
                    &nbsp;{booking.babySeat}
                  </div>
                  <div className="col-md-6 d-flex align-items-center my-2">
                    <label className="form-label-theme">Booster:</label>
                    &nbsp;{booking.childSeat}
                  </div>
                </div>
                <h3 className="block-title mt-4">
                  <span>Customer Information</span>
                </h3>
                <div className="row pb-3 px-5">
                  <div className="col-md-6 mt-4">
                    <div className="form-group">
                      <label className="form-label-theme">Name:</label>{" "}
                      {booking.customerDetails && booking.customerDetails.name}
                    </div>
                  </div>
                  <div className="col-md-6 mt-4">
                    <div className="form-group">
                      <label className="form-label-theme">Surname:</label>{" "}
                      {booking.customerDetails && booking.customerDetails.surname}
                    </div>
                  </div>
                  <div className="col-md-6 mt-4">
                    <div className="form-group">
                      <label className="form-label-theme">Birth Date:</label>{" "}
                      {booking.customerDetails && booking.customerDetails.dateOfBirth}
                    </div>
                  </div>
                  <div className="col-md-6 mt-4">
                    <div className="form-group">
                      <label className="form-label-theme">Email:</label>{" "}
                      {booking.customerDetails && booking.customerDetails.email}
                    </div>
                  </div>
                  <div className="col-md-6 mt-4">
                    <div className="form-group">
                      <label className="form-label-theme">Address:</label>{" "}
                      {booking.customerDetails && booking.customerDetails.address}
                    </div>
                  </div>
                  <div className="col-md-6 mt-4">
                    <div className="form-group">
                      <label className="form-label-theme">Place to Stay in North Cyprus:</label>{" "}
                      {booking.customerDetails && booking.customerDetails.placeInNorthCyprus}
                    </div>
                  </div>
                  <div className="col-md-6 mt-4">
                    <div className="form-group">
                      <label className="form-label-theme">Phone:</label>{" "}
                      {booking.customerDetails && booking.customerDetails.phone}
                    </div>
                  </div>
                  <div className="col-md-6 mt-4">
                    <div className="form-group">
                      <label className="form-label-theme">Cell Phone:</label>{" "}
                      {booking.customerDetails && booking.customerDetails.cellPhone}
                    </div>
                  </div>
                  <div className="col-md-12 mt-4">
                    <div className="form-group">
                      <label className="form-label-theme">Message:</label>{" "}
                      {booking.customerDetails && booking.customerDetails.message}
                    </div>
                  </div>
                </div>
                <hr />
                <h3 className="block-title">
                  Total Price: <span className="fw-normal">£{booking.totalPrice}</span>
                </h3>
              </div>
            </div>
          </Fragment>
        )
      )}
    </Fragment>
  );
};

export default BookingDetails;
