import React, { Fragment, useEffect, useState } from "react";
import "./newProduct.css";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import MetaData from "../layout/MetaData";
import { useNavigate } from "react-router-dom";
import { getAllCategories } from "../../actions/categoryAction";
import Select from "react-select";
import { createCar, clearErrors } from "../../actions/carAction";
import { CREATE_CAR_RESET } from "../../constants/carConstants";

const CreateCar = () => {
  const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  const { loading, error, success } = useSelector((state) => state.createCar);

  const { categories } = useSelector((state) => state.categories);

  const [orderNo, setOrderNo] = useState(0);
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [engine, setEngine] = useState("");
  const [fuel, setFuel] = useState("");
  const [gearbox, setGearbox] = useState("");
  const [passengers, setPassengers] = useState(0);
  const [doors, setDoors] = useState(0);
  const [suitcases, setSuitcases] = useState(0);
  // Prices
  const [season1day1, setSeason1day1] = useState(0);
  const [season1day2, setSeason1day2] = useState(0);
  const [season2day1, setSeason2day1] = useState(0);
  const [season2day2, setSeason2day2] = useState(0);
  const [season3day1, setSeason3day1] = useState(0);
  const [season3day2, setSeason3day2] = useState(0);
  const [season4day1, setSeason4day1] = useState(0);
  const [season4day2, setSeason4day2] = useState(0);
  const [season5day1, setSeason5day1] = useState(0);
  const [season5day2, setSeason5day2] = useState(0);
  const [season6day1, setSeason6day1] = useState(0);
  const [season6day2, setSeason6day2] = useState(0);
  const [season7day1, setSeason7day1] = useState(0);
  const [season7day2, setSeason7day2] = useState(0);
  const [season8day1, setSeason8day1] = useState(0);
  const [season8day2, setSeason8day2] = useState(0);

  const [additionalInfo, setAdditionalInfo] = useState("");
  const [image, setImage] = useState("");
  const [imagePreview, setImagePreview] = useState("");
  const [AC, setAC] = useState(false);
  const [active, setActive] = useState(false);

  useEffect(() => {
    dispatch(getAllCategories());
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    if (success) {
      alert.success("Car Created Successfully");
      navigate("/admin/cars");
      dispatch({ type: CREATE_CAR_RESET });
    }
  }, [dispatch, alert, error, navigate, success]);

  const prices = [
    {
      season: 1,
      days1: season1day1,
      days2: season1day2,
    },
    {
      season: 2,
      days1: season2day1,
      days2: season2day2,
    },
    {
      season: 3,
      days1: season3day1,
      days2: season3day2,
    },
    {
      season: 4,
      days1: season4day1,
      days2: season4day2,
    },
    {
      season: 5,
      days1: season5day1,
      days2: season5day2,
    },
    {
      season: 6,
      days1: season6day1,
      days2: season6day2,
    },
    {
      season: 7,
      days1: season7day1,
      days2: season7day2,
    },
    {
      season: 8,
      days1: season8day1,
      days2: season8day2,
    },
  ];

  const createCarSubmitHandler = (e) => {
    e.preventDefault();

    const myForm = new FormData();

    myForm.set("orderNo", orderNo);
    myForm.set("name", name);
    myForm.set("category", category);
    myForm.set("engine", engine);
    myForm.set("fuel", fuel);
    myForm.set("gearbox", gearbox);
    myForm.set("passengers", passengers);
    myForm.set("doors", doors);
    myForm.set("suitcases", suitcases);
    myForm.set("prices", JSON.stringify(prices));
    myForm.set("additionalInfo", additionalInfo);
    myForm.set("image", image);
    myForm.set("AC", AC);
    myForm.set("active", active);

    dispatch(createCar(myForm));
  };

  const carImageHandler = (e) => {
    setImage(e.target.files[0]);
    const reader = new FileReader();

    reader.onload = () => {
      if (reader.readyState === 2) {
        setImagePreview(reader.result);
      }
    };

    reader.readAsDataURL(e.target.files[0]);
  };

  const handleOnChangeAC = () => {
    setAC(!AC);
  };

  const handleOnChangeActive = () => {
    setActive(!active);
  };

  const categorySelectOptions = [];

  categories &&
    categories.forEach((item) => {
      categorySelectOptions.push({
        value: item.name,
        label: item.name,
      });
    });

  const fuelSelectOptions = [
    { value: "Petrol", label: "Petrol" },
    { value: "Euro Diesel", label: "Euro Diesel" },
    { value: "High Octane", label: "High Octane" },
    { value: "Unleaded", label: "Unleaded" },
  ];
  const gearBoxSelectOptions = [
    { value: "Manual", label: "Manual" },
    { value: "Automatic", label: "Automatic" },
    { value: "Triptonic", label: "Triptonic" },
  ];
  return (
    <Fragment>
      <MetaData title="Create Car" />
      <div className="card">
        <h1 id="productListHeading">Create Car</h1>
        <div className="card-body px-0 py-0">
          <form className="AddProductForm" encType="multipart/form-data" onSubmit={createCarSubmitHandler}>
            <div className="row">
              <div className="col1 col-lg-5">
                <div>
                  <label for="name" className="prodFormLabel">
                    Car Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    placeholder="Enter Car Name..."
                    className="prodFormInput"
                    required
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col1 col-lg-5">
                <div>
                  <label for="type" className="prodFormLabel">
                    Car Type
                  </label>
                  <Select
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={categorySelectOptions}
                    required
                    onChange={(e) => setCategory(e.value)}
                  />
                </div>
              </div>
              <div className="col1 col-lg-2">
                <div>
                  <label for="orderNo" className="prodFormLabel">
                    Order Number
                  </label>
                  <input
                    type="number"
                    name="orderNo"
                    placeholder="Enter Order Number..."
                    className="prodFormInput"
                    required
                    onChange={(e) => setOrderNo(e.target.value)}
                  />
                </div>
              </div>
              <div className="col1 col-lg-4">
                <div>
                  <label for="engine" className="prodFormLabel">
                    Engine
                  </label>
                  <input
                    type="text"
                    name="engine"
                    placeholder="Enter Engine Type..."
                    className="prodFormInput"
                    required
                    onChange={(e) => setEngine(e.target.value)}
                  />
                </div>
              </div>
              <div className="col1 col-lg-4">
                <div>
                  <label for="fuel" className="prodFormLabel">
                    Fuel
                  </label>
                  <Select
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={fuelSelectOptions}
                    required
                    onChange={(e) => setFuel(e.value)}
                  />
                </div>
              </div>
              <div className="col1 col-lg-4">
                <div>
                  <label for="gearbox" className="prodFormLabel">
                    Gearbox
                  </label>
                  <Select
                    className="basic-multi-select"
                    classNamePrefix="select"
                    options={gearBoxSelectOptions}
                    required
                    onChange={(e) => setGearbox(e.value)}
                  />
                </div>
              </div>
              <div className="col1 col-lg-4">
                <div>
                  <label for="passengers" className="prodFormLabel">
                    No. Of Passengers
                  </label>
                  <input
                    type="text"
                    name="passengers"
                    placeholder="Enter No. Of Passengers..."
                    className="prodFormInput"
                    required
                    onChange={(e) => setPassengers(e.target.value)}
                  />
                </div>
              </div>
              <div className="col1 col-lg-4">
                <div>
                  <label for="doors" className="prodFormLabel">
                    No. Of Doors
                  </label>
                  <input
                    type="text"
                    name="doors"
                    placeholder="Enter No. Of Doors..."
                    className="prodFormInput"
                    required
                    onChange={(e) => setDoors(e.target.value)}
                  />
                </div>
              </div>
              <div className="col1 col-lg-4">
                <div>
                  <label for="suitcases" className="prodFormLabel">
                    No. Of Suitcases
                  </label>
                  <input
                    type="text"
                    name="suitcases"
                    placeholder="Enter No. Of Suitcases..."
                    className="prodFormInput"
                    required
                    onChange={(e) => setSuitcases(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <h5>Prices</h5>
                <div className="row">
                  <div className="col border-start border-end">
                    <div>
                      <label for="lowPrice" className="prodFormLabel">
                        Nov-Dec-Jan-Feb
                      </label>
                      <div className="d-flex">
                        <div>
                          <label for="lowPrice" className="prodFormLabel">
                            1-9 days
                          </label>
                          <input
                            type="text"
                            name="lowPrice"
                            className="prodFormInput"
                            required
                            onChange={(e) => setSeason1day1(e.target.value)}
                          />
                        </div>
                        <div>
                          <label for="lowPrice" className="prodFormLabel">
                            10+ days
                          </label>
                          <input
                            type="text"
                            name="lowPrice"
                            className="prodFormInput"
                            required
                            onChange={(e) => setSeason1day2(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col border-end">
                    <div>
                      <label for="lowPrice" className="prodFormLabel">
                        March
                      </label>
                      <div className="d-flex">
                        <div>
                          <label for="lowPrice" className="prodFormLabel">
                            1-9 days
                          </label>
                          <input
                            type="text"
                            name="lowPrice"
                            className="prodFormInput"
                            required
                            onChange={(e) => setSeason2day1(e.target.value)}
                          />
                        </div>
                        <div>
                          <label for="lowPrice" className="prodFormLabel">
                            10+ days
                          </label>
                          <input
                            type="text"
                            name="lowPrice"
                            className="prodFormInput"
                            required
                            onChange={(e) => setSeason2day2(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col border-end">
                    <div>
                      <label for="lowPrice" className="prodFormLabel">
                        April
                      </label>
                      <div className="d-flex">
                        <div>
                          <label for="lowPrice" className="prodFormLabel">
                            1-9 days
                          </label>
                          <input
                            type="text"
                            name="lowPrice"
                            className="prodFormInput"
                            required
                            onChange={(e) => setSeason3day1(e.target.value)}
                          />
                        </div>
                        <div>
                          <label for="lowPrice" className="prodFormLabel">
                            10+ days
                          </label>
                          <input
                            type="text"
                            name="lowPrice"
                            className="prodFormInput"
                            required
                            onChange={(e) => setSeason3day2(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col border-end">
                    <div>
                      <label for="lowPrice" className="prodFormLabel">
                        May
                      </label>
                      <div className="d-flex">
                        <div>
                          <label for="lowPrice" className="prodFormLabel">
                            1-9 days
                          </label>
                          <input
                            type="text"
                            name="lowPrice"
                            className="prodFormInput"
                            required
                            onChange={(e) => setSeason4day1(e.target.value)}
                          />
                        </div>
                        <div>
                          <label for="lowPrice" className="prodFormLabel">
                            10+ days
                          </label>
                          <input
                            type="text"
                            name="lowPrice"
                            className="prodFormInput"
                            required
                            onChange={(e) => setSeason4day2(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col border-end">
                    <div>
                      <label for="lowPrice" className="prodFormLabel">
                        Jun
                      </label>
                      <div className="d-flex">
                        <div>
                          <label for="lowPrice" className="prodFormLabel">
                            1-9 days
                          </label>
                          <input
                            type="text"
                            name="lowPrice"
                            className="prodFormInput"
                            required
                            onChange={(e) => setSeason5day1(e.target.value)}
                          />
                        </div>
                        <div>
                          <label for="lowPrice" className="prodFormLabel">
                            10+ days
                          </label>
                          <input
                            type="text"
                            name="lowPrice"
                            className="prodFormInput"
                            required
                            onChange={(e) => setSeason5day2(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col border-end">
                    <div>
                      <label for="lowPrice" className="prodFormLabel">
                        Oct
                      </label>
                      <div className="d-flex">
                        <div>
                          <label for="lowPrice" className="prodFormLabel">
                            1-9 days
                          </label>
                          <input
                            type="text"
                            name="lowPrice"
                            className="prodFormInput"
                            required
                            onChange={(e) => setSeason6day1(e.target.value)}
                          />
                        </div>
                        <div>
                          <label for="lowPrice" className="prodFormLabel">
                            10+ days
                          </label>
                          <input
                            type="text"
                            name="lowPrice"
                            className="prodFormInput"
                            required
                            onChange={(e) => setSeason6day2(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col border-end">
                    <div>
                      <label for="lowPrice" className="prodFormLabel">
                        Jul-Sep
                      </label>
                      <div className="d-flex">
                        <div>
                          <label for="lowPrice" className="prodFormLabel">
                            1-9 days
                          </label>
                          <input
                            type="text"
                            name="lowPrice"
                            className="prodFormInput"
                            required
                            onChange={(e) => setSeason7day1(e.target.value)}
                          />
                        </div>
                        <div>
                          <label for="lowPrice" className="prodFormLabel">
                            10+ days
                          </label>
                          <input
                            type="text"
                            name="lowPrice"
                            className="prodFormInput"
                            required
                            onChange={(e) => setSeason7day2(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col border-end">
                    <div>
                      <label for="lowPrice" className="prodFormLabel">
                        Aug
                      </label>
                      <div className="d-flex">
                        <div>
                          <label for="lowPrice" className="prodFormLabel">
                            1-9 days
                          </label>
                          <input
                            type="text"
                            name="lowPrice"
                            className="prodFormInput"
                            required
                            onChange={(e) => setSeason8day1(e.target.value)}
                          />
                        </div>
                        <div>
                          <label for="lowPrice" className="prodFormLabel">
                            10+ days
                          </label>
                          <input
                            type="text"
                            name="lowPrice"
                            className="prodFormInput"
                            required
                            onChange={(e) => setSeason8day2(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col1 col-lg-6">
                <div>
                  <label for="additionalInfo" className="prodFormLabel">
                    Additional Information
                  </label>
                  <textarea
                    type="text"
                    name="additionalInfo"
                    placeholder="Enter Additional Information..."
                    className="prodFormTextArea"
                    onChange={(e) => setAdditionalInfo(e.target.value)}
                  />
                </div>
              </div>
              <div className="col1 col-lg-4 flex-column align-items-start">
                <label style={{ fontSize: "15px", margin: 0 }}>Image</label>
                <div className="file-input">
                  <label
                    className="forImg prodFormLabel"
                    style={{
                      backgroundImage: `url('${imagePreview}')`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                    }}
                    for="images"
                  >
                    Select Image
                  </label>
                  <input
                    type="file"
                    name="images"
                    placeholder="Select Product Images"
                    className="prodFormInputFile file"
                    accept="image/*"
                    onChange={carImageHandler}
                  />
                </div>
              </div>
              <div className="col1 col-lg-1">
                <div>
                  <label for="AC" className="prodFormLabel">
                    AC
                  </label>
                  <input
                    type="checkbox"
                    name="AC"
                    className="prodFormInputCheck"
                    isChecked={AC}
                    onChange={handleOnChangeAC}
                  />
                </div>
              </div>
              <div className="col1 col-lg-1">
                <div>
                  <label for="active" className="prodFormLabel">
                    Active
                  </label>
                  <input
                    type="checkbox"
                    name="active"
                    className="prodFormInputCheck"
                    isChecked={active}
                    onChange={handleOnChangeActive}
                  />
                </div>
              </div>
            </div>
            <div className="col1 col-lg-12">
              <div>
                <input type="submit" className="btn btn-theme" />
              </div>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

export default CreateCar;
