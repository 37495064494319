import React, { Fragment, useEffect, useState } from "react";
import "./productList.css";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import MetaData from "../layout/MetaData";
import { getAllTransfers, clearErrors, deleteTransfer, updateTransfer } from "../../actions/transferAction";
import { DELETE_TRANSFER_RESET, UPDATE_TRANSFER_RESET } from "../../constants/transferConstants";
import Loader from "../layout/Loader/Loader";
import Modal from "react-bootstrap/Modal";

const TransfersList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();

  const { error, transfers, loading } = useSelector((state) => state.transfers);

  const { error: deleteError, isDeleted, message, isUpdated } = useSelector((state) => state.transfer);

  const deleteTransferHandler = (id) => {
    dispatch(deleteTransfer(id));
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }

    if (deleteError) {
      alert.error(deleteError);
      dispatch(clearErrors());
    }
    if (isUpdated) {
      alert.success("Status Updated Successfully");
      dispatch({ type: UPDATE_TRANSFER_RESET });
    }
    if (isDeleted) {
      alert.success("Transfer Deleted Succesfully");
      navigate("/admin/transfers");
      dispatch({ type: DELETE_TRANSFER_RESET });
    }
    dispatch(getAllTransfers());
  }, [dispatch, alert, error, deleteError, navigate, isDeleted, message, isUpdated]);

  const [show, setShow] = useState(false);
  const [modalId, setModalId] = useState("");
  const [updateStatus, setUpdateStatus] = useState("");

  const handleClose = () => {
    setModalId("");
    setShow(false);
  };
  const handleShow = (id) => {
    setModalId(id);
    setShow(true);
  };
  const handleUpdateStatus = () => {
    let transfer = { status: updateStatus };
    dispatch(updateTransfer(modalId, transfer));
    handleClose();
  };

  return (
    <Fragment>
      <MetaData title={`ALL Transfers - Admin`} />
      <Modal backdrop="static" show={show} onHide={handleClose} keyboard={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>Update Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <label className="labels">Update Status</label>
            <select className="form-control" required onChange={(e) => setUpdateStatus(e.target.value)}>
              <option selected disabled hidden>
                Select
              </option>
              <option value="Pending">Pending</option>
              <option value="Confirmed">Confirmed</option>
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <input type="button" className="btn btn-danger" onClick={handleClose} value="Close" />
          <input type="button" className="btn btn-theme" value="Update" onClick={handleUpdateStatus} />
        </Modal.Footer>
      </Modal>
      <div className="card">
        <h1 id="productListHeading">All Transfers</h1>
        {loading ? (
          <Loader />
        ) : (
          <div className="card-body px-0 py-0">
            <div className="table-responsive scrollbar">
              <table className="table table-sm fs--1 mb-0 overflow-hidden">
                <thead className="bg-200 text-900">
                  <tr>
                    <th className="align-middle border white-space-nowrap" rowSpan={3}>
                      Transfer Id
                    </th>
                    <th className="text-center align-middle border white-space-nowrap" rowSpan={3}>
                      Cusomter
                    </th>
                    <th className="text-center align-middle border white-space-nowrap" rowSpan={3}>
                      Cusomter Email
                    </th>
                    <th className="text-center white-space-nowrap border" colSpan={3}>
                      Arrival
                    </th>
                    <th className="text-center white-space-nowrap border" colSpan={3}>
                      Departure
                    </th>
                    <th className="text-center white-space-nowrap text-center align-middle border" rowSpan={3}>
                      Status
                    </th>
                    <th className="white-space-nowrap text-center align-middle border border-end-0" rowSpan={3}>
                      Created At
                    </th>
                    <th rowSpan={3} className="data-table-row-action border-top border-bottom"></th>
                  </tr>
                  <tr>
                    <th className="text-center white-space-nowrap border">From</th>
                    <th className="text-center white-space-nowrap border">To</th>
                    <th className="text-center white-space-nowrap border">Date</th>
                    <th className="text-center white-space-nowrap border">From</th>
                    <th className="text-center white-space-nowrap border">To</th>
                    <th className="text-center white-space-nowrap border">Date</th>
                  </tr>
                </thead>
                <tbody className="list" id="table-purchase-body">
                  {transfers && transfers.length > 0 ? (
                    transfers.map((item) => (
                      <tr className="btn-reveal-trigger">
                        <td className="align-middle white-space-nowrap">{item.id}</td>
                        <td className="text-center align-middle white-space-nowrap">
                          {item.customerDetails && item.customerDetails.name}
                        </td>
                        <td className="text-center align-middle white-space-nowrap">
                          {item.customerDetails && item.customerDetails.email}
                        </td>

                        {item.arrivalPickupLocation ? (
                          <>
                            <td className="text-center align-middle white-space-nowrap">
                              {item.arrivalPickupLocation}
                            </td>
                            <td className="text-center align-middle white-space-nowrap">
                              {item.arrivalDropoffLocation}
                            </td>
                            <td className="text-center align-middle white-space-nowrap">
                              {String(item.arrivalPickupDate).substring(0, 10)} {item.arrivalPickupTime}
                            </td>
                          </>
                        ) : (
                          <td className="text-center align-middle white-space-nowrap" colSpan={3}>
                            <span
                              className="d-flex justify-content-center px-2 py-1 text-white text-uppercase fw-bold bg-danger"
                              style={{ fontSize: "10px" }}
                            >
                              Arrival Not Selected
                            </span>
                          </td>
                        )}
                        {item.departurePickupLocation ? (
                          <>
                            <td className="text-center align-middle white-space-nowrap">
                              {item.departurePickupLocation}
                            </td>
                            <td className="text-center align-middle white-space-nowrap">
                              {item.departureDropoffLocation}
                            </td>
                            <td className="text-center align-middle white-space-nowrap">
                              {String(item.departurePickupDate).substring(0, 10)} {item.departurePickupTime}
                            </td>
                          </>
                        ) : (
                          <td className="text-center align-middle white-space-nowrap" colSpan={3}>
                            <span
                              className="d-flex justify-content-center px-2 py-1 text-white text-uppercase fw-bold bg-danger"
                              style={{ fontSize: "10px" }}
                            >
                              Departure Not Selected
                            </span>
                          </td>
                        )}
                        <td className="text-center align-middle">
                          <span
                            className={`px-2 py-1 text-white text-uppercase fw-bold rounded-pill ${
                              item.status === "Pending" ? "bg-warning" : "bg-success"
                            }`}
                            style={{ fontSize: "10px" }}
                          >
                            {item.status}
                          </span>
                        </td>
                        <td className="text-center align-middle">{String(item.createdAt).substring(0, 10)}</td>
                        <td className="text-center align-middle white-space-nowrap">
                          <div className="dropstart font-sans-serif position-static d-inline-block">
                            <button
                              className="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal float-end"
                              type="button"
                              id="dropdown0"
                              data-bs-toggle="dropdown"
                              data-boundary="window"
                              aria-haspopup="true"
                              aria-expanded="false"
                              data-bs-reference="parent"
                            >
                              <svg
                                className="svg-inline--fa fa-ellipsis-h fa-w-16 fs--1"
                                aria-hidden="true"
                                focusable="false"
                                data-prefix="fas"
                                data-icon="ellipsis-h"
                                role="img"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                data-fa-i2svg=""
                              >
                                <path
                                  fill="currentColor"
                                  d="M328 256c0 39.8-32.2 72-72 72s-72-32.2-72-72 32.2-72 72-72 72 32.2 72 72zm104-72c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm-352 0c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72z"
                                ></path>
                              </svg>
                            </button>
                            <div className="dropdown-menu dropdown-menu-end border py-2" aria-labelledby="dropdown0">
                              <Link className="dropdown-item" target="_blank" to={`/admin/transfer/${item.id}`}>
                                View
                              </Link>
                              <Link className="dropdown-item" onClick={() => handleShow(item.id)}>
                                Update Status
                              </Link>
                              <div className="dropdown-divider"></div>
                              <Link
                                onClick={() => deleteTransferHandler(item.id)}
                                className="dropdown-item text-danger"
                              >
                                Delete
                              </Link>
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr className="btn-reveal-trigger">
                      <td className="text-center align-middle py-3" colSpan={11}>
                        No Results Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default TransfersList;
