import {
  ALL_TRANSFER_PRICE_REQUEST,
  ALL_TRANSFER_PRICE_SUCCESS,
  ALL_TRANSFER_PRICE_FAIL,
  CREATE_TRANSFER_PRICE_REQUEST,
  CREATE_TRANSFER_PRICE_SUCCESS,
  CREATE_TRANSFER_PRICE_FAIL,
  CREATE_TRANSFER_PRICE_RESET,
  TRANSFER_PRICE_DETAILS_REQUEST,
  TRANSFER_PRICE_DETAILS_SUCCESS,
  TRANSFER_PRICE_DETAILS_FAIL,
  UPDATE_TRANSFER_PRICE_REQUEST,
  UPDATE_TRANSFER_PRICE_SUCCESS,
  UPDATE_TRANSFER_PRICE_FAIL,
  UPDATE_TRANSFER_PRICE_RESET,
  DELETE_TRANSFER_PRICE_REQUEST,
  DELETE_TRANSFER_PRICE_SUCCESS,
  DELETE_TRANSFER_PRICE_FAIL,
  DELETE_TRANSFER_PRICE_RESET,
  CLEAR_ERRORS,
} from "../constants/transferPriceConstants";

export const transferPricesReducer = (
  state = { transferPrices: [] },
  action
) => {
  switch (action.type) {
    case ALL_TRANSFER_PRICE_REQUEST:
      return {
        loading: true,
        transferPrices: [],
      };
    case ALL_TRANSFER_PRICE_SUCCESS:
      return {
        loading: false,
        transferPrices: action.payload,
      };
    case ALL_TRANSFER_PRICE_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const createTransferPriceReducer = (
  state = { transferPrice: {} },
  action
) => {
  switch (action.type) {
    case CREATE_TRANSFER_PRICE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_TRANSFER_PRICE_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        transferPrice: action.payload.transferPrice,
      };
    case CREATE_TRANSFER_PRICE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CREATE_TRANSFER_PRICE_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const transferPriceReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_TRANSFER_PRICE_REQUEST:
    case UPDATE_TRANSFER_PRICE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_TRANSFER_PRICE_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case UPDATE_TRANSFER_PRICE_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_TRANSFER_PRICE_FAIL:
    case UPDATE_TRANSFER_PRICE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_TRANSFER_PRICE_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_TRANSFER_PRICE_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const transferPriceDetailsReducer = (
  state = { transferPrice: {} },
  action
) => {
  switch (action.type) {
    case TRANSFER_PRICE_DETAILS_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case TRANSFER_PRICE_DETAILS_SUCCESS:
      return {
        loading: false,
        transferPrice: action.payload,
      };
    case TRANSFER_PRICE_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
