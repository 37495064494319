import React, { Fragment, useEffect, useRef, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getCarDetails } from "../../actions/carAction";
import { clearErrors } from "../../actions/bookingAction";
import Breadcrumb from "../layout/Breadcrumb";
import Loader from "../layout/Loader/Loader";
import $ from "jquery";
import TimeSelect from "../layout/TimeSelect";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ContactFooter from "../layout/ContactFooter";
import axios from "axios";
import MetaData from "../layout/MetaData";
import ReCAPTCHA from "react-google-recaptcha";
import moment from "moment";

const CarBooking = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { id } = useParams();

  const { car, loading } = useSelector((state) => state.carDetails);

  const { error, success } = useSelector((state) => state.createBooking);

  const { bookingInfo } = useSelector((state) => state.createBooking);

  const [transferPrices, setTransferPrices] = useState();

  useEffect(() => {
    axios.get("/api/v1/transfer-prices").then((response) => {
      setTransferPrices(response.data.transferPrices);
    });
  }, []);

  useEffect(() => {
    if (bookingInfo) {
      if (Object.keys(bookingInfo).length > 0) {
        setPickUpLocation(bookingInfo.pickUpLocation);
        setPickUpDate(new Date(bookingInfo.pickUpDate));
        setPickUpTime(bookingInfo.pickUpTime);
        setDropOffLocation(bookingInfo.dropOffLocation);
        setDropOffDate(new Date(bookingInfo.dropOffDate));
        setDropOffTime(bookingInfo.dropOffTime);
      }
    }
  }, [bookingInfo]);

  const [pickUpLocation, setPickUpLocation] = useState("");
  const [pickUpDate, setPickUpDate] = useState("");
  const [pickUpTime, setPickUpTime] = useState("00:00");
  const [dropOffLocation, setDropOffLocation] = useState("");
  const [dropOffDate, setDropOffDate] = useState("");
  const [dropOffTime, setDropOffTime] = useState("00:00");
  const [babySeat, setBabySeat] = useState(0);
  const [childSeat, setChildSeat] = useState(0);
  const [customerTitle, setCustomerTitle] = useState("Mr");
  const [customerName, setCustomerName] = useState("");
  const [customerSurname, setCustomerSurname] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [customerPlaceInCyprus, setCustomerPlaceInCyprus] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerCellPhone, setCustomerCellPhone] = useState("");
  const [customerMessage, setCustomerMessage] = useState("");
  const [customerDateOfBirth, setCustomerDateOfBirth] = useState("");
  const [termsConditions, setTermsConditions] = useState("");

  var [hours1, minutes1] = pickUpTime.split(":");
  var [hours2, minutes2] = dropOffTime.split(":");

  var new1Date = new Date();
  var new2Date = new Date();

  new1Date.setHours(hours1, minutes1);
  new2Date.setHours(hours2, minutes2);

  var diffMilliseconds = Math.abs(dropOffDate - pickUpDate);

  var diffMilliseconds2 = new2Date - new1Date;

  var diffInHours = diffMilliseconds / (1000 * 60 * 60);

  var diffInHours2 = diffMilliseconds2 / (1000 * 60 * 60);

  let diffInDays = Math.floor(diffInHours / 24);

  let remainingHours = diffInHours2 % 24;

  if (remainingHours > 3) {
    diffInDays++;
  }

  const captchaRef = useRef(null);

  let totalPriceBooking = 0;

  const startYear = pickUpDate && pickUpDate.getFullYear();
  const startMonth = pickUpDate && pickUpDate.getMonth();
  const startDate = pickUpDate && pickUpDate.getDate();
  const endYear = dropOffDate && dropOffDate.getFullYear();
  const endMonth = dropOffDate && dropOffDate.getMonth();
  const endDate = dropOffDate && dropOffDate.getDate();

  for (let year = startYear; year <= endYear; year++) {
    const monthStart = year === startYear ? startMonth : 0;
    const monthEnd = year === endYear ? endMonth : 11;

    for (let month = monthStart; month <= monthEnd; month++) {
      const isStartMonth = year === startYear && month === startMonth;
      const isEndMonth = year === endYear && month === endMonth;

      const daysInMonth = new Date(year, month + 1, 0).getDate();
      const startDay = isStartMonth ? startDate : 1;
      const endDay = isEndMonth ? endDate : daysInMonth;

      for (let day = startDay; isEndMonth ? (diffInHours2 > 3 ? day <= endDay : day < endDay) : day <= endDay; day++) {
        const price =
          car.prices &&
          (month >= 10 || month <= 1
            ? diffInDays >= 10
              ? car.prices[0].days2
              : car.prices[0].days1
            : month === 2
            ? diffInDays >= 10
              ? car.prices[1].days2
              : car.prices[1].days1
            : month === 3
            ? diffInDays >= 10
              ? car.prices[2].days2
              : car.prices[2].days1
            : month === 4
            ? diffInDays >= 10
              ? car.prices[3].days2
              : car.prices[3].days1
            : month === 5
            ? diffInDays >= 10
              ? car.prices[4].days2
              : car.prices[4].days1
            : month === 9
            ? diffInDays >= 10
              ? car.prices[5].days2
              : car.prices[5].days1
            : month === 6 || month === 8
            ? diffInDays >= 10
              ? car.prices[6].days2
              : car.prices[6].days1
            : month === 7
            ? diffInDays >= 10
              ? car.prices[7].days2
              : car.prices[7].days1
            : "");

        totalPriceBooking += price;
      }
    }
  }

  totalPriceBooking += (babySeat * 2 + childSeat * 2) * diffInDays;

  diffInDays < 7
    ? pickUpLocation === "Ercan Airport" || dropOffLocation === "Ercan Airport"
      ? (totalPriceBooking += 20)
      : (totalPriceBooking += 0)
    : (totalPriceBooking += 0);

  const booking = {
    carId: car.id,
    pickupLocation: pickUpLocation,
    pickupDate: moment(pickUpDate).format("DD/MM/YYYY"),
    pickupTime: pickUpTime,
    dropoffLocation: dropOffLocation,
    dropoffDate: moment(dropOffDate).format("DD/MM/YYYY"),
    dropoffTime: dropOffTime,
    babySeat: babySeat,
    childSeat: childSeat,
    customerDetails: {
      name: customerName,
      surname: customerSurname,
      email: customerEmail,
      address: customerAddress,
      placeInNorthCyprus: customerPlaceInCyprus,
      phone: customerPhone,
      cellPhone: customerCellPhone,
      message: customerMessage,
      dateOfBirth: customerDateOfBirth,
    },
    rentalPeriod: diffInDays,
    totalPrice: totalPriceBooking,
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    dispatch(getCarDetails(id));
  }, [dispatch, id, error, alert]);

  const handleChangeTerms = () => {
    setTermsConditions(!termsConditions);
  };

  // Form Input Errors
  const [inputError, setInputError] = useState(false);
  const [tokenE, setTokenE] = useState();
  // Booking
  const [pickLocE, setPickLocE] = useState();
  const [pickDateE, setPickDateE] = useState();
  const [dropLocE, setDropLocE] = useState();
  const [dropDateE, setDropDateE] = useState();
  // Customer
  const [cusNameE, setCusNameE] = useState();
  const [cusSurnameE, setCusSurnameE] = useState();
  const [cusEmailE, setCusEmailE] = useState();
  const [cusPhoneE, setCusPhoneE] = useState();
  const [cusCellPhoneE, setCusCellPhoneE] = useState();
  const [cusMessageE, setCusMessageE] = useState();
  const [cusPlaceCyprusE, setCusPlaceCyprusE] = useState();
  const [termsCondE, setTermsCondE] = useState(false);
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

  const bookingSubmitHandler = (e) => {
    e.preventDefault();
    let isError = false;
    const token = captchaRef.current.getValue();
    captchaRef.current.reset();

    if (!customerName) {
      window.scrollTo({
        top: document.getElementById("customerinformation").offsetTop,
      });
      setCusNameE("Enter Your Name");
      setInputError(true);
      isError = true;
    }
    if (!customerSurname) {
      window.scrollTo({
        top: document.getElementById("customerinformation").offsetTop,
      });
      setCusSurnameE("Enter Your Surname");
      setInputError(true);
      isError = true;
    }
    if (!customerEmail || !emailRegex.test(customerEmail)) {
      window.scrollTo({
        top: document.getElementById("customerinformation").offsetTop,
      });
      setCusEmailE("Email is not Valid");
      setInputError(true);
      isError = true;
    }
    if (!customerPlaceInCyprus) {
      window.scrollTo({
        top: document.getElementById("customerinformation").offsetTop,
      });
      setCusPlaceCyprusE("Enter Your Place to Stay");
      setInputError(true);
      isError = true;
    }
    if (!customerPhone) {
      window.scrollTo({
        top: document.getElementById("customerinformation").offsetTop,
      });
      setCusPhoneE("Enter Your Phone Number");
      setInputError(true);
      isError = true;
    }
    if (!customerMessage) {
      window.scrollTo({
        top: document.getElementById("customerinformation").offsetTop,
      });
      setCusMessageE("Enter Your Message");
      setInputError(true);
      isError = true;
    }
    if (!customerCellPhone) {
      window.scrollTo({
        top: document.getElementById("customerinformation").offsetTop,
      });
      setCusCellPhoneE("Enter Your Cell Phone Number");
      setInputError(true);
      isError = true;
    }
    if (!termsConditions) {
      window.scrollTo({
        top: document.getElementById("customerinformation").offsetTop,
      });
      setTermsCondE("Please accept all Terms and Conditions");
      setInputError(true);
      isError = true;
    }
    if (!token) {
      window.scrollTo({
        top: document.getElementById("customerinformation").offsetTop,
      });
      setTokenE("Fill Recaptcha");
      setInputError(true);
      isError = true;
    }
    if (!pickUpLocation) {
      window.scrollTo({
        top: document.getElementById("pickupanddropoff").offsetTop,
      });
      setPickLocE("Enter Pickup Location");
      setInputError(true);
      isError = true;
    }
    if (!pickUpDate) {
      window.scrollTo({
        top: document.getElementById("pickupanddropoff").offsetTop,
      });
      setPickDateE("Select Pickup Date");
      setInputError(true);
      isError = true;
    }
    if (!dropOffLocation) {
      window.scrollTo({
        top: document.getElementById("pickupanddropoff").offsetTop,
      });
      setDropLocE("Enter Dropoff Location");
      setInputError(true);
      isError = true;
    }
    if (!dropOffDate) {
      window.scrollTo({
        top: document.getElementById("pickupanddropoff").offsetTop,
      });
      setDropDateE("Select Dropoff Date");
      setInputError(true);
      isError = true;
    }
    if (!isError) {
      setInputError(false);
      navigate("/booking/confirm", { state: { booking } });
    }
  };

  useEffect(() => {
    if (inputError) {
      pickUpLocation ? setPickLocE() : setPickLocE("Enter Pickup Location");
      dropOffLocation ? setDropLocE() : setDropLocE("Enter Dropoff Location");
      pickUpDate ? setPickDateE() : setPickDateE("Select Pickup Date");
      dropOffDate ? setDropDateE() : setDropDateE("Select Dropoff Date");
      customerName ? setCusNameE() : setCusNameE("Enter Your Name");
      customerPhone ? setCusPhoneE() : setCusPhoneE("Enter Your Phone Number");
      customerCellPhone ? setCusCellPhoneE() : setCusCellPhoneE("Enter Your Cell Phone Number");
      customerSurname ? setCusSurnameE() : setCusSurnameE("Enter Your Surname");
      customerMessage ? setCusMessageE() : setCusMessageE("Enter Your Message");
      !customerEmail || !emailRegex.test(customerEmail) ? setCusEmailE("Email is not Valid") : setCusEmailE();
      customerPlaceInCyprus ? setCusPlaceCyprusE() : setCusPlaceCyprusE("Enter Your Place to Stay");
      termsConditions ? setTermsCondE() : setTermsCondE("Please accept all Terms and Conditions");
    }
  }, [
    pickUpLocation,
    dropOffLocation,
    pickUpDate,
    dropOffDate,
    customerName,
    customerSurname,
    customerEmail,
    customerPlaceInCyprus,
    customerPhone,
    customerMessage,
    customerCellPhone,
    termsConditions,
  ]);

  let minDate = new Date();
  let minDateDrop = pickUpDate && new Date(pickUpDate.getTime() + 1 * 24 * 60 * 60 * 1000);

  return (
    <Fragment>
      {loading ? (
        <Loader />
      ) : (
        <Fragment>
          <MetaData title="Baspinar - Car Booking" />
          <Breadcrumb parent="cars" name="Car Booking" />
          <section className="page-section with-sidebar sub-page">
            <div className="container-lg">
              <div className="row">
                <div className="col-md-9 content" id="content">
                  <h3 className="block-title alt">
                    <i className="fas fa-angle-down"></i>Car Information
                  </h3>
                  <div className="car-big-card alt">
                    <div className="row">
                      <div
                        className="col-md-7"
                        style={{
                          backgroundColor: "white",
                          textAlign: "-webkit-center",
                        }}
                      >
                        <img src={car.image && `/images/${car.image}`} className="img-fluid" />
                      </div>
                      <div className="col-md-5">
                        <div className="car-details">
                          <div className="list">
                            <ul>
                              <li className="title">
                                <h2>
                                  {car.name} / <span>{car.category}</span>
                                </h2>
                              </li>
                              <li>{car.engine && car.engine.toPrecision(2)} lt</li>
                              <li>{car.gearbox} </li>
                              <li>{car.fuel} </li>
                              <li>{car.passengers} Seats </li>
                              <li>{car.doors} Doors</li>
                              <li>{car.additionalInfo}</li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr className="page-divider half transparent" />
                  <h3 className="block-title alt" id="pickupanddropoff">
                    <i className="fas fa-angle-down"></i>Pick Up & Drop Off
                  </h3>
                  <div className="row mb-4">
                    <div className="col-sm-6 mb-2 mb-sm-4">
                      <div className="form-group has-icon has-label">
                        <label className="form-label-theme">Picking Up Location</label>
                        <select
                          className="form-control"
                          value={pickUpLocation}
                          onChange={(e) => setPickUpLocation(e.target.value)}
                        >
                          <option selected hidden>
                            Select
                          </option>
                          <option value="Ercan Airport">Ercan Airport</option>
                          <option value="Hotel in North Cyprus">Hotel in N.Cyprus</option>
                          <option value="Villa in North Cyprus">Villa in N.Cyprus</option>
                          <option value="Kyrenia Office">Kyrenia Office</option>
                          <option value="Famagusta">Famagusta</option>
                        </select>
                        <span className="text-danger">{pickLocE && pickLocE}</span>
                        <span className="form-control-icon">
                          <i className="fas fa-map-marker-alt"></i>
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-3 mb-2 mb-sm-4">
                      <div className="form-group has-label has-icon">
                        <label className="form-label-theme">Picking Up Date</label>
                        <DatePicker
                          className="form-control"
                          selected={pickUpDate}
                          onChange={(date) => setPickUpDate(date)}
                          minDate={minDate}
                          placeholderText="dd/MM/yyyy"
                          dateFormat="dd/MM/yyyy"
                        />
                        <span className="form-control-icon">
                          <i className="far fa-calendar"></i>
                        </span>
                        <span className="text-danger">{pickDateE && pickDateE}</span>
                      </div>
                    </div>
                    <div className="col-sm-3 mb-2 mb-sm-4">
                      <div className="form-group has-icon has-label">
                        <label className="form-label-theme">Picking Up Hour</label>
                        <TimeSelect selectedVal={pickUpTime} onChange={(value) => setPickUpTime(value)} />
                        <span className="form-control-icon">
                          <i className="far fa-clock"></i>
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-6 mb-2 mb-sm-4">
                      <div className="form-group has-icon has-label">
                        <label className="form-label-theme">Dropping Off Location</label>
                        <select
                          className="form-control"
                          value={dropOffLocation}
                          onChange={(e) => setDropOffLocation(e.target.value)}
                        >
                          <option selected hidden>
                            Select
                          </option>
                          <option value="Ercan Airport">Ercan Airport</option>
                          <option value="Hotel in North Cyprus">Hotel in N.Cyprus</option>
                          <option value="Villa in North Cyprus">Villa in N.Cyprus</option>
                          <option value="Kyrenia Office">Kyrenia Office</option>
                          <option value="Famagusta">Famagusta</option>
                        </select>
                        <span className="text-danger">{dropLocE && dropLocE}</span>
                        <span className="form-control-icon">
                          <i className="fas fa-map-marker-alt"></i>
                        </span>
                      </div>
                    </div>
                    <div className="col-sm-3 mb-2 mb-sm-4">
                      <div className="form-group has-label has-icon">
                        <label className="form-label-theme">Dropping Off Date</label>
                        <DatePicker
                          className="form-control"
                          selected={dropOffDate}
                          onChange={(date) => setDropOffDate(date)}
                          minDate={minDateDrop}
                          placeholderText="dd/MM/yyyy"
                          dateFormat="dd/MM/yyyy"
                        />
                        <span className="form-control-icon">
                          <i className="far fa-calendar"></i>
                        </span>
                        <span className="text-danger">{dropDateE && dropDateE}</span>
                      </div>
                    </div>
                    <div className="col-sm-3 mb-2 mb-sm-4">
                      <div className="form-group has-icon has-label">
                        <label className="form-label-theme">Dropping Off Hour</label>
                        <TimeSelect selectedVal={dropOffTime} onChange={(value) => setDropOffTime(value)} />
                        <span className="form-control-icon">
                          <i className="far fa-clock"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <h3 className="block-title alt">
                    <i className="fas fa-angle-down"></i>Extras & Frees
                  </h3>
                  <div className="row mb-4">
                    <div className="col-md-4 d-flex align-items-center my-2">
                      <label className="form-label-theme">Baby Seat:</label>
                      <select
                        className="form-control"
                        name="babySeat"
                        style={{ width: "100px" }}
                        onChange={(e) => setBabySeat(e.target.value)}
                      >
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                      </select>
                    </div>
                    <div className="col-md-4 d-flex align-items-center my-2">
                      <label className="form-label-theme">Booster:</label>
                      <select
                        className="form-control"
                        name="childSeat"
                        style={{ width: "100px" }}
                        onChange={(e) => setChildSeat(e.target.value)}
                      >
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                      </select>
                    </div>
                  </div>
                  <h3 className="block-title alt" id="customerinformation">
                    <i className="fas fa-angle-down"></i>Customer Information
                  </h3>
                  <div className="panel-group payments-options">
                    <div className="panel panel-default">
                      <div className="panel-body">
                        <div className="panel panel-default" style={{ borderBottom: "none" }}>
                          <div className="row pb-3">
                            <div className="col-md-6 mt-4">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="name"
                                  className="form-control alt"
                                  placeholder="Name:*"
                                  required
                                  onChange={(e) => setCustomerName(e.target.value)}
                                />
                                <span className="text-danger">{cusNameE && cusNameE}</span>
                              </div>
                            </div>
                            <div className="col-md-6 mt-4">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="surname"
                                  className="form-control alt"
                                  placeholder="Surname:*"
                                  required
                                  onChange={(e) => setCustomerSurname(e.target.value)}
                                />
                                <span className="text-danger">{cusSurnameE && cusSurnameE}</span>
                              </div>
                            </div>
                            <div className="col-md-6 mt-4">
                              <div className="form-group">
                                <input
                                  placeholder="Date of Birth"
                                  type="text"
                                  name="dateOfBirth"
                                  className="form-control alt"
                                  onChange={(e) => setCustomerDateOfBirth(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-6 mt-4">
                              <div className="form-group">
                                <input
                                  type="email"
                                  name="email"
                                  className="form-control alt"
                                  placeholder="Your Email Address:*"
                                  required
                                  onChange={(e) => setCustomerEmail(e.target.value)}
                                />
                                <span className="text-danger">{cusEmailE && cusEmailE}</span>
                              </div>
                            </div>
                            <div className="col-md-12 mt-4">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="address"
                                  className="form-control alt"
                                  placeholder="Your Address:"
                                  required
                                  onChange={(e) => setCustomerAddress(e.target.value)}
                                />
                              </div>
                            </div>
                            <div className="col-md-12 mt-4">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="placeInNorthCyprus"
                                  className="form-control alt"
                                  placeholder="Place to Stay in North Cyprus:*"
                                  required
                                  onChange={(e) => setCustomerPlaceInCyprus(e.target.value)}
                                />
                                <span className="text-danger">{cusPlaceCyprusE && cusPlaceCyprusE}</span>
                              </div>
                            </div>
                            <div className="col-md-6 mt-4">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="phone"
                                  className="form-control alt"
                                  placeholder="Phone:*"
                                  required
                                  onChange={(e) => setCustomerPhone(e.target.value)}
                                />
                                <span className="text-danger">{cusPhoneE && cusPhoneE}</span>
                              </div>
                            </div>
                            <div className="col-md-6 mt-4">
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="cellPhone"
                                  className="form-control alt"
                                  placeholder="Cell Phone:*"
                                  required
                                  onChange={(e) => setCustomerCellPhone(e.target.value)}
                                />
                                <span className="text-danger">{cusCellPhoneE && cusCellPhoneE}</span>
                              </div>
                            </div>
                            <div className="col-md-12 mt-4">
                              <div className="form-group">
                                <textarea
                                  rows={5}
                                  type="text"
                                  name="message"
                                  className="form-control alt"
                                  placeholder="Message:*"
                                  required
                                  onChange={(e) => setCustomerMessage(e.target.value)}
                                ></textarea>
                                <span className="text-danger">{cusMessageE && cusMessageE}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ReCAPTCHA
                    sitekey="6LeKxzMkAAAAANgCKy2BAxnM3zi6ECNwJt1sg2Bu"
                    ref={captchaRef}
                    onChange={(e) => setTokenE(null)}
                  />
                  <span className="text-danger mb-4">{tokenE && tokenE}</span>
                  <hr className="mt-4" />
                  <div className="row">
                    <div className="col-9 d-flex flex-column justify-content-center">
                      <div>
                        <input type="checkbox" required onChange={handleChangeTerms} />
                        <label className="fw-bold">
                          I accept all{" "}
                          <Link to="/terms-conditions" className="text-theme">
                            Terms and Conditions
                          </Link>
                        </label>
                      </div>
                      <div className="row">
                        <span className="text-danger">{termsCondE && termsCondE}</span>
                      </div>
                    </div>
                    <div className="col-3">
                      <input
                        type="submit"
                        value="Continue"
                        className="btn btn-theme pull-right btn-reservation-now float-end"
                        onClick={bookingSubmitHandler}
                      />
                    </div>
                  </div>
                </div>
                <aside className="col-md-3 sidebar">
                  <div className="widget shadow widget-helping-center">
                    <h4 className="widget-title">Helping Center</h4>
                    <div className="widget-content">
                      <p>In case of an emergency please call our 24/7 phone number</p>
                      <h5 className="widget-title-sub">+90 542 851 43 33 </h5>
                      <p>
                        <a href="mailto:info@baspinar-rentals.com">info@baspinar-rentals.com</a>
                      </p>
                      <div className="button">
                        <a id="btnfooterpage" href="#" className="btn btn-theme w-100">
                          Support Center
                        </a>
                      </div>
                    </div>
                  </div>
                </aside>
              </div>
            </div>
          </section>
          <ContactFooter />
        </Fragment>
      )}
    </Fragment>
  );
};

export default CarBooking;
