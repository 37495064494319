import React, { Fragment, useEffect, useState } from "react";
import "./Home.css";
import { useSelector, useDispatch } from "react-redux";
import LoaderSlider from "../layout/Loader/LoaderSlider";
import { useAlert } from "react-alert";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import { getCars, clearErrors } from "../../actions/carAction";
import { Link, useNavigate } from "react-router-dom";
import ContactFooter from "../layout/ContactFooter";
import TimeSelect from "../layout/TimeSelect";
import { saveBooking } from "../../actions/bookingAction";
import DatePicker from "react-datepicker";
import MetaData from "../layout/MetaData";

const Home = () => {
  const alert = useAlert();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { error, cars, loading } = useSelector((state) => state.cars);

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
    dispatch(getCars());
  }, [dispatch, error, alert]);

  const [pickUpLocation, setPickUpLocation] = useState("");
  const [pickUpDate, setPickUpDate] = useState("");
  const [pickUpTime, setPickUpTime] = useState("00:00");
  const [dropOffLocation, setDropOffLocation] = useState("");
  const [dropOffDate, setDropOffDate] = useState("");
  const [dropOffTime, setDropOffTime] = useState("00:00");

  // Form Input Errors
  const [inputError, setInputError] = useState(false);
  const [pickLocE, setPickLocE] = useState();
  const [pickDateE, setPickDateE] = useState();
  const [dropLocE, setDropLocE] = useState();
  const [dropDateE, setDropDateE] = useState();

  const booking = {
    pickUpLocation: pickUpLocation,
    pickUpDate: pickUpDate,
    pickUpTime: pickUpTime,
    dropOffLocation: dropOffLocation,
    dropOffDate: dropOffDate,
    dropOffTime: dropOffTime,
  };

  const bookingSubmitHandler = (e) => {
    e.preventDefault();
    let isError = false;

    if (!pickUpLocation) {
      setPickLocE("Enter Pickup Location");
      setInputError(true);
      isError = true;
    }
    if (!pickUpDate) {
      setPickDateE("Select Pickup Date");
      setInputError(true);
      isError = true;
    }
    if (!dropOffLocation) {
      setDropLocE("Enter Dropoff Location");
      setInputError(true);
      isError = true;
    }
    if (!dropOffDate) {
      setDropDateE("Select Dropoff Date");
      setInputError(true);
      isError = true;
    }
    if (!isError) {
      setInputError(false);
      dispatch(saveBooking(booking));
      alert.show("Choose Car");
      navigate("/cars");
    }
  };

  useEffect(() => {
    if (inputError) {
      pickUpLocation ? setPickLocE() : setPickLocE("Enter Pickup Location");

      dropOffLocation ? setDropLocE() : setDropLocE("Enter Dropoff Location");

      pickUpDate ? setPickDateE() : setPickDateE("Select Pickup Date");

      dropOffDate ? setDropDateE() : setDropDateE("Select Dropoff Date");
    }
  }, [pickUpLocation, dropOffLocation, pickUpDate, dropOffDate]);

  let minDate = new Date();
  let minDateDrop = pickUpDate && new Date(pickUpDate.getTime() + 1 * 24 * 60 * 60 * 1000);
  return (
    <Fragment>
      <MetaData title="Baspinar - Rent A Car" />
      <div className="container full-width">
        <Splide
          className="main-slider"
          options={{
            height: 700,
            breakpoints: {
              574: {
                height: "unset",
              },
            },
          }}
          aria-label="My Favorite Images"
        >
          <SplideSlide>
            <img src="/img/preview/slider/slide-1.webp" alt="Image 1" />
            <div className="caption">
              <div className="container">
                <div className="div-table">
                  <div className="div-cell">
                    <div className="caption-content">
                      <h2 className="caption-title">All Discounts Just For You</h2>
                      <h3 className="caption-subtitle">Find Best Rental Car</h3>
                      <div className="row m-auto">
                        <div className="col-sm-12 col-md-10 m-auto">
                          <div className="form-search dark">
                            <form action="#">
                              <div className="form-title">
                                <i className="fa fa-globe"></i>
                                <h2>Search for Cheap Rental Cars Wherever Your Are</h2>
                              </div>
                              <div className="row row-inputs m-0 px-3">
                                <div className="col-sm-6">
                                  <div className="form-group has-icon has-label">
                                    <label for="formSearchUpLocation">Picking Up Location</label>
                                    <select
                                      className="form-control"
                                      onChange={(e) => setPickUpLocation(e.target.value)}
                                    >
                                      <option selected disabled hidden>
                                        Select
                                      </option>
                                      <option value="Ercan Airport">Ercan Airport</option>
                                      <option value="Hotel in North Cyprus">Hotel in N.Cyprus</option>
                                      <option value="Villa in North Cyprus">Villa in N.Cyprus</option>
                                      <option value="Kyrenia Office">Kyrenia Office</option>
                                      <option value="Famagusta">Famagusta</option>
                                    </select>
                                    <span className="text-danger">{pickLocE && pickLocE}</span>
                                    <span className="form-control-icon">
                                      <i className="fas fa-map-marker-alt"></i>
                                    </span>
                                  </div>
                                </div>
                                <div className="col-sm-3">
                                  <div className="form-group has-label has-icon">
                                    <label for="formSearchUpDate">Picking Up Date</label>
                                    <DatePicker
                                      className="form-control"
                                      selected={pickUpDate}
                                      onChange={(date) => setPickUpDate(date)}
                                      minDate={minDate}
                                      placeholderText="dd/MM/yyyy"
                                      dateFormat="dd/MM/yyyy"
                                    />
                                    <span className="form-control-icon">
                                      <i className="far fa-calendar"></i>
                                    </span>
                                    <span className="text-danger">{pickDateE && pickDateE}</span>
                                  </div>
                                </div>
                                <div className="col-sm-3">
                                  <div className="form-group has-label has-icon">
                                    <label>Picking Up Hour</label>
                                    <TimeSelect selectedVal={pickUpTime} onChange={(value) => setPickUpTime(value)} />
                                    <span className="form-control-icon">
                                      <i className="far fa-clock"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="row row-inputs m-0 px-3">
                                <div className="col-sm-6">
                                  <div className="form-group has-icon has-label">
                                    <label for="formSearchOffLocation">Dropping Off Location</label>
                                    <select
                                      className="form-control"
                                      onChange={(e) => setDropOffLocation(e.target.value)}
                                    >
                                      <option selected disabled hidden>
                                        Select
                                      </option>
                                      <option value="Ercan Airport">Ercan Airport</option>
                                      <option value="Hotel in North Cyprus">Hotel in N.Cyprus</option>
                                      <option value="Villa in North Cyprus">Villa in N.Cyprus</option>
                                      <option value="Kyrenia Office">Kyrenia Office</option>
                                      <option value="Famagusta">Famagusta</option>
                                    </select>
                                    <span className="text-danger">{dropLocE && dropLocE}</span>
                                    <span className="form-control-icon">
                                      <i className="fas fa-map-marker-alt"></i>
                                    </span>
                                  </div>
                                </div>
                                <div className="col-sm-3">
                                  <div className="form-group has-label has-icon">
                                    <label for="formSearchOffDate">Dropping Off Date</label>
                                    <DatePicker
                                      className="form-control"
                                      selected={dropOffDate}
                                      onChange={(date) => setDropOffDate(date)}
                                      minDate={minDateDrop}
                                      placeholderText="dd/MM/yyyy"
                                      dateFormat="dd/MM/yyyy"
                                    />
                                    <span className="form-control-icon">
                                      <i className="far fa-calendar"></i>
                                    </span>
                                    <span className="text-danger">{dropDateE && dropDateE}</span>
                                  </div>
                                </div>
                                <div className="col-sm-3">
                                  <div className="form-group has-label has-icon">
                                    <label>Dropping Off Hour</label>
                                    <TimeSelect selectedVal={dropOffTime} onChange={(value) => setDropOffTime(value)} />
                                    <span className="form-control-icon">
                                      <i className="far fa-clock"></i>
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div className="row row-submit">
                                <div className="container-fluid">
                                  <div className="inner d-flex justify-content-end">
                                    <button
                                      type="submit"
                                      onClick={bookingSubmitHandler}
                                      className="btn btn-submit btn-theme pull-right"
                                    >
                                      Find Car
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SplideSlide>
          <SplideSlide>
            <img src="/img/preview/slider/slide-2.webp" alt="Image 2" />
            <div className="caption">
              <div className="container">
                <div className="div-table">
                  <div className="div-cell">
                    <div className="caption-content" style={{ textAlign: "left" }}>
                      <h2 className="caption-subtitle">
                        PRIVATE LUXURY <br />
                        AIRPORT TRANSFER
                      </h2>
                      <p className="caption-text"></p>
                      <p className="caption-text">
                        <Link className="btn btn-theme" to="/transfer">
                          Transfer
                        </Link>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SplideSlide>
          <SplideSlide>
            <img src="/img/preview/slider/slide-3.webp" alt="Image 3" />
          </SplideSlide>
          <SplideSlide>
            <img src="/img/preview/slider/slide-4.webp" alt="Image 4" />
          </SplideSlide>
        </Splide>
      </div>

      <section className="page-section testimonials">
        <div className="container">
          <div className="row">
            <div className="col-md-6 wow fadeInLeft" data-wow-offset="200" data-wow-delay="100ms">
              <h2 className="section-title text-left">
                <small>What Do You Know About Us</small>
                <span>Who We Are ?</span>
              </h2>
              <p>
                Since 1993 Baspinar Rent A Car has been the leader in car rental services in North Cyprus. The company
                has continued to go from strength to strength, operating in the beginning from our first office in Lapta
                before moving to our modern and spacious office in Catalkoy, Kyrenia.
              </p>
              <ul className="list-icons">
                <li>
                  <i className="fa fa-check-circle"></i>We can offer small family cars, luxury 4x4 jeeps
                </li>
                <li>
                  <i className="fa fa-check-circle"></i>In addition to our car rental services we also provide transfer
                  services to and from Ercan airport and also Larnaca and Paphos airports in South Cyprus
                </li>
              </ul>
              <p className="btn-row">
                <Link to="/cars" className="btn btn-theme ripple-effect btn-theme-md">
                  See All Vehicles
                </Link>
                <Link to="/cars" className="btn btn-theme ripple-effect btn-theme-md btn-theme-transparent">
                  Online Reservation
                </Link>
              </p>
            </div>
            <div className="col-md-6 wow fadeInRight" data-wow-offset="200" data-wow-delay="300ms">
              <Splide>
                <SplideSlide>
                  <img className="img-fluid" src="/img/preview/slider/slide1.webp" alt="image 1" />
                </SplideSlide>
                <SplideSlide>
                  <img className="img-fluid" src="/img/preview/slider/slide2.webp" alt="image 2" />
                </SplideSlide>
                <SplideSlide>
                  <img className="img-fluid" src="/img/preview/slider/slide3.webp" alt="image 3" />
                </SplideSlide>
                <SplideSlide>
                  <img className="img-fluid" src="/img/preview/slider/slide4.webp" alt="image 4" />
                </SplideSlide>
              </Splide>
            </div>
          </div>
        </div>
      </section>
      <section className="page-section">
        <div className="container">
          <h2 className="section-title wow fadeInUp" data-wow-offset="70" data-wow-delay="100ms">
            <span>Our Cars</span>
          </h2>

          <div className="tabs wow fadeInUp" data-wow-offset="70" data-wow-delay="300ms">
            <ul id="tabs" className="nav">
              <li>
                <Link aria-label="Cars" to="/cars">
                  &nbsp;
                </Link>
              </li>
              <li className="active">
                <Link aria-label="Cars" to="/cars">
                  OUR CARS
                </Link>
              </li>
              <li>
                <Link aria-label="Cars" to="/cars">
                  &nbsp;
                </Link>
              </li>
            </ul>
          </div>
          <div className="tab-content wow fadeInUp" data-wow-offset="70" data-wow-delay="500ms">
            <div className="tab-pane active in" id="tab-1">
              {loading ? (
                <LoaderSlider />
              ) : (
                <Splide
                  options={{
                    type: "loop",
                    perPage: 3,
                    gap: "1rem",
                    focus: "center",
                    start: 1,
                    autoplay: true,
                    breakpoints: {
                      991: {
                        perPage: 2,
                        focus: "none",
                      },
                      768: {
                        perPage: 1,
                      },
                    },
                  }}
                >
                  {cars &&
                    cars.map((car) => (
                      <SplideSlide>
                        <div className="thumbnail no-border no-padding thumbnail-car-card">
                          <div className="media">
                            <Link to={`/car/${car.id}`} className="media-link">
                              <img src={`/images/${car.image}`} className="img-fluid" alt="Car Image" />
                              <span className="icon-view">
                                <strong>
                                  <i className="fa fa-eye"></i>
                                </strong>
                              </span>
                            </Link>
                          </div>
                          <div className="caption text-center">
                            <h4 className="caption-title">
                              <Link aria-label="Car" to={`/car/${car.id}`}>
                                {car.name}
                              </Link>
                            </h4>
                            <div className="caption-text">
                              from Low Season: £<b style={{ fontSize: "30px" }}>{car.lowPrice}</b>
                              High Season: £<b style={{ fontSize: "30px" }}>{car.highPrice}</b> /
                              <br /> {car.category}
                            </div>
                            <div className="buttons">
                              <Link
                                data-role="button"
                                data-inline="true"
                                className="btn btn-theme ripple-effect"
                                aria-label="Car"
                                to={`/car/${car.id}`}
                              >
                                Rent It
                              </Link>
                            </div>

                            <table className="table">
                              <tbody>
                                <tr>
                                  <td>
                                    <i className="fa fa-car"></i>
                                    <br />
                                    {car.fuel}
                                  </td>
                                  <td>
                                    <i className="fa fa-tachometer-alt"></i>
                                    <br />
                                    {car.engine.toPrecision(2)} lt
                                  </td>
                                  <td>
                                    <i className="fa fa-cog"></i>
                                    <br />
                                    {car.gearbox}
                                  </td>
                                  <td>
                                    <i className="fa fa-users"></i>
                                    <br />
                                    {car.passengers} Seats
                                  </td>
                                  <td>
                                    <i className="far fa-folder"></i>
                                    <br />
                                    {car.doors} Doors
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </SplideSlide>
                    ))}
                </Splide>
              )}
            </div>
          </div>
        </div>
      </section>
      <section className="page-section testimonials">
        <div className="container wow fadeInUp" data-wow-offset="70" data-wow-delay="500ms">
          <div className="testimonials-carousel">
            <Splide>
              <SplideSlide>
                <div className="testimonial">
                  <div className="media">
                    <div className="media-left">
                      <a href="#">
                        <img
                          className="media-object testimonial-avatar"
                          src="/img/preview/avatars/testimonial-140x140x1.jpg"
                          alt="Testimonial avatar"
                        />
                      </a>
                    </div>
                    <div className="media-body">
                      <div className="testimonial-text">
                        <i className="fas fa-quote-left"></i>
                        <br />
                        Great Customer Service, the staff are very friendly and helpful
                        <br />
                        <i className="fas fa-quote-right" style={{ right: 0, position: "absolute" }}></i>
                      </div>
                      <div className="testimonial-name">
                        JULIE SMITH
                        <span className="testimonial-position"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="testimonial">
                  <div className="media">
                    <div className="media-left">
                      <a href="#">
                        <img
                          className="media-object testimonial-avatar"
                          src="/img/preview/avatars/testimonial-140x140x1.jpg"
                          alt="Testimonial avatar"
                        />
                      </a>
                    </div>
                    <div className="media-body">
                      <div className="testimonial-text">
                        <i className="fas fa-quote-left"></i>
                        <br />
                        Great Customer Service, the staff are very friendly and helpful
                        <br />
                        <i className="fas fa-quote-right" style={{ right: 0, position: "absolute" }}></i>
                      </div>
                      <div className="testimonial-name">
                        JULIE SMITH
                        <span className="testimonial-position"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="testimonial">
                  <div className="media">
                    <div className="media-left">
                      <a href="#">
                        <img
                          className="media-object testimonial-avatar"
                          src="/img/preview/avatars/testimonial-140x140x1.jpg"
                          alt="Testimonial avatar"
                        />
                      </a>
                    </div>
                    <div className="media-body">
                      <div className="testimonial-text">
                        <i className="fas fa-quote-left"></i>
                        <br />
                        Great Customer Service, the staff are very friendly and helpful
                        <br />
                        <i className="fas fa-quote-right" style={{ right: 0, position: "absolute" }}></i>
                      </div>
                      <div className="testimonial-name">
                        JULIE SMITH
                        <span className="testimonial-position"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </SplideSlide>
            </Splide>
          </div>
        </div>
      </section>
      <section className="page-section">
        <div className="container">
          <h2 className="section-title wow fadeInDown" data-wow-offset="200" data-wow-delay="100ms">
            <small>See What People Ask to Us</small>
            <span>FAQS</span>
          </h2>

          <div className="row">
            <div className="col-md-6">
              <div className="accordion" id="accordion1">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="heading1">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse1"
                      aria-expanded="true"
                      aria-controls="collapse1"
                    >
                      How do I make reservation?
                    </button>
                  </h2>
                  <div
                    id="collapse1"
                    className="accordion-collapse collapse show"
                    aria-labelledby="heading1"
                    data-bs-parent="#accordion1"
                  >
                    <div className="accordion-body">
                      You can book online or over the telephone or come in to our office
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="heading2">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse2"
                      aria-expanded="false"
                      aria-controls="collapse2"
                    >
                      Can I collect the car from the airport ?
                    </button>
                  </h2>
                  <div
                    id="collapse2"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading2"
                    data-bs-parent="#accordion1"
                  >
                    <div className="accordion-body">
                      Yes we can deliver the cars to Ercan airport for you to coolect when you arrive .(provding it is 5
                      days in low season and 7 days in high season)
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="heading3">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse3"
                      aria-expanded="false"
                      aria-controls="collapse3"
                    >
                      How will I find the car I have booked when I arrive at Ercan ?
                    </button>
                  </h2>
                  <div
                    id="collapse3"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading3"
                    data-bs-parent="#accordion1"
                  >
                    <div className="accordion-body">
                      Our representative will meet you at arrivals he will be holding a sign with you name
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="accordion" id="accordion2">
                <div className="accordion-item">
                  <h2 className="accordion-header" id="heading21">
                    <button
                      className="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse21"
                      aria-expanded="true"
                      aria-controls="collapse21"
                    >
                      Can I pay by Credit Card when I collect my car hire ?
                    </button>
                  </h2>
                  <div
                    id="collapse21"
                    className="accordion-collapse collapse show"
                    aria-labelledby="heading21"
                    data-bs-parent="#accordion2"
                  >
                    <div className="accordion-body">
                      Yes we accept all card payments , with a 2 % charge for all card payment
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="heading22">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse22"
                      aria-expanded="false"
                      aria-controls="collapse22"
                    >
                      Is there a 24 hour emergency contact number , if I need one ?
                    </button>
                  </h2>
                  <div
                    id="collapse22"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading22"
                    data-bs-parent="#accordion2"
                  >
                    <div className="accordion-body">
                      If you need to contact us for an emergency the number is 0090 542 888 4888
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="heading23">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapse23"
                      aria-expanded="false"
                      aria-controls="collapse23"
                    >
                      Can I add additional drivers and is there a extra charge ?
                    </button>
                  </h2>
                  <div
                    id="collapse23"
                    className="accordion-collapse collapse"
                    aria-labelledby="heading23"
                    data-bs-parent="#accordion2"
                  >
                    <div className="accordion-body">You can add up to a maximum of 3 drivers with no extra charge</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="page-section image subscribe">
        <div className="container">
          <h2 className="section-title wow fadeInDown" data-wow-offset="200" data-wow-delay="100ms">
            <small>You Can Follow Us By E Mail</small>
            <span>Subscribe</span>
          </h2>

          <div
            className="row wow d-flex justify-content-center fadeInDown"
            data-wow-offset="200"
            data-wow-delay="200ms"
          >
            <div className="col-md-8 col-md-offset-2">
              <p className="text-center">
                Subcribe to our e-mail news service and receive regular updates on our special offers
              </p>
              <div className="form-subscribe">
                <div className="form-group">
                  <input type="text" className="form-control" placeholder="Enter your email here" />
                  <input
                    type="submit"
                    value="Subscribe"
                    id="btnSubscribe"
                    className="btn btn-submit btn-theme ripple-effect btn-theme-dark"
                    style={{ padding: "13px" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ContactFooter />
    </Fragment>
  );
};

export default Home;
