export const CREATE_TRANSFER_REQUEST = "CREATE_TRANSFER_REQUEST";
export const CREATE_TRANSFER_SUCCESS = "CREATE_TRANSFER_SUCCESS";
export const CREATE_TRANSFER_FAIL = "CREATE_TRANSFER_FAIL";
export const CREATE_TRANSFER_RESET = "CREATE_TRANSFER_RESET";

export const MY_TRANSFERS_REQUEST = "MY_TRANSFERS_REQUEST";
export const MY_TRANSFERS_SUCCESS = "MY_TRANSFERS_SUCCESS";
export const MY_TRANSFERS_FAIL = "MY_TRANSFERS_FAIL";

export const ALL_TRANSFERS_REQUEST = "ALL_TRANSFERS_REQUEST";
export const ALL_TRANSFERS_SUCCESS = "ALL_TRANSFERS_SUCCESS";
export const ALL_TRANSFERS_FAIL = "ALL_TRANSFERS_FAIL";

export const UPDATE_TRANSFER_REQUEST = "UPDATE_TRANSFER_REQUEST";
export const UPDATE_TRANSFER_SUCCESS = "UPDATE_TRANSFER_SUCCESS";
export const UPDATE_TRANSFER_RESET = "UPDATE_TRANSFER_RESET";
export const UPDATE_TRANSFER_FAIL = "UPDATE_TRANSFER_FAIL";

export const DELETE_TRANSFER_REQUEST = "DELETE_TRANSFER_REQUEST";
export const DELETE_TRANSFER_SUCCESS = "DELETE_TRANSFER_SUCCESS";
export const DELETE_TRANSFER_RESET = "DELETE_TRANSFER_RESET";
export const DELETE_TRANSFER_FAIL = "DELETE_TRANSFER_FAIL";

export const TRANSFER_DETAILS_REQUEST = "TRANSFER_DETAILS_REQUEST";
export const TRANSFER_DETAILS_SUCCESS = "TRANSFER_DETAILS_SUCCESS";
export const TRANSFER_DETAILS_FAIL = "TRANSFER_DETAILS_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";
