import {
  ALL_CAR_FAIL,
  ALL_CAR_REQUEST,
  ALL_CAR_SUCCESS,
  ADMIN_CAR_REQUEST,
  ADMIN_CAR_SUCCESS,
  ADMIN_CAR_FAIL,
  CREATE_CAR_REQUEST,
  CREATE_CAR_SUCCESS,
  CREATE_CAR_FAIL,
  CREATE_CAR_RESET,
  UPDATE_CAR_REQUEST,
  UPDATE_CAR_SUCCESS,
  UPDATE_CAR_FAIL,
  UPDATE_CAR_RESET,
  DELETE_CAR_REQUEST,
  DELETE_CAR_SUCCESS,
  DELETE_CAR_FAIL,
  DELETE_CAR_RESET,
  CAR_DETAILS_REQUEST,
  CAR_DETAILS_FAIL,
  CAR_DETAILS_SUCCESS,
  CLEAR_ERRORS,
} from "../constants/carConstants";

export const carsReducer = (state = { cars: [] }, action) => {
  switch (action.type) {
    case ALL_CAR_REQUEST:
    case ADMIN_CAR_REQUEST:
      return {
        loading: true,
        cars: [],
      };
    case ALL_CAR_SUCCESS:
      return {
        loading: false,
        cars: action.payload.cars,
        carsCount: action.payload.carsCount,
        resultPerPage: action.payload.resultPerPage,
        filteredCarsCount: action.payload.filteredCarsCount,
      };
    case ADMIN_CAR_SUCCESS:
      return {
        loading: false,
        cars: action.payload,
      };
    case ALL_CAR_FAIL:
    case ADMIN_CAR_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const createCarReducer = (state = { car: {} }, action) => {
  switch (action.type) {
    case CREATE_CAR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_CAR_SUCCESS:
      return {
        loading: false,
        success: action.payload.success,
        car: action.payload.car,
      };
    case CREATE_CAR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CREATE_CAR_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const carReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_CAR_REQUEST:
    case UPDATE_CAR_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_CAR_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };
    case UPDATE_CAR_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_CAR_FAIL:
    case UPDATE_CAR_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_CAR_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case UPDATE_CAR_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const carDetailsReducer = (state = { car: {} }, action) => {
  switch (action.type) {
    case CAR_DETAILS_REQUEST:
      return {
        loading: true,
        ...state,
      };
    case CAR_DETAILS_SUCCESS:
      return {
        loading: false,
        car: action.payload,
      };
    case CAR_DETAILS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};
