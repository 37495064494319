import React, { Fragment } from "react";
import { Link } from "react-router-dom";

const TravelGuide = () => {
  return (
    <Fragment>
      <section className="page-section pt-4">
        <div className="container">
          <div>
            <h1>North Cyprus</h1>
          </div>
          <div>
            <br />
          </div>
          <div>
            <span>
              Cyprus is the third largest island in the Mediterranean Sea after Sicily and Sardinia. Nicosia is the
              capital city of North Cyprus, which has a total population of 264,172 according to data compiled in 2006.
            </span>
          </div>
          <div>
            <span>&nbsp;</span>
          </div>
          <div>
            <span>
              North Cyprus is a paradise island in the Mediterranean with its untouched natural beauties and unique
              historical wealth. Cyprus is unmatched with its friendly people, clean and untouched nature,
              kilometers-long coasts, yearlong sunshine, golden beaches and 9,000 years of history. Cyprus offers a
              fantastic combination of history with gothic churches and crusader castles on the Besparmak Mountains,
              remains of temples and examples of British colonial architecture.
            </span>
          </div>
          <div>
            <br />
          </div>
          <div>
            <span>The beach holiday season</span>
            <span>
              &nbsp;typically starts in April and lasts into November, although the weather is mild all year round, and
              usually dry and sunny even in the depths of winter. Walking, golf and other activity holidays can be
              enjoyed year-round.
            </span>
          </div>
          <div>
            <span>Weather:</span>
            <span>
              &nbsp;The local currency is the Turkish Lira ( Turk Lirasi, TL). Major credit cards are widely accepted.
            </span>
          </div>
          <div>
            <span>Border Crossing:</span>
            <span>
              &nbsp;There are no longer any restrictions on border crossings for EU citizens in Cyprus, as of 2003. EU
              citizens are free to cross and spend as much time as they wish on either side of the border by producing
              their ID cards or passports at crossing points any time of the day (24 hrs). At present, there are seven
              crossing points where pedestrians can cross at the Ledra Palace and Lokmaci border gates are in Lefkosa
              (Nicosia), whilst those who intend to cross by vehicle can use the Metehan (Kermia) border gate in
              Lefkosa, Beyarmudu (Pile) near the British Sovereign bases at Dhekelia and the Akyar border gates near
              Gazimagusa (Famagusta) on the Larnaca-Famagusta road, Bostanci near Guzelyurt and Yesilirmak west of
              Lefke.
            </span>
          </div>
          <div>
            <span>Flights:</span>
            <span>
              &nbsp;North Cyprus has two international airports. The larger of the two is Ercan Airport near Lefkosa,
              and the other is Geçitkale Airport near Gazimagusa. Due to international requirements, all flights into
              North Cyprus (Ercan Airport) have to touch down, in transit, at any airport in Turkey (Istanbul, Izmir,
              Antalya or Dalaman). The stop-over is about 30-45 minutes and there is usually no change of aircraft.
              Luggage will usually be checked in straight through to Ercan airport. Scheduled and charter flights
              operate from Manchester, Birmingham, Stansted and London Heathrow airports to Ercan via a stop-over in
              Turkey.&nbsp;
            </span>
          </div>
          <div>
            <span>Traffic Information:</span>
            <span>
              &nbsp;Driving is on the left and traffic and road signs are international.Maximum speed is 100 km/hr.
              Vehicles entering North Cyprus must be insured upon arrival. Seat-belts must be worn at all times.
              Drinking and driving is illegal, as is using a mobile phone whilst driving.
            </span>
          </div>
          <div>
            <span>Passport and Visas:</span>
            <span>
              &nbsp;No visa is required for British or EU (European Union) citizens wishing to visit North Cyprus. The
              only requirement is a valid passport at the time of travel to North Cyprus. If you prefer not to have your
              passport stamped on arrival, you can ask at passport control for a separate piece of paper, which will be
              stamped instead. The only nationalities which require visas for North Cyprus are Nigerians and Armenians.
              For further information visit www.trnclondon.com
            </span>
          </div>
          <div>
            <br />
          </div>
          <div>
            <span>
              <Link to="/travel-guide/kyrenia" target="_self">
                <span style="color: #000000;">Kyrenia</span>
              </Link>
            </span>
          </div>
          <div>
            <span>
              North Cyprus is home to the harbour city of Kyrenia and some of the most amazing sites in Cyprus.
              &nbsp;Although many holiday makers may be forgiven for thinking that Kyrenia is just a harbour town, there
              really is a lot more to see and do. &nbsp;Filled with historical buildings, superb scenery, spectacular
              natural un-spoilt sandy beaches, and much more, Kyrenia makes the perfect destination for any holiday.
            </span>
          </div>
          <div>
            <span>
              The calming atmosphere of this beautiful sea-front city makes holidaying in Kyrenia a relaxing and
              thoroughly enjoyable experience. &nbsp;The old town of Kyrenia is filled with whitewashed buildings and
              the ruins of walls and towers that once protected this city from attack. &nbsp;Whilst the city of Kyrenia
              may seem quite and asleep during the day, once the sun sets the atmosphere is transformed as restaurant
              and tavern owners prepare for the night and the entertainment it brings.
            </span>
          </div>
          <div>
            <span>Larnaca</span>
          </div>
          <div>
            <span>
              Larnaca (also spelt Larnaka) is an area of outstanding beauty - endowed with numerous attractions,
              waterfronts and scenic vistas as well as some of the islands most outstanding beaches. It is located on
              Cyprus' southernmost coast and is the country’s third largest city and home to the second largest
              commercial port. Larnaca's International Airport is located on the fringe of the city with easy access to
              and from the city centre.
            </span>
          </div>
          <div>
            <span>
              Places to see in Larnaca: Hala Sultan Tekke, Church of saint Lazarus, Ancient Kition, Larnaca Fort, Europe
              Square, Larnaca Salt Lake, Stavrovouni Monastery, Choirokoitia Neolithic Settlement, Pierides Museum,
              Saint George Makris
            </span>
          </div>
          <div>
            <span>Guzelyurt (Morphou)</span>
          </div>
          <div>
            <span>
              Guzelyurt &nbsp;is a market town located in the west of North Cyprus, and is the regional capital of the
              Guzelyurt district. It is one of the richest agricultural areas of North Cyprus, famous particularly for
              the citrus (orange, lemon &amp; grapefruit) and strawberries. They thrive in Guzelyurt's fertile red soil.
              A large portion of the citrus fruit is exported, and the rest is made into fruit juice and canned for
              local consumption as well as export.
            </span>
          </div>
          <div>
            <span>Guzelyurt</span>
            <span>
              &nbsp;is famous for its annual Orange Festival, which is a major event lasting for two weeks every June.
              It also hosts a culture and arts festival annually in May.
            </span>
          </div>
          <div>
            <br />
          </div>
          <div>
            <span>St. Mamas Church and Icon Museum</span>
            <span>
              &nbsp;The many valuable historical artefacts are on display inside, which include the Sacrophagus of St.
              Mamas which according to folklore, was washed ashore and discovered by a peasant in the local bay. In a
              vision he was told to take the Sacrophagus to the place where it now stands and build a monastery there.
            </span>
          </div>
          <div>
            <span>Museum of Archaeological &amp; Natural Sciences</span>
            <span>
              &nbsp;The museum contains mainly archeological artifacts from the Bronze Age as well as a section mainly
              concerned with geology, birds, reptiles, insects and some of the wildlife of the island.
            </span>
          </div>
          <div>
            <span>Pighades:</span>
            <span>
              &nbsp;A Bronze Age Settlement The Bronze Age sanctuary at Pigadhes dates back to about 1600 B.C. About
              1300 B.C. refugees from Crete brought their property, including the Horns of Consecration, a religious
              symbol, to the Cyprus alter
            </span>
          </div>
          <div>
            <span>Famagusta Bay</span>
          </div>
          <div>
            <span>
              If you're dreaming of clean beaches that stretch as far as the eye can see, where you can relax in
              glorious sunshine on golden sands, then you're dreaming of beaches in Famagusta, North Cyprus!
            </span>
          </div>
          <div>
            <span>
              The wide sweeping Famagusta Bay occupies almost all the eastern coast of North Cyprus, and has long
              attracted invaders and beach-lovers alike! The Romans built their ancient town of Salamis here, and the
              walled town of Famagusta grew wealthy in Medieval times thanks to its deep water harbour.
            </span>
          </div>
          <div>
            <span>
              Most public beaches near Famagusta offer the essentials of a fabulous day on the beach; sun bed and
              sunshade hire, and showers to wash off the salt water after a cooling dip in the sea. Many have beachside
              restaurants or cafés for cooling drinks, mid-morning snacks or a relaxed lunch with a glass of wine!
              Remember, the North Cyprus sun is strong, and you should always bring your own sunscreen, a spare bottle
              of water, plus a hat and slip-on shoes for that trip from sun bed to bar, (the sand can get very hot!).
            </span>
          </div>
          <div>
            <span>
              The star of the east coast beaches must be Glapsides Public Beach, offering everything for a wonderful day
              on the beach, from sunshades to a resident dj!
            </span>
          </div>
          <div>
            <span>Famagusta Beaches</span>
          </div>
          <div>
            <span>Glapsides Public Beach</span>
          </div>
          <div>
            <span>Voted second-best beach in North Cyprus, after Karpas Golden Beach!</span>
          </div>
          <div>
            <span>Silver Beach</span>
          </div>
          <div>
            <span>
              Next to the ruins of Salamis, Silver Beach is an ideal place to combine a bit of culture and some serious
              sun-worshipping.
            </span>
          </div>
          <div>
            <span>Bafra Public Beach&nbsp;</span>
          </div>
          <div>
            <span>
              Quiet sandy beach with a good restaurant. Two nearby hotels are being built so the peace and quiet may not
              last too long.
            </span>
          </div>
          <div>
            <br />
          </div>
          <div>
            <span>Nikosia (Lefkose)</span>
          </div>
          <div>
            <span>
              Nicosia is the capital and largest city on the island of Cyprus, as well as its main business centre. It
              is located near the centre of the Mesaoria plain, on the banks of the River Pedieos.
            </span>
          </div>
          <div>
            <span>
              Lefkosia (Nicosia), the island’s capital, combines both old and new in a busy modern commercial and
              business centre and a centuries-old culture. The centre of the city is its old quarter surrounded by a
              Venetian sandstone fortress wall with a moat and heart-shaped bastions.&nbsp;
            </span>
          </div>
          <div>
            <br />
          </div>
          <div>
            <span>
              Mosques and palm trees give an oriental atmosphere to the old city. Wander round narrow streets with
              overhanging balconies and the beautifully restored pedestrian precinct with craft shops, cafes and
              tavernas. Make sure you stop off at St John’s cathedral with its frescoes and the marvellous museum of
              Byzantine icons. In the evening catch a concert in the moat or an exhibition at the Famagusta Gate, one of
              the three original entrances into the old city, which has now been restored and is used as a cultural
              centre.&nbsp;
            </span>
          </div>
          <div>
            <br />
          </div>
          <div>
            <span>
              The new town spreads beyond the walls with a modern Europeanised centre of high-rise buildings, office
              blocks, shops and pavement cafes, expanding into suburban residential areas. Lefkosia offers the best in
              shopping and Stassicratous Street is the place for the ultimate shopping experience.
            </span>
          </div>
          <div>
            <br />
          </div>
          <div>
            <span>
              Of the city’s main sights, the Cyprus Museum houses the best collection of archaeological artefacts on the
              island, including a first century AD Roman statuette of Aphrodite of Soli and the original mosaic of Leda
              and the Swan, while the Leventis museum depicts the history of the town.
            </span>
          </div>
          <div>
            <span>Faneromeni Church</span>
          </div>
          <div>
            <span>A wonderful church in the Lefkosia old city</span>
            <span className="Apple-tab-span"></span>
            <span>The Leventis Municipal Museum of Nicosia</span>
          </div>
          <div>
            <span>The Historical Museum of Nicosia</span>
          </div>
          <div>
            <span>The Cyprus Classic Motorcycle Museum</span>
          </div>
          <div>
            <span>Exhibits motorcycles dated between 1914 - 1983</span>
            <span className="Apple-tab-span"></span>
            <span>Holy Cross Catholic Church</span>
          </div>
          <div>
            <span>A beautiful church that dates back to 1900</span>
          </div>
          <hr className="page-divider small" />
        </div>
      </section>
    </Fragment>
  );
};

export default TravelGuide;
