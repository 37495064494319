import React, { Fragment } from "react";
import Breadcrumb from "../layout/Breadcrumb";
import MetaData from "../layout/MetaData";

const TermsConditions = () => {
  return (
    <Fragment>
      <MetaData title="Baspinar - Terms & Conditions" />
      <Breadcrumb parent="pages" name="Terms and Conditions" />
      <section className="page-section pt-4">
        <div
          className="container"
          style={{
            fontFamily: "tahoma, arial, helvetica, sans-serif",
            color: "#999999",
            fontSize: "16px",
          }}
        >
          <p className="text-uppercase my-3 text-black">BASPINAR RENT A CAR</p>
          <p>
            Baspinar Rent A Car requests that you make a small deposit payment when you make your booking. We accept the
            following forms of payment:
          </p>
          <p>
            <br />
            <span>
              Credit/Charge Cards:&nbsp;Visa, MasterCard or Amex are accepted. &nbsp;
              <br />
              <br />
              Debit Cards:&nbsp;Debit cards, such as Visa, Delta or Switch/Maestro,&nbsp;are accepted.
              <br />
              <br />
              Upon delivery of your rental car you can pay the remainder of the balance by either Cash (Sterling,
              Turkish Lira, Euro or Dollars), or&nbsp;via credit or debit card with our mobile POS machine. &nbsp;
            </span>
          </p>
          <p>
            <span>
              Note: 1.3 % transaction fee is charged for any credit and debit card payment. Amex card payment there is a
              5% charge (American Express) .&nbsp;&nbsp;&nbsp;
              <br />
              <br />
              Rental Agreement:&nbsp;The Rental Agreement relates to the actual hire of the Vehicle. As a condition of
              the vehicle supplied to you by&nbsp;Baspinar Rent A Car&nbsp;, you will be required to sign the Rental
              Agreement. The Rental Agreement should be reviewed before signing, to ensure that you are aware of, and
              understand the terms upon which the vehicle is being provided. Key terms of the Rental Agreement are set
              out below:
              <br />
              <br />
              Driver information:&nbsp;Renter must hold a full valid drivers licence.If you are a UK resident and hold a
              new style UK driving licence, you must also produce the paper counterpart.
              <br />
              <br />
              Rental Period:&nbsp;The minimum rental period is&nbsp;3 days.
              <br />
              <br />
              Seat Belts:&nbsp;All vehicle occupants must use a seatbelt at all times.
              <br />
              <br />
              State/Border Restrictions:Baspinar Rent A Cars are not permitted to be driven in South Cyprus.
            </span>
          </p>
          <p>
            <span>
              Collection Time:&nbsp;Your vehicle will only be held available for up to 2 hours after the agreed
              collection time, you must contact Baspinar Rent A Car if you will arrive after this time.
            </span>
          </p>
          <p>
            <span>Included in your booking cost:</span>
          </p>
          <p>
            <span>
              Mileage Allowance:&nbsp;The vehicle is provided with an unlimited kilometre/mileage allowance.
              <br />
              <br />
              Insurance Loss/Damage Waiver 1:&nbsp;This is&nbsp;included in the booking cost and reduces your financial
              liability. &nbsp;Driving on unpaved roads voids Collision Damage Waiver (CDW) coverage.
              <br />
              <br />
              Theft Protection (TP):&nbsp;In the case of theft or fire, you must:&nbsp;- Contact the police and report
              the incident within 24 hours&nbsp;- Submit a copy of the police report and the rental agreement to
              Baspinar Rent A Car&nbsp;- Return the vehicle keys to Baspinar Rent A Car.&nbsp;For details of this
              insurance, please check with Baspinar Rent A Car on collection of the Vehicle.
              <br />
              <br />
              Refunds:&nbsp;No credit will be given for any unused portion of the Rental Period.
              <br />
              <br />
              Additional Driver(s):&nbsp;There is no extra charge for additional drivers.&nbsp; All additional drivers
              must show full driving licences and passports/ID cards.
              <br />
              <br />
              Delivery Charge:
            </span>
          </p>
          <p>
            <span>
              For Booking less than 5 days during low season and 7 days during the high season the following delivery/
              collection charges will apply Ozankoy, Catalakoy, £10 Kyrenia, Karaoglanoglu , Arapkoy £15 Alsancak, Lapta
              , Karsiyaka , Esentepe , Tatlisu , Bahceli £20 Ercan &amp; Kayalar £30&nbsp;
            </span>
          </p>
          <p>
            <span>Famagusta , Iskele &amp; Guzelyurt&nbsp;£60 (Regardless of the number of days booked)</span>
          </p>
          <p>
            <span>Bafra £80 (Regardless of the number of days booked)</span>
          </p>
          <p>
            <span>Karpaz £100 (Regardless of the number of days booked)</span>
          </p>
          <p>
            <span>
              If the car is to be delivered to any of the above mentioned destionations, the tank will be filled with
              £20 of petrol. This amount will be added to the total price for the car hire.&nbsp;
            </span>
          </p>
          <p>
            <br />
            <span>
              Fuel:&nbsp;For customer peace of mind, if you require your car to be delivered to Ercan airport, £20 of
              petrol will be put into your hire car. &nbsp;This will be added to the cost of your hire car. The type of
              fuel will be stated, and also labled on the petrol cap. Baspinar Rent A Car consider that you are
              responsible in case of any fuel mistakes. Depending on the circumstances, any unused fuel cannot be
              refunded.
              <br />
              <br />
              Fines and Penalties:&nbsp;You are responsible for the cost of any parking fines or other penalties
              incurred whilst the vehicle is on rent to you. Baspinar Rent A Car will pass the relevant charge on to you
              plus any administrative expenses.&nbsp;
              <br />
              Out of Hours Charge: There is no 'Out of hours service' charge.
              <br />
              <br />
              Late Returns:&nbsp;If the vehicle is returned later than the time specified in the voucher, Baspinar Rent
              A Car will apply a charge. This is paid locally by you.
              <br />
              <br />
              Lost Keys:&nbsp;Should the vehicle's keys not be handed back to a Baspinar Rent A Car employee at the
              return depot then a charge will be incurred. This is paid locally by you.
              <br />
              <br />
              Child Seats:&nbsp; Baspinar Rent a Car can provide you with a Baby seat for £1 per day, Child Booster for
              £1 per day of car hire. You will be required to fit the child seat yourself. We have limited baby seats
              and boosters, so please book in advance along with your car hire. &nbsp;
              <br />
              <br />
              Cleaning Fee:&nbsp;A cleaning fee may be charged if the vehicle is returned in an unacceptable condition
              or if it requires special washing. This is paid locally by you.
              <br />
              <br />
              Condition of Vehicle:&nbsp;The vehicle must be returned (normal wear &amp; tear expected), in the same
              condition as when rented.
              <br />
              <br />
              Documents Required:&nbsp;The vehicle will not be released to you for rental unless you produce the
              documents listed below:&nbsp;
            </span>
          </p>
          <ul>
            <li>
              <span>- A printout of your voucher (if applicable).</span>
            </li>
            <li>
              <span>- A valid, original driver's licence is required for yourself and each additional driver:</span>
            </li>
            <li>
              <span>- A valid passport for all the drivers</span>
            </li>
            <li>
              <span>+ Photocopies, faxes or letters will not be accepted.</span>
            </li>
            <li>
              <span>
                + All drivers' licences must be for manual transmission vehicles, unless you have specifically
                pre-booked an automatic vehicle.
              </span>
            </li>
            <li>
              <span>+ All drivers' licences must be held for more than one year.</span>
            </li>
          </ul>
          <p>
            <br />
            <span>
              UK drivers:&nbsp;the paper counterpart must accompany any photo card licence (we do not call the DVLA to
              confirm the status of a licence).&nbsp;
              <br />
              <br />A credit/charge card:
            </span>
          </p>
          <ul>
            <li>
              <span>+ This must be the card used to make your booking.</span>
            </li>
            <li>
              <span>+ This must be the original card in your name (not a copy).</span>
            </li>
            <li>
              <span>
                + All additional drivers must show a valid credit card issued in their name at the time of rental.
              </span>
            </li>
            <li>
              <span>+ The cardholder must be present.</span>
            </li>
            <li>
              <span>
                + It must be a valid credit card with sufficient funds available to cover any required deposit.&nbsp;
              </span>
            </li>
          </ul>
          <p>
            <span>
              Roadside Assistance:&nbsp;24 hour roadside assistance is provided. The phone number will be supplied on
              the rental agreement. Please see the procedures detailed below for what to do in the event of an incident.
              Depending on circumstances, Baspinar Rent A Car reserve the right not to replace the vehicle.
              <br />
              <br />
              Incident:&nbsp;In case of an Incident:&nbsp;
              <br />
              <br />
              - Inform the Police immediately (Telephone 155) and obtain an incident or crime number.&nbsp;
              <br />
              - Notify&nbsp;&nbsp;Baspinar Rent A Car&nbsp;&nbsp;customer services right away.&nbsp;
              <br />
              - Obtain names, addresses, contact telephone numbers of all third parties and witnesses and vehicle
              registration numbers of all third parties and supply them to us.&nbsp;
              <br />
              - Immediately send&nbsp;&nbsp;Baspinar Rent A Car&nbsp;&nbsp;any letter from any third party, any claim
              form, any written summons or any other documents relating to any court proceedings.
              <br />
              -Provide all reasonable assistance to Baspinar Rent A Car or our agents and insurers in dealing with the
              court proceedings including allowing court actions to be taken in your name and defending any proceedings
              taken against you.
              <br />
              - At the time of the incident, which has involved the vehicle and a third party in an accident, make no
              admission of liability to any persons. By signing and agreeing to the rental agreement you agree to make
              good (and if necessary pay Baspinar Rent A Car for) any and all damage or loss sustained to the vehicle
              and to any third party during the rental period in the event that you:
              <br />
              <br />
              - Fail to ensure that an Incident Report Form is completed and submitted to the rental company.&nbsp;
              <br />
              - Use the vehicle for any purpose other than that for which it was supplied to you.&nbsp;
              <br />
              - Use the vehicle to participate in any illegal activity.&nbsp;
              <br />- Fail to take reasonable care of the vehicle.&nbsp; Depending on the circumstances, Baspinar Rent A
              Car reserve the right not to replace the vehicle.
            </span>
          </p>
          <p>
            <span>
              Insurance:&nbsp;Baspinar Rent A Car provide full insurance, provided that:
              <br />
              <br />
              - an incident report form is completed and submitted to the rental company
              <br />
              - Vehicle is used only for the purpose which it was supplied to you&nbsp;
              <br />
              - the Vehicle is not used to participate in any illegal activity
              <br />
              - reasonable care is taken of the vehicle&nbsp;
              <br />
              - you do not use or drive the vehicle, or allow anyone else to use or drive the vehicle&nbsp;whilst
              intoxicated, or under the influence of any medication, or other legal or illegal substance, which impairs
              driving ability.&nbsp;
              <br />- Baspinar Rent A Car will not be held responsible for anyone driving under the influence of
              alcohol/ drugs, causing wilful damage, off road driving or driving without due care and attention. You
              accept responsibility in respect of such claims.
            </span>
          </p>
          <p>
            <span>
              Please note that insurance does not cover damage to tires or underneath of the vehicle and loss or damage
              to keys.
              <br />
              <br />
              General information:&nbsp;Please ensure that you:
              <br />
              <br />
              - Do not use or drive the vehicle or allow anyone else to use or drive the vehicle whilst intoxicated or
              under the influence of any medication or other legal or illegal substance, which impairs driving ability.
              <br />
              - Do not allow or do work on or to the vehicle including altering, removing or changing any component and
              changing in any way the vehicle's specification or appearance.&nbsp;
              <br />
              - Do not represent that you are the owner of the vehicle or our agent.&nbsp;
              <br />
              - Do not remove/change any name or other mark identifying ownership of the vehicle.&nbsp;
              <br />
              - Notify Baspinar Rent A Car of the vehicle whereabouts immediately upon request.&nbsp;- Do not sell, rent
              or dispose of the vehicle or any extras in any way.&nbsp;
              <br />
              - Ensure that the vehicle is not used in an unsafe circumstance, in a condition that is considered unsafe
              to drive or use the vehicle for the purpose for which it is not designed or not suitable for. In
              particular you will not overload the vehicle, you will properly secure all loads and not carry any
              hazardous, dangerous or inflammable substance(s).&nbsp;
              <br />
              - Do not use the vehicle for racing, pace making or any other form of competition.&nbsp;
              <br />
              - Do not use the vehicle for towing, pushing, driving tuition or any other hazardous or unusual use.&nbsp;
              <br />
              - Baspinar Rent A Car will not be held responsible for anyone driving under the influence of alcohol/
              drugs, causing wilful damage, off road driving or driving without due care and attention. You accept
              responsibility in respect of such claims. This list is not exclusive.
              <br />
              <br />
              If you fail to fulfil any of the obligations imposed on you by this clause, you may invalidate your
              Insurance as applicable and/or be held liable by Baspinar Rent A Car to make good all loss or damage
              sustained.&nbsp;Terms and Conditions and Prices are subject to change without notice and may vary by
              location.
              <br />
              <br />
              Cancellations and Amendments:&nbsp;Cancellations and amendments must always be made directly to Baspinar
              Rent A Car. &nbsp;Amendments to bookings are free of charge.
              <br />
              <br />
              Cancellation charges apply as follows:&nbsp;Within 48 hours of placing the booking the deposit is fully
              refundable (unless within 48 hours before the rental takes place). When canceling after 48 hours of making
              the booking the deposit paid will be non-refundable. Credit card fees are non-refundable.
              <br />
              In the event of a "NO SHOW" (when the client fails to collect a vehicle and the booking is not cancelled
              in advance) Baspinar Rent A Car are unable to provide any refund. If clients end the hire duration early
              or are late collecting the vehicle, Baspinar Rent A Car are unable to refund any unused hire days.
              <br />
              <br />
              Cancellation Charge:&nbsp;Baspinar Rent A Car will charge a cancellation fee for any booking you are
              unable to honor prior to the collection date. No refunds will be given for no shows in resort, rentals
              ended early, late collections or cancellations made after the rental start date, even if cancellation
              protection or CAPP is purchased. Bookings can only be cancelled upon return of the rental voucher (if
              issued).&nbsp;
              <br />
              <br />
              Fuel:&nbsp;&nbsp;For our customers peace of mind, £20 of petrol will be put into your hire car. This will
              be added to the cost of your hire car. The type of fuel will be stated, and also labelled on the petrol
              cap. Baspinar Rent A Car consider that you are responsible in case of any fuel mistakes. Depending on the
              circumstances, any unused fuel cannot be refunded
            </span>
          </p>
        </div>
      </section>
    </Fragment>
  );
};

export default TermsConditions;
